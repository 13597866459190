<template>
  <div :class="`${question.questionClass || 'col'} field`">
    <button class="btn btn-primary btn-lg" @click="addReponse()" target="_blank">
      {{ question.buttonLabel }}
    </button>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: ["question", "path", "responsePath"],
  methods: {
    ...mapActions({
      addReponse: "addReponseQuizz",
    }),
  },
};
</script>
