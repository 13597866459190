<template>
  <!-- <header v-if="section" class="app-header" :class="sectionName"> -->
  <header class="app-header" :class="sectionName">
    <div class="header-title">
      <!-- <i v-if="section.icon" :class="section.icon" /> -->
      <!-- <div v-if="section.title">{{ section.title }}</div> -->
      <img
        src="@/assets/images/jouez-gagnez.jpg"
        alt="Jouez et Gagnez"
        v-if="!routeBO && $route.name !== 'ErrorAPI'"
        height="49px"
      />
      <img
        src="@/assets/images/logo-groupama-loire-bretagne.svg"
        id="logo"
        alt="Logo Groupama Loire Bretagne"
        width="220px"
        height="54px"
      />
    </div>
    <div v-if="routeBO && NomUtilisateur" class="header-right">
      <span>Bonjour {{ NomUtilisateur }}</span>
    </div>
  </header>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["NomUtilisateur"]),
    ...mapState({
      section(state) {
        return state.survey.sections[this.$route.meta.section];
      },
      sectionName() {
        return this.$route.meta.section;
      },
      routeName() {
        return this.$route.name;
      },
      routeBO() {
        // let routeNames = ["BO", "ModificationJeu", "CreationJeu"];
        // return routeNames.includes(this.routeName);
        return this.routeName?.includes("BO.");
      },
    }),
  },
  methods: {
    ...mapActions(["toggleNavBar"]),
  },
};
</script>
<style lang="scss" scoped></style>
