<!-- Gestion d'une page du formulaire + actions avant changement de page -->
<template>
  <main>
    <div class="liste-questions" v-if="section">
      <h2 class="jeux-nom" v-if="section.title">{{ section.title }}</h2>
      <div v-if="section.label">{{ section.label }}</div>
      <question-layout
        v-for="(question, questionKey) in section.questions"
        :key="
          questionKey.indexOf('questions.') == -1 ? questionKey : questionKey.split('questions.')[1]
        "
        :questionName="
          questionKey.indexOf('questions.') == -1 ? questionKey : questionKey.split('questions.')[1]
        "
        :question="
          questionKey.indexOf('questions.') == -1
            ? question
            : Object.assign({}, questions[`${questionKey.split('questions.')[1]}`], question)
        "
        :path="
          questionKey.indexOf('questions.') == -1
            ? `sections.${$route.meta.section}.questions.${questionKey}`
            : `sections.${$route.meta.section}.questions.${questionKey.split('questions.')[1]}`
        "
        :responsePath="question.responsePath"
        :resetOtherQuestion="question.resetOtherQuestion"
      />
      <div class="field field-required questionnaire" v-if="sectionName === 'jeu_concours_form'">
        <div class="question-wrapper">
          <div class="row">
            <div class="col-12">
              <div v-if="NbchampsObligatoires === 1">* champ obligatoire</div>
              <div v-if="NbchampsObligatoires > 1">* champs obligatoires</div>
            </div>
          </div>
        </div>
      </div>
      <div class="field field-mentions questionnaire" v-if="sectionName === 'jeu_concours_form'">
        <div class="question-wrapper">
          <div class="row">
            <div class="col-12">
              Nous vous informons que vous pouvez vous opposer au démarchage téléphonique en vous inscrivant gratuitement sur le site BLOCTEL (liste d’opposition au démarchage téléphonique) : <a href="www.bloctel.gouv.fr" target="_blank">www.bloctel.gouv.fr</a>
            </div>
          </div>
        </div>
      </div>
      <div
        class="footer"
        v-if="sectionName === 'bo_nouveau_jeu' || sectionName === 'bo_modifier_jeu'"
      >
        <router-link :to="{ name: 'BO.ListeJeux' }" class="btn btn-primary">Annuler</router-link>
        <button class="btn btn-primary" :class="{ opacity: !isValide }" @click="ApercuJeu">
          Aperçu
        </button>
        <button class="btn btn-primary" :class="{ opacity: !isValide }" @click="ValiderJeu">
          Valider
        </button>
      </div>
      <div class="footer" v-else>
        <div v-if="affichePrecedent">
          <router-link
            v-if="previousSection"
            :to="{
              name: 'Questionnaire',
              params: {
                section: previousSection,
                param: jeuId,
              },
              query: {...$route.query, button: 'previous'},
            }"
            custom
            v-slot="{navigate}"
          >
            <button @click="navigate" @keypress.enter="navigate" class="btn btn-primary">
              <span data-qa="precedent">
                {{ section && section.previousButton ? section.previousButton : "Précédent" }}
              </span>
            </button>
          </router-link>
        </div>

        <div v-if="nextSection">
          <router-link
            :to="{
              name: 'Questionnaire',
              params: {
                section: nextSection,
                param: jeuId,
              },
              meta: {
                section: nextSection,
              },
              query: {...$route.query, button: 'next'},
            }"
            custom
            v-slot="{navigate}"
          >
            <button @click="navigate" @keypress.enter="navigate" :class="{ opacity: !isValide }" class="btn btn-primary test" :disabled="!isValide">
              <span data-qa="suivant">
                {{ section && section.nextButton ? section.nextButton : "Suivant" }}
              </span>
            </button>
          </router-link>
        </div>

        <div v-if="!nextSection">
          <router-link
            :to="{
              name: 'Complet',
              params: { param: $route.params.param },
              query: $route.query,
              meta: { section: 'confirmation'}
            }"
            custom
            v-slot="{navigate}"
          >
            <button @click="navigate" @keypress.enter="navigate" :class="{ opacity: !isValide }" class="btn btn-primary btn-lg" :disabled="!isValide">
              <span data-qa="suivant">
                {{ section && section.confirmeButton ? section.confirmeButton : "Valider" }}
              </span>
            </button>
          </router-link>
        </div>

        <div class="field field-mentions-submit questionnaire" v-if="sectionName === 'jeu_concours_form' && currentJeu.Type === 'reco'">
          <div class="question-wrapper">
            <div class="row">
              <div class="col-12">
                <p>
                  Programme de parrainage soumis à conditions disponibles en agence Groupama :
                  <ul>
                    <li>
                      Pour les filleuls : 30€ offerts au filleul, versés sous la forme de bons "Ensemble et Solidaires", valables pour toute nouvelle souscription d’un contrat avant le 31/12/2024, dans les agences Groupama des départements 22, 29, 35, 44, 49, 56. La liste des contrats d’assurance éligibles à cette offre, ainsi que les montants minimum de cotisations à souscrire pour en bénéficier sont disponibles auprès de votre conseiller. Ces offres sont valables pour les nouveaux clients "filleuls" et sont non cumulables avec toute autre offre promotionnelle en cours ou à venir. Chaque contrat peut être souscrit séparément.
                    </li>
                    <li>
                      Pour les parrains : 30€ offerts au parrain, versés sous la forme de bons "Ensemble et Solidaires", valables pour toute souscription nouvelle par un nouveau client "filleul", d'un ou plusieurs contrat(s) IARD d'un montant minimum de cotisation annuelle 150€ TTC par un nouveau client particulier, exploitant agricole, artisan, commerçant ou prestataires de services. Règlement complet sur le parrainage disponible auprès de votre conseiller Groupama. Ces offres sont valables sur les départements 22, 29, 35, 44, 49, 56 jusqu’au 31/12/2024.
                    </li>
                  </ul>
                </p>
                <p>
                  En communiquant vos données ainsi que celles de votre filleul, vous acceptez que les données personnelles soient traitées par Groupama Loire Bretagne, responsable de traitement, pour gérer votre participation à l’opération de parrainage. Vous avez par ailleurs préalablement informé votre filleul de cette collecte et avez recueilli son consentement à être contacté par un conseiller Groupama pour un bilan assurantiel en Agence.<br>
                  Le traitement de ces données personnelles est réalisé sur la base de l’intérêt légitime de l’entreprise d’organiser cette opération de parrainage. Les données sont conservées le temps de l’opération de parrainage.
                </p>
                <p>
                  Conformément à la réglementation relative à la protection des données personnelles notamment le RGPD (UE 2016/679) et la loi Informatique et Libertés n° 78-17 du 6 Janvier 1978 modifiée, vous pouvez exercer vos droits d’accès, de rectification, de suppression, de limitation, de portabilité et d'opposition en vous adressant au Délégué à la Protection des Données de Groupama Loire Bretagne, par courrier à l’adresse : 23, Boulevard Solférino CS51209 35012 RENNES cedex ou par mail à l’adresse <a href="mailto:contactdrpo@groupama-loire-bretagne.fr">contactdrpo@groupama-loire-bretagne.fr</a>. Il vous revient d’informer votre filleul des droits dont il dispose et des modalités d’exercice de ceux-ci.<br>
                  Pour une information détaillée concernant le traitement de vos données personnelles ou les modalités d’exercice de vos droits, vous pouvez consulter le règlement de parrainage disponible auprès de votre conseiller ainsi que notre Politique de protection des données accessible sur le site internet <a href="www.groupama.fr" target="_blank">www.groupama.fr</a>, rubrique « Données personnelles ».
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div v-else>No Section</div>

    <div class="pages" v-if="sectionName !== 'bo_nouveau_jeu' || sectionName !== 'bo_modifier_jeu'">
      <div></div>
      <div></div>
    </div>
  </main>
</template>
<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { keys, find, get, map, filter, forEach, has } from "lodash";
import { isRulesVisible, getProp, execQuestionDeepError } from "../utils/SurveyUtils";
import store from "../store";
import { getError, isRequired } from "../utils/validate";

export default {
  data: function () {
    return { errorsSubmit: false, NbchampsObligatoires: 0 };
  },
  created() {
    if (this.$store.state.survey.sections.globals.nombreReferrals > 0) {
      console.log("/....reset..../");
      this.RESET_REFERRALS();
      this.SET_REFERRAL();
    }
  },
  mounted() {
    if (this.$store.state.survey.sections.globals.nombreReferrals === 0) {
      this.SET_REFERRAL();
    }
    // active popState si on utilise le bouton précédent du navigateur pour pas être perdu
    // console.log("---------");
    window.addEventListener("popstate", () => {
      while (
        this.$store.state.survey.sections.globals.previousSection &&
        this.$store.state.survey.sections.globals.previousSection.length !== 0 &&
        this.$store.state.survey.sections.globals.previousSection[
          this.$store.state.survey.sections.globals.previousSection.length - 1
        ] !== this.$route.meta.section
      ) {
        this.popPreviousSection();
      }
      this.popPreviousSection();
    });

    // <!-- Conversion Pixel - 2020_GROUPAMA_LOIRE BRETAGNE - DO NOT MODIFY -->
    // Code tracking dans le formulaire
    // let glbScript = document.createElement("script");
    // glbScript.setAttribute("src", "https://secure.adnxs.com/px?id=1370970&t=1");
    // glbScript.setAttribute("type", "text/javascript");
    // document.head.appendChild(glbScript);
  },
  computed: {
    sectionName() {
      console.log(this.$route.meta.section);
      return this.$route.meta.section;
    },
    typeJeu() {
      return this.typeJeuSection(this.sectionName);
    },
    ...mapGetters(["typeJeuSection", "getEmailEtTelephone", "dejaJouer", "currentJeu"]),
    ...mapState({
      showAppFooter: "showAppFooter",
      getState(state) {
        return state;
      },
      confirmButton(state) {
        return state.survey.sections.globals.confirmButton;
      },
      sectionList(state) {
        return state.survey.sections;
      },
      jeuId(state) {
        return this.$route.params.param;
      },
      sectionNameList(state) {
        return filter(keys(state.survey.sections), name => name !== "globals");
      },
      section(state) {
        return state.survey.sections[this.$route.meta.section];
      },
      previousSection(state) {
        // récupère la section précédente
        let previousSections = get(this.$store.state.survey, "sections.globals.previousSection");

        if (previousSections === null || previousSections === undefined) {
          return null;
        }

        let i = 1;
        while (i <= previousSections.length) {
          // si la section précédente est égal à la section courante on regarde celle d'avant
          if (previousSections[previousSections.length - i] !== this.$route.meta.section) {
            return previousSections[previousSections.length - i];
          }
          i++;
        }
        return null;
      },
      visibilityRulesBtNext(state) {
        return state.survey.sections[this.$route.meta.section].visibilityRulesBtNext;
      },
      nextSection(state) {
        let section = null;
        if (this.section) {
          forEach(this.section.next, function (nextSection, name) {
            if (isRulesVisible(nextSection.visibilityRules, state)) {
              section = name;
            }
          });
        }
        return section;
      },
      questions(state) {
        return state.survey.questions;
      },
      affichePrecedent() {
        let previous = this.section && this.section.previousButton;
        return previous;
      },
      isValide(state) {
        const sectionName = this.$route.meta.section;
        // console.log("isValide Section ===>", sectionName);
        // callback -> return true if we need to search error in survey or false if we have get error
        let callback = (q, p, rp) => {
          const isVisible = isRulesVisible(q.visibilityRules, state);
          // console.log("----------------isValide----------");
          if (isVisible) {
            let error;
            // maxAnswers need to access to global state
            if (has(q.rules, "maxAnswers")) {
              error = getError(getProp(state.survey, q.rules.maxAnswers.path, ""), q.rules);
              if (error) {
                // dispatch error
                this.checkError({
                  path: p,
                  responsePath: rp,
                  value: getProp(state.survey, q.rules.maxAnswers.path, ""),
                });
              } else {
                this.checkError({
                  path: p,
                  responsePath: rp,
                  value: null,
                });
              }
            } else if (has(q.rules, "minQuestions")) {
              this.checkError({
                path: p,
                responsePath: rp,
                value: null,
              });
              error = getProp(q, rp, "error", state);
            } else {
              error = getError(getProp(q, rp, "response"), q.rules, state);
            }
            // console.log("question ", q.serverPath, error);
            return !error;
          }
          // console.log("--------------------------");
          return true;
        };
        let section = get(state, `survey.sections.${sectionName}`);
        // regarder les erreurs sur les questions de la section
        const arrayError = section
          ? map(section.questions, (question, questionName) => {
              // console.log("DEBUG question, questionName", question, questionName);
              const isVisible = isRulesVisible(question.visibilityRules, state);

              return !isVisible
                ? true
                : !execQuestionDeepError(
                    question,
                    `sections.${sectionName}.questions.${questionName}`,
                    question.responsePath,
                    callback
                  );
            })
          : [];
        // console.log("DEBUG arrayError", arrayError);
        return find(arrayError, bool => bool === false) === undefined;
      },
      haveDeclarations(state) {
        let declarations = getProp(this.$store.state.survey, "sections.globals.declarations");
        return (
          find(
            declarations,
            element =>
              element !== undefined &&
              element !== null &&
              typeof element === "object" &&
              Object.keys(element).length !== 0
          ) !== undefined
        );
      },
    }),
  },
  methods: {
    ...mapMutations(["SET_REFERRAL", "RESET_REFERRALS"]),
    ...mapActions([
      "setDejaJouer",
      "validateSection",
      "sendSurvey",
      "sendBuilding",
      "resetValue",
      "setValidateSection",
      "toggleBtNext",
      "addPreviousSection",
      "popPreviousSection",
      "setValueGlobal",
      "setValue",
      "resetLotsJeu",
      "checkUserAlreadyPlay",
      "checkError",
      "checkCompleted",
      "setCurrentJeu",
      "setListeQuizzs",
      "addLotJeuState",
      "setAppFooter",
      "resetQuestion",
      "setDejaJouer",
    ]),
    changeValueValidateSection(path, value) {
      this.setValidateSection({ path, value });
    },
    onRouteChange(to, from, next) {
      return Promise.resolve()
        .then(() => {
          if (
            from.path.indexOf("jeu_concours_form") >= 0 &&
            !(to.path.indexOf("dejaParticiper") >= 0)
          ) {
            const repondant = {
              Email: this.getEmailEtTelephone.split("|")[0],
              Telephone: this.getEmailEtTelephone.split("|")[1],
            };
            let currentJeu = this.$store.state.survey.sections.globals.currentJeu;
            return currentJeu.Type !== "reco" && this.checkUserAlreadyPlay({ jeuId: this.jeuId, repondant: repondant });
          }
          return false;
        })
        .then(res => {
          return this.setDejaJouer(res);
        })
        .then(() => {
          if (this.dejaJouer) {
            return this.$router.push({
              name: "Deja participer",
              meta: { section: "dejaParticiper" },
            });
          }
        })
        .then(() => {
          if (to.name.toLowerCase() === "complet" && !this.dejaJouer) {
            return this.validateSection(from.meta.section);
          }
        })
        .then(props => {
          if (
            this.section.isValidated === true &&
            !this.dejaJouer &&
            to.name.toLowerCase() === "complet"
          ) {
            return this.sendSurvey({
              query: from.query,
              survey: this.getState.survey,
              type: to.query.type !== "apercu" ? this.sectionName : null,
            });
          }
        })
        .then(() => next());
    },
    ValiderJeu() {
      // console.log("TODO #5759 sectionName est undefined à certain moment", this.sectionName);
      return Promise.resolve()
        .then(() => this.validateSection(this.sectionName))
        .then(res => this.isValide)
        .then(valide => {
          if (valide) {
            return this.sendSurvey({
              survey: this.getState.survey,
              type: this.sectionName,
            });
          } else {
            return "invalide";
          }
        })
        .then(res => {
          if (res !== "invalide") {
            return this.$router.push({
              name: "BO.ListeJeux",
            });
          } else {
            return window.scroll(0, 0);
          }
        });
    },
    ApercuJeu() {
      return Promise.resolve()
        .then(() => this.validateSection(this.sectionName))
        .then(res => {
          if (this.isValide) {
            return this.setCurrentJeu({
              survey: this.getState.survey,
              fromSection: this.sectionName,
              type: "apercu",
            }).then(res => {
              let routeApercu = this.$router.resolve({
                name: "Questionnaire",
                params: {
                  param: this.jeuId,
                  section: "jeu_accueil",
                },
                query: { ...this.$route.query, type: "apercu" },
              });
              return window.open(routeApercu.href, "_blank");
            });
          } else {
            return window.scroll(0, 0);
          }
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    console.log("to", to);

    // Pour le component Questionnaire, la section est passée en param.
    // Pour tous les autres c'est en meta (changement vue router 4)
    if (to.params?.section !== undefined) {
      to.meta.section = to.params.section;
    }

    if (to.meta.section === "bo_modifier_jeu") {
      return Promise.resolve()
        .then(() => store.dispatch("setJeu", to.params.param))
        .then(() => store.dispatch("setListeImages"))
        .then(() => {
          return next();
        });
    }
    if (to.meta.section === "bo_nouveau_jeu") {
      return Promise.resolve()
        .then(() => store.dispatch("setJeu", 0))
        .then(() => store.dispatch("setListeImages"))
        .then(() => store.dispatch("resetLotsJeu", { section: "bo_nouveau_jeu", typeLots: "all" }))
        .then(() =>
          store.dispatch("addLotJeuState", { sectionName: "bo_nouveau_jeu", typeLot: "tirage" })
        )
        .then(() => {
          return next();
        });
    }
    if (to.meta.section === "jeu_accueil" && to.params.param) {
      return Promise.resolve()
        .then(() => store.dispatch("setListeQuizzs"))
        .then(() =>
          store.dispatch("setCurrentJeu", {
            survey: store.state.survey,
            id: to.params.param,
            type: to.query.type,
          })
        )
        .then(() => store.dispatch("setAppFooter", true))
        .then(() => next());
    }
    return next();
  },
  // Toutes les actions effectués avant de changer de page sont ici
  beforeRouteUpdate(to, from, next) {
    const button = to.query.button;

    if (button === "previous") {
      // si on appui sur précédent on reset le formulaire incomplet
      // this.resetValue({ path: `sections.${this.$route.meta.section}`, value: survey.sections[this.$route.meta.section] });
      // et dépile le dernière element de l'historique des précédentes versions
      this.popPreviousSection();

      next();
    } else if (button === "linkField") {
      // si on arrive sur le formulaire de déclaration via un "LinkField"
      // on ajoute la section courante à l'historique des sections
      if (from.meta.section !== to.meta.section) {
        this.addPreviousSection(from.meta.section);
      }
      next();
    } else if (button === "header") {
      // si on clique sur l'header
      // on vérifie si on peut y aller
      let headerList = this.$store.state.survey.sections.globals.header;

      // on créer une liste nomRoute => index pour plus facilement comparer leurs positions
      let reverseHeaderList = [];
      for (let index = 0; index < headerList.length; index++) {
        reverseHeaderList[headerList[index].route] = index;
      }

      // si on clique sur une section précédente
      if (
        from.meta.section !== undefined &&
        reverseHeaderList[to.meta.section] < reverseHeaderList[from.meta.section]
      ) {
        // on dépile les elements de l'historique des précédentes versions jusque la où on va
        while (
          this.$store.state.survey.sections.globals.previousSection &&
          this.$store.state.survey.sections.globals.previousSection.length !== 0 &&
          this.$store.state.survey.sections.globals.previousSection[
            this.$store.state.survey.sections.globals.previousSection.length - 1
          ] !== to.meta.section
        ) {
          this.popPreviousSection();
        }
        this.popPreviousSection();
        next();
      } else if (
        from.meta.section !== undefined &&
        reverseHeaderList[to.meta.section] > reverseHeaderList[from.meta.section] &&
        this.$store.state.survey.sections[to.meta.section].isValidated
      ) {
        // sinon si on clique sur une section suivante
        let i = 1;
        while (
          headerList[reverseHeaderList[from.meta.section] + i] !== undefined &&
          headerList[reverseHeaderList[from.meta.section] + i].route !== to.meta.section
        ) {
          this.addPreviousSection(headerList[reverseHeaderList[from.meta.section] + i - 1].route);
          i++;
        }
        this.addPreviousSection(headerList[reverseHeaderList[from.meta.section] + i - 1].route);

        next();
      }
    } else {
      // sinon on regarde si le formulaire est valide
      // on ajoute la section courante à l'historique des sections
      if (from.meta.section !== to.meta.section) {
        this.addPreviousSection(from.meta.section);
      }
      this.onRouteChange(to, from, next);
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === "/bo") {
      next();
    } else {
      this.onRouteChange(to, from, next);
    }
  },
  watch: {
    getEmailEtTelephone(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setDejaJouer(false);
        this.resetQuestion({
          path: "sections.jeu_instant_gagnant_form.questions.roue_chance",
        });
      }
    },
    typeJeu(newValue, oldValue) {
      // console.log("watcher surveyForm => ", newValue, oldValue, this.sectionName);
      if (["classic", "quizz"].indexOf(newValue) >= 0) {
        this.resetLotsJeu({ section: this.sectionName, typeLots: "gagnant" });
      }
    },
    sectionName(newValue, oldValue) {
      if (newValue === "jeu_concours_form") {
        let questions = this.section.questions.questionnaire.questions;
        console.log(questions);
        let nbQuestionsRequired = map(questions, question => {
          return isRequired(question, this.getState);
        });
        this.NbchampsObligatoires = nbQuestionsRequired.filter(Boolean).length;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
