<template>
  <div class="editor-wrapper">
    <QuillEditor
      :placeholder="question.placeholder"
      :data-qa="question.serverPath"
      :name="question.labelInput"
      :toolbar="customToolbar"
      theme="snow"
      :content="content"
      v-model:content="content"
      contentType="html"
      @update:content="onEditorInput"
    ></QuillEditor>
  </div>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { mapActions } from "vuex";
import { getProp } from "../../utils/SurveyUtils";

export default {
  props: ["question", "path", "responsePath"],
  data: () => ({
    content: "",
    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      // ["link", "image", "video"],
      ["link"],
      ["clean"],
    ],
  }),
  methods: {
    ...mapActions(["setValue", "checkError"]),
    onEditorInput(quill) {
      this.setValue({ path: this.path, value: this.content });
      this.checkError({ path: this.path, responsePath: this.responsePath, value: this.content });
    },
  },
  mounted() {
    this.content = getProp(this.question, this.responsePath, "response");
  },
  components: {
    QuillEditor,
  },
};
</script>
