<!-- Gestion d'une question -->
<template>
  <div
    v-if="isVisible"
    :class="[
      isChildren ? 'col' : 'field',
      question.questionClass,
      questionName,
      question.questionType,
    ]"
    v-show="question.invisible === undefined || !question.invisible"
  >
    <label v-if="question.label" :class="['question-title', question.questionTitleClass]"
      >{{ question.label }}
      <span class="subtitle" v-if="question.sublabel" v-sane-html="question.sublabel"></span
    ></label>

    <div class="question-wrapper">
      <div :class="['row', question.questionRowClass]">
        <!-- Render error message -->
        <div v-if="error" :class="question.errorClass ? question.errorClass : 'col-12 error'">
          <div class="error-message">{{ error }}</div>
        </div>

        <!-- Render question field following questionType -->
        <input-field
          v-if="question.questionType === 'input'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <cleave-field
          v-if="question.questionType === 'cleave'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <radio-field
          v-else-if="question.questionType === 'radio'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
          :questionName="questionName"
        />
        <select-quizzs-field
          v-else-if="question.questionType === 'select-quizzs'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <add-quizzs-field
          v-else-if="question.questionType === 'add-quizzs'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <checkbox-field
          v-else-if="question.questionType === 'checkbox'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <checkbox-classic-field
          v-else-if="question.questionType === 'checkbox-classic'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
          :isChecked="isChecked"
          :isDisabled="isDisabled"
          @click="manageResetQuestion()"
        />
        <checkbox-classic-field
          v-else-if="question.questionType === 'checkbox-text'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
          :isChecked="isChecked"
          :isDisabled="isDisabled"
        />
        <roue-chance-field
          v-else-if="question.questionType === 'roue-chance'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />

        <select-field
          v-else-if="question.questionType === 'select'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <date-month-year-field
          v-else-if="question.questionType === 'date-month-year'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <calendar-field
          v-else-if="question.questionType === 'calendar'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <download-field
          v-else-if="question.questionType === 'download'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <text-field
          v-else-if="question.questionType === 'text'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <textConfirm-field
          v-else-if="question.questionType === 'textConfirm'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <download-button-field
          v-else-if="question.questionType === 'downloadButton'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <upload-file-field
          v-else-if="question.questionType === 'uploadfile'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <crop-image-field
          v-else-if="question.questionType === 'cropimage'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <link-field
          v-else-if="question.questionType === 'link'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <drop-down-div-field
          v-else-if="question.questionType === 'dropdowndiv'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <text-area-field
          v-else-if="question.questionType === 'textarea'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <text-editor-field
          v-else-if="question.questionType === 'texteditor'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <pricing-table-field
          v-else-if="question.questionType === 'pricingTable'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <calcul-field
          v-else-if="question.questionType === 'calcul'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <image-field
          v-else-if="question.questionType === 'image'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <copy-button-field
          v-else-if="question.questionType === 'copybutton'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <add-lot-field
          v-else-if="question.questionType === 'addLot'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <add-reponse-field
          v-else-if="question.questionType === 'addReponse'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <remove-reponse-field
          v-else-if="question.questionType === 'removeReponse'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <remove-lot-field
          v-else-if="question.questionType === 'removeLot'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <grattage-field
          v-else-if="question.questionType === 'grattage'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />

        <referral-add-field
          v-else-if="question.questionType === 'addReferral'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />
        <referral-remove-field
          v-else-if="question.questionType === 'removeReferral'"
          :question="question"
          :path="path"
          :responsePath="responsePath"
        />

        <!-- Render child questions if exists -->
        <question-layout
          v-for="(subQuestion, key) in question.questions"
          :key="key"
          :questionName="key"
          :question="subQuestion"
          :path="`${path}.questions.${key}`"
          :responsePath="responsePath"
          :isChildren="true"
        />

        <!-- Render reset button if resetLabel exist -->
        <div
          :class="[question.answerColClass, question.resetBtnClass]"
          v-if="question.resetLabel && question.resetLabel.length >= 1"
        >
          <button
            type="button"
            :class="['btn btn-primary fullHeight', { selected: isResetSelected() }]"
            @click="manageResetQuestion()"
          >
            <span>{{ question.resetLabel }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { find, get } from "lodash";

import { getProp, isRulesVisible, isRulesChecked, isRulesDisabled } from "../utils/SurveyUtils";
import survey from "../../data/survey.json";

import InputField from "./fields/InputField";
import CleaveField from "./fields/CleaveField";
import RadioField from "./fields/RadioField";
import CheckboxField from "./fields/CheckboxField";
import CheckboxClassicField from "./fields/CheckboxClassicField";
import SelectField from "./fields/SelectField";
import DateMonthYearField from "./fields/DateMonthYearField";
import CalendarField from "./fields/CalendarField";
import DownloadField from "./fields/DownloadField";
import TextField from "./fields/TextField";
import TextConfirmField from "./fields/TextConfirmField";
import DownloadButtonField from "./fields/DownloadButtonField";
import LinkField from "./fields/LinkField";
import TextAreaField from "./fields/TextAreaField";
import PricingTableField from "./fields/PricingTableField";
import CalculField from "./fields/CalculField";
import AddLotField from "./fields/AddLotField.vue";
import RemoveLotField from "./fields/RemoveLotField.vue";
import UploadFileField from "./fields/UploadFileField.vue";
import ImageField from "./fields/ImageField.vue";
import CopyButtonField from "./fields/CopyButtonField.vue";
import CheckboxTextField from "./fields/CheckboxTextField.vue";
import TextEditorField from "./fields/TextEditorField.vue";
import DropDownDivField from "./fields/DropDownDivField.vue";
import AddReponseField from "./fields/AddReponseField.vue";
import RemoveReponseField from "./fields/RemoveReponseField.vue";
import SelectQuizzsField from "./fields/SelectQuizzsField.vue";
import GrattageField from "./fields/GrattageField.vue";
import RoueChanceField from "./fields/RoueChanceField.vue";
import AddQuizzsField from "./fields/AddQuizzsField.vue";
import CropImageField from "./fields/CropImageField.vue";
import ReferralAddField from "./fields/ReferralAddField.vue";
import ReferralRemoveField from "./fields/ReferralRemoveField.vue";

export default {
  props: ["questionName", "question", "path", "responsePath", "isChildren"],
  computed: {
    ...mapState({
      isVisible(state) {
        const visible = isRulesVisible(this.question.visibilityRules, state);
        const hidden = this.question.hidden;
        if (visible === false && this.question.response && !hidden) {
          // if visible false, and this question have a response,
          // we set the value with the initial state (survey.json)
          this.resetValue({ path: this.path, value: get(survey, this.path) });
        }
        return visible;
      },
      isChecked(state) {
        if (this.question.isCheckedRules) {
          const checkedRulesOK = isRulesChecked(this.question.isCheckedRules.rules, state);
          if (checkedRulesOK) return this.question.isCheckedRules.value;
          if (!checkedRulesOK) return !this.question.isCheckedRules.value;
        }
        return false;
      },
      isDisabled(state) {
        const disabled = isRulesDisabled(this.question.disabledRules, state);
        return disabled;
      },
    }),

    error() {
      return getProp(this.question, this.responsePath, "error");
    },
  },
  methods: {
    ...mapActions(["resetQuestion", "toggleBtNext", "resetValue"]),
    isResetSelected(question = this.question) {
      let resetOtherQuestion = get(this.$store.state.survey, question.resetOtherQuestion);
      return (
        getProp(question, this.responsePath, "response") === null &&
        find(question.questions, q => !this.isResetSelected(q)) === undefined &&
        (!resetOtherQuestion ||
          (getProp(resetOtherQuestion, this.responsePath, "response") === null &&
            find(resetOtherQuestion.questions, q => !this.isResetSelected(q)) === undefined))
      );
    },
    manageResetQuestion() {
      if (this.question && this.question.resetOtherQuestion) {
        this.resetQuestion({ path: this.question.resetOtherQuestion });
      }
      this.resetQuestion({ path: this.path, responsePath: this.responsePath });
    },
  },
  components: {
    InputField,
    CleaveField,
    RadioField,
    CheckboxField,
    CheckboxClassicField,
    SelectField,
    DateMonthYearField,
    CalendarField,
    DownloadField,
    TextField,
    TextConfirmField,
    DownloadButtonField,
    LinkField,
    TextAreaField,
    PricingTableField,
    CalculField,
    AddLotField,
    RemoveLotField,
    UploadFileField,
    ImageField,
    CopyButtonField,
    CheckboxTextField,
    TextEditorField,
    DropDownDivField,
    AddReponseField,
    RemoveReponseField,
    SelectQuizzsField,
    GrattageField,
    RoueChanceField,
    AddQuizzsField,
    CropImageField,
    ReferralAddField,
    ReferralRemoveField
  },
};
</script>
<style lang="scss"></style>
