<template>
  <div class="not-found">
    Can't found the path:
    <b>{{ $route.fullPath }}</b>
    <div>
      Please check the url or Redirect to home page:
      <router-link :to="{ name: 'Acceuil', query: $route.query }" replace>Home</router-link>
    </div>
  </div>
</template>
<script>
export default {
  timeout: 4000
};
</script>
