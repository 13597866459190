<template>
  <div :class="`${question.answerColClass || 'col'} field`">
    <cleave
      :placeholder="question.placeholder"
      :data-qa="question.serverPath"
      :name="question.labelInput"
      :type="question.inputType && question.inputType"
      :class="['form-control', filled, question.fieldClass]"
      :options="question.cleaveOptions"
      v-model="response"
      @blur="e => setValue({ path, responsePath, value: response })"
      @input="e => {checkError({ path, responsePath, value: response }) && setValue({ path, responsePath, value: response })}"

    />
    <label v-if="question.labelInput">{{ question.labelInput }} <span v-if="required">*</span>
      <tooltip-element
        v-if="question.tooltipImage || question.tooltipText"
        :class="['question-tooltip']"
        :question="question"
      />
    </label>
    <label v-if="question.labelSuffix">{{ question.labelSuffix }}</label>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Cleave from "vue-cleave-component";

import "cleave.js/dist/addons/cleave-phone.fr";

import { getProp } from "../../utils/SurveyUtils";
import { isRequired } from "../../utils/validate";

export default {
  props: ["question", "path", "responsePath"],
  data: function () {
    return {
      response: null,
    };
  },
  computed: {
    required() {
      return isRequired(this.question, this.$store.state);
    },
    filled() {
      return this.response && this.response.length > 0 && "filled";
    },
  },
  mounted: function () {
    this.response = getProp(this.question, this.responsePath, "response");
  },
  methods: {
    ...mapActions(["setValue", "checkError"]),
  },
  components: {
    Cleave,
  },
};
</script>
