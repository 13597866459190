<template>
  <main>
    <div>
      <div class="page-title">
        <h2 @click="resetFilter()" style="cursor: pointer">Les quiz</h2>
        <router-link
          :to="{ name: 'CreationQuizz' }"
          class="btn btn-primary"
          >Créer un nouveau quiz</router-link
        >
      </div>
      <div class="content-wrapper" v-if="ListeQuizzs.length > 0">
        <div>
          <table class="table">
            <thead>
              <tr>
                <th>
                  <div class="th-title">
                    <a
                      @click="
                        trieDate = !trieDate;
                        sortListQuizz('DateCreation', trieDate);
                      "
                    >
                      <span>Date de création</span>
                      <i :class="`far  ${trieDate ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                    </a>
                  </div>
                  <input type="text" v-model="searchDate" @input="filterQuizz()" />
                </th>
                <th>
                  <div class="th-title">
                    <a
                      @click="
                        trieTitre = !trieTitre;
                        sortListQuizz('Titre', trieTitre);
                      "
                    >
                      <span>Titre</span>
                      <i :class="`far  ${trieTitre ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                    </a>
                  </div>
                  <input type="text" v-model="searchTitre" @input="filterQuizz()" />
                </th>
                <th>
                  <div class="th-title">
                    <a
                      @click="
                        trieQuestion = !trieQuestion;
                        sortListQuizz('Question', trieQuestion);
                      "
                    >
                      <span>Question</span>
                      <i :class="`far  ${trieQuestion ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                    </a>
                  </div>
                  <input type="text" v-model="searchQuestion" @input="filterQuizz()" />
                </th>
                <th>
                  <div class="th-title">
                    <a
                      @click="
                        trieStatut = !trieStatut;
                        sortListQuizz('Statut', trieStatut);
                      "
                    >
                      <span>Statut</span>
                      <i :class="`far  ${trieStatut ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                    </a>
                  </div>
                </th>
                <th>
                  <span></span>
                </th>
              </tr>
            </thead>
            <tbody v-if="CurrentQuizzs.length > 0">
              <quizz v-for="(quizz, index) in CurrentQuizzs" :key="index" :quizz="quizz" />
            </tbody>
            <div v-else>Aucun résultat</div>
          </table>
        </div>
      </div>
      <div v-else>
        <span>Aucune Quiz ajoutée pour l'instatnt</span>
      </div>
      <footer v-if="nbPages > 1">
        <div class="pagination">
          <button class="page-number" v-if="currentPage > 0" @click="nextQuizzsPage(currentPage)">
            <i class="far fa-chevron-left" />
          </button>
          <button
            :class="[i === currentPage + 1 && 'active', 'page-number']"
            v-for="i in nbPages"
            :key="i"
            @click="nextQuizzsPage(i)"
          >
            <span>{{ i }}</span>
          </button>
          <button
            class="page-number"
            v-if="currentPage < nbPages - 1"
            @click="nextQuizzsPage(currentPage + 2)"
          >
            <i class="far fa-chevron-right" />
          </button>
        </div>
      </footer>
    </div>
  </main>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import Quizz from "./Quizz.vue";
export default {
  components: {
    Quizz,
  },
  data() {
    return {
      nbPages: 0,
      nbJeuxPage: 15,
      currentPage: 0,
      searchDate: null,
      trieDate: false,
      searchTitre: null,
      trieTitre: false,
      searchQuestion: null,
      trieQuestion: false,
      trieStatut: false,
      listCurrentQuizzs: [],
    };
  },
  computed: {
    ...mapGetters(["ListeQuizzs"]),
    CurrentQuizzs() {
      let n = this.currentPage * this.nbJeuxPage;
      let m = n + this.nbJeuxPage;
      this.nbPages = Math.ceil(this.listCurrentQuizzs.length / this.nbJeuxPage);
      return this.listCurrentQuizzs.slice(n, m);
    },
  },
  methods: {
    ...mapActions(["setListeQuizzs"]),
    nextQuizzsPage(i) {
      this.currentPage = i - 1 < 0 ? 0 : i - 1 > this.nbPages - 2 ? this.nbPages - 1 : i - 1;
    },
    filterQuizz() {
      this.currentPage = 0;
      this.listCurrentQuizzs = this.ListeQuizzs;
      if (this.searchQuestion) {
        let search = new RegExp(this.searchQuestion, "i");
        this.listCurrentQuizzs = this.listCurrentQuizzs.filter(quizz => {
          return search.test(quizz.Question);
        });
      }
      if (this.searchTitre) {
        let search = new RegExp(this.searchTitre, "i");
        this.listCurrentQuizzs = this.listCurrentQuizzs.filter(quizz => {
          return search.test(quizz.Titre);
        });
      }
      if (this.searchDate) {
        let search = new RegExp(this.searchDate, "i");
        this.listCurrentQuizzs = this.listCurrentQuizzs.filter(quizz => {
          let date = moment(quizz.DateCreation).format("DD/MM/YYYY");
          return search.test(date);
        });
      }
    },
    resetFilter() {
      this.listCurrentQuizzs = this.ListeQuizzs;
      this.searchDate = null;
      this.searchTitre = null;
      this.searchQuestion = null;
      this.trieDate = false;
      this.trieTitre = false;
      this.trieStatut = false;
      this.trieQuestion = false;
    },
    sortListQuizz(key, trieKey) {
      this.currentPage = 0;
      this.listCurrentQuizzs = this.listCurrentQuizzs.sort((a, b) =>
        this.callbackSort(a, b, key, trieKey)
      );
    },
    callbackSort(a, b, key, trieKey) {
      if (a[key] < b[key]) {
        return trieKey ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return trieKey ? 1 : -1;
      }
      return 0;
    },
  },
  mounted() {
    return Promise.resolve()
      .then(() => this.setListeQuizzs())
      .then(() => (this.listCurrentQuizzs = this.ListeQuizzs));
  },
};
</script>

<style></style>
