<template>
  <main>
    <router-view></router-view>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import { ADMIN_ROLES } from "../../../constants/Properties";

export default {
  name: "IndexImages",
  computed: {
    ...mapGetters(["userRole"]),
    hasAction() {
      return ADMIN_ROLES.includes(this.userRole);
    },
  },
  mounted() {
    if (!this.hasAction) {
      this.$router.push({
        name: "ErrorAPI",
        params: { id: 401, errorMsg: "Vous n'avez pas le droit" },
      });
    }
  },
};
</script>

<style></style>
