<template>
  <div :class="`${question.answerColClass || 'col'} field multi-select-quizz`">
    <button class="btn-add btn-small" @click="selected.push(null)">+ Ajouter une question</button>
    <div class="question-bloc" v-for="(qvalue, index) in selected" :key="`question${index}`">
      <label class="question-title">Question {{ index + 1 }}</label>
      <v-select
        :name="`question${index}`"
        :options="options"
        :clearable="false"
        placeholder="Choisir une question"
        :modelValue="qvalue"
        @option:selected="value => onInput(value, index)"
      >
        <template #no-options>Pas de résultats</template>
      </v-select>
      <div class="flexbox justifyEnd">
        <button class="btn-small" v-if="selected.length > 1" @click="deleteQ(index)">
          Supprimer
        </button>
      </div>
    </div>
    <!-- <label v-if="question.labelInput">{{ question.labelInput }}</label> -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import VSelect from "vue-select";
import { getProp } from "../../utils/SurveyUtils";

export default {
  components: {
    VSelect,
  },
  props: ["question", "path", "responsePath"],
  data() {
    return {
      options: [],
      selected: [null],
      valueToSet: [],
    };
  },
  computed: {
    ...mapGetters(["ListeQuizzs"]),
  },
  methods: {
    ...mapActions(["setValue", "setListeQuizzs"]),
    onInput(value, index) {
      this.selected[index] = value;
      let id = this.ListeQuizzs.find(q => q.Question === value).IdQuizz;
      this.valueToSet[index] = id;
      this.setOptionAndValue();
    },
    deleteQ(index) {
      this.selected.splice(index, 1);
      this.valueToSet.splice(index, 1);
      this.setOptionAndValue();
    },
    setOptionAndValue() {
      this.options = this.ListeQuizzs.filter(quizz => {
        let q = quizz.Question;
        return this.selected.indexOf(q) < 0;
      }).map(quizz => quizz.Question);

      // set IdQuizz comme response
      this.$store.dispatch("setValue", {
        path: this.path,
        responsePath: this.responsePath,
        value: this.valueToSet,
      });
    },
  },
  mounted() {
    return Promise.resolve()
      .then(() => this.setListeQuizzs())
      .then(() => {
        let response = getProp(this.question, this.responsePath, "response");
        this.valueToSet = response || [];
        this.selected =
          response?.length > 0
            ? response.map(id => this.ListeQuizzs.find(q => q.IdQuizz === id).Question)
            : [null];
      })
      .then(() => this.setOptionAndValue());
  },
};
</script>
<style scoped>
.multi-select-quizz {
  display: grid;
  grid-gap: 10px 10px;
  /* grid-template-columns: 50% 50%; */
  /* grid-column-start: 2; */
}
</style>
