<template>
  <main>
    <div class="confirm-message">
      <p>Afin d'accéder aux resources une connexion est requise !</p>
      <!-- <button class="btn btn-primary" @click="signIn">Je me connecte via ADFS</button> -->
      <button class="btn btn-primary" @click="signIn">Connexion en cours...</button>
    </div>
  </main>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["setUtilisateur"]),
    signIn() {
      window.location.href = `${process.env.VUE_APP_API_URL}/api/login`;
    },
  },
  mounted() {
    return this.setUtilisateur({ email: null });
  },
};
</script>

<style></style>
