<template>
  <div :class="`${question.questionClass || 'col'} field`">
    <img
      v-if="question.response"
      :src="QrCode"
      :alt="question.alt"
      :width="question.width"
      :height="question.height"
      @click="downloadImg"
    />
  </div>
</template>

<script>
export default {
  props: ["question", "path", "responsePath"],
  computed: {
    QrCode() {
      return `${process.env.VUE_APP_API_URL}/api/upload/${this.question.response}`;
    },
  },
  methods: {
    downloadImg() {
      window.open(
        `${process.env.VUE_APP_API_URL}/api/upload/download/${this.question.response}`,
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  cursor: pointer;
}
</style>
>
