<template>
  <div>
    <modal-confirmation-field v-if="showModal" @close="onConfirm" @confirm="onConfirm">
      <template #headerTitle>
        <h5>Choix d'un visuel</h5>
      </template>
      <template #confirmationMessage>
        <div v-if="currentImages.length === 0">Aucune image</div>
        <div class="row">
          <div
            class="col-12 col-xs-6 col-md-4 col-lg-4 col-xl-3"
            v-for="(image, index) in currentImages"
            :key="index"
          >
            <div
              class="media-block"
              @click="selectedImage = image"
              :class="{ 'media-block-selected': selectedImage.FileName === image.FileName }"
            >
              <div class="media-block-img">
                <div class="media-block-img-mask">
                  <span>
                    <i class="fas fa-plus" />
                  </span>
                </div>
                <img :src="getUrl(image)" :alt="image.Titre" width="100px" />
              </div>
              <div class="media-block-title">
                <span>{{ image.Titre }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </modal-confirmation-field>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalConfirmationField from "./ModalConfirmationField.vue";
export default {
  components: { ModalConfirmationField },
  name: "SelectImage",
  props: ["question", "path", "responsePath", "typeSelect"],
  emits: ["input"],
  data() {
    return {
      showModal: true,
      selectedImage: { FileName: null },
    };
  },
  computed: {
    ...mapGetters(["listeImages"]),
    currentImages() {
      return this.listeImages.filter(img => img.Type === this.typeSelect);
    },
  },
  methods: {
    ...mapActions(["setValue"]),
    onConfirm() {
      if (this.selectedImage) {
        this.setValue({
          path: this.path,
          response: this.responsePath,
          value: this.selectedImage.FileName,
        });
      }
      this.$emit("input", false);
      this.showModal = false;
    },
    getUrl(image) {
      return `${process.env.VUE_APP_API_URL}/api/upload/${image.FileName}`;
    },
  },
};
</script>
