<template>
  <div
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    style="display: block"
  >
    <div class="modal-dialog modal-dialog-centered modal-fullscreen" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <slot name="headerTitle">
            <h5 class="modal-title">confirmation</h5>
          </slot>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('close')"
          >
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot name="confirmationMessage">Merci de confirmer votre demande</slot>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            @click="$emit('close')"
          >
            <slot name="closeBtn">Fermer</slot>
          </button>
          <button type="button" class="btn btn-primary" @click="$emit('confirm')">
            <slot name="confirmationBtn">Confirmer</slot>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["confirm", "close"]
};
</script>

<style></style>
