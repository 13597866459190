<template>
  <main>
    <div class="quizz-infos-wrapper" v-if="sectionName">
      <h2 class="jeux-nom" v-if="section.title">{{ section.title }}</h2>
      <div v-if="section.label">{{ section.label }}</div>
      <question-layout
        v-for="(question, questionKey) in section.questions"
        :key="
          questionKey.indexOf('questions.') == -1 ? questionKey : questionKey.split('questions.')[1]
        "
        :questionName="
          questionKey.indexOf('questions.') == -1 ? questionKey : questionKey.split('questions.')[1]
        "
        :question="
          questionKey.indexOf('questions.') == -1
            ? question
            : Object.assign({}, questions[`${questionKey.split('questions.')[1]}`], question)
        "
        :path="
          questionKey.indexOf('questions.') == -1
            ? `sections.${sectionName}.questions.${questionKey}`
            : `sections.${sectionName}.questions.${questionKey.split('questions.')[1]}`
        "
        :responsePath="question.responsePath"
        :resetOtherQuestion="question.resetOtherQuestion"
      />
      <footer>
        <button @click="$router.go(-1)" class="btn btn-primary">Annuler</button>
        <button @click="validerQuizz" class="btn btn-primary">Valider</button>
      </footer>
    </div>
    <div v-else>cette section n'est pas encore été implementée</div>
  </main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import QuestionLayout from "../../../components/QuestionLayout.vue";
export default {
  components: { QuestionLayout },
  computed: {
    sectionName() {
      let s = this.$route.meta.section;
      return s;
    },
    idQuizz() {
      let id = this.$route.params.id;
      return id;
    },
    ...mapState({
      getState(state) {
        return state;
      },
      section(state) {
        return state.survey.sections[this.sectionName];
      },
      isSectionValid(state) {
        return state.survey.sections[this.sectionName].isValidated;
      },
    }),
  },
  methods: {
    ...mapActions([
      "validateSection",
      "setQuizz",
      "addReponseQuizz",
      "resetReponsesQuizz",
      "sendSurvey",
    ]),
    validerQuizz() {
      return Promise.resolve()
        .then(() => this.validateSection(this.sectionName))
        .then(() => {
          if (this.isSectionValid) {
            return this.sendSurvey({
              survey: this.getState.survey,
              type: this.sectionName,
            });
          } else {
            return this.isSectionValid;
          }
        })
        .then(valide => {
          if (!valide) {
            return window.scroll(0, 0);
          }
          return this.$router.push({
            name: "BO.ListeQuizz",
          });
        });
    },
  },
  mounted() {
    if (this.idQuizz) {
      return Promise.resolve().then(() => this.setQuizz({ id: this.idQuizz }));
    } else {
      return Promise.resolve()
        .then(() => this.setQuizz({ id: this.idQuizz }))
        .then(() => this.resetReponsesQuizz())
        .then(() => this.addReponseQuizz())
        .then(() => this.addReponseQuizz());
    }
  },
};
</script>

<style></style>
