<template>
  <div :class="`${question.answerColClass || 'col'} field`">
    <v-select
      :name="question.labelInput"
      :taggable="question.taggable"
      :options="options"
      :placeholder="question.placeholder"
      :class="question.fieldClass"
      :modelValue="response"
      @option:selected="value => onInput(value)"
      @search="onSearch"
      selectOnTab
      :searchable="question.searchable"
      push-tags
    >
      <template #no-options>Pas de résultats</template>
    </v-select>
    <label v-if="question.labelInput">{{ question.labelInput }} <span v-if="required">*</span></label>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { map } from "lodash";
import VSelect from "vue-select";
import { isRequired } from "../../utils/validate";

import { getProp } from "../../utils/SurveyUtils";

export default {
  components: {
    VSelect,
  },
  props: ["question", "path", "responsePath"],
  data() {
    return {
      options: [],
    };
  },
  computed: {
        required() {
      return isRequired(this.question, this.$store.state);
    },
    response() {
      return getProp(this.question, this.responsePath, "response");
    },
    stockedResponse() {
      return getProp(this.question, "stockedResponse");
    },
  },
  methods: {
    ...mapActions(["setValue"]),
    onInput(value) {
      this.$store.dispatch("setValue", {
        path: this.path,
        responsePath: "stockedResponse",
        value: null,
      });
      this.$store.dispatch("setValue", {
        path: this.path,
        responsePath: this.responsePath,
        value: value,
      });
    },
    onSearch(search, loading, spinner) {
      if (this.question.optionsURL && search) {
        loading(true);
        this.$axios
          .get(`${process.env.VUE_APP_API_URL}/${this.question.optionsURL}/${search}`)
          .then(res => {
            this.options = map(res.data.data, d =>
              d.Ville.indexOf("CEDEX") > -1
                ? `${d.Ville.substring(0, d.Ville.indexOf("CEDEX"))} (${d.Code})`
                : `${d.Ville} (${d.Code})`
            );
            loading(false);
          });
      }
    },
  },
};
</script>
