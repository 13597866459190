import axios from "axios";
import { get, forEach, keys, split } from "lodash";
import { set as setFP } from "lodash/fp";
import moment from "moment";
import { getPath, getProp, execSurveyDeep } from "../utils/SurveyUtils";
import { getResponseFromSurvey } from "../utils/ServerParser";
import { ADMIN_ROLES } from "../constants/Properties";
import router from "../router";

export const CHECK_ERROR = "CHECK_ERROR";
export const CHECK_ERROR_FP = "CHECK_ERROR_FP";
export const CHECK_COMPLETED = "CHECK_COMPLETED";
export const SET_VALUE = "SET_VALUE";
export const SET_VALUE_GLOBAL = "SET_VALUE_GLOBAL";
export const SET_VALIDATE_SECTION = "SET_VALIDATE_SECTION";
export const SET_VALUE_DATE = "SET_VALUE_DATE";
export const RESET_QUESTION = "RESET_QUESTION";
export const VALIDATE_SECTION = "VALIDATE_SECTION";
export const FETCH_SURVEY = "FETCH_SURVEY";
export const RESET_VALUE = "RESET_VALUE";
export const GET_POSTAL_CODE = "GET_POSTAL_CODE";
export const TOGGLE_BT_NEXT = "TOGGLE_BT_NEXT";
export const ADD_PREVIOUS_SECTION = "ADD_PREVIOUS_SECTION";
export const POP_PREVIOUS_SECTION = "POP_PREVIOUS_SECTION";
export const SET_SURVEY = "SET_SURVEY";
export const SET_ID = "SET_ID";
export const SET_CAISSE = "SET_CAISSE";
export const ADD_LOT_JEU = "ADD_LOT_JEU";
export const REMOVE_LOT_JEU = "REMOVE_LOT_JEU";
export const SET_JEUX_UTILISATEUR = "SET_JEUX_UTILISATEUR";
export const SET_UTILISATEUR_AND_JEUX = "SET_UTILISATEUR_AND_JEUX";
export const RESET_LOTS_JEU = "RESET_LOTS_JEU";
export const SET_CURRENT_JEU = "SET_CURRENT_JEU";
export const RESET_LISTE_JEUX = "RESET_LISTE_JEUX";
export const SET_APP_FOOTER = "SET_APP_FOOTER";
export const SET_UTILISATEUR = "SET_UTILISATEUR";
export const SET_LISTE_JEUX = "SET_LISTE_JEUX";
export const ADD_REPONSE_QUIZZ = "ADD_REPONSE_QUIZZ";
export const RESET_REPONSES_QUIZZ = "RESET_REPONSES_QUIZZ";
export const REMOVE_REPONSE_QUIZZ = "REMOVE_REPONSE_QUIZZ";
export const SET_LISTE_QUIZZS = "SET_LISTE_QUIZZS";
export const SET_REPONSES_QUIZZ = "SET_REPONSES_QUIZZ";
export const SET_LOTS_JEU = "SET_LOTS_JEU";
export const REMOVE_JEU_FROM_LISTE = "REMOVE_JEU_FROM_LISTE";
export const ADD_JEU_TO_LISTE = "ADD_JEU_TO_LISTE";
export const SET_LOADING_JEUX = "SET_LOADING_JEUX";
export const SET_DEJA_JOUER = "SET_DEJA_JOUER";
export const SET_REPONDANT = "SET_REPONDANT";
export const SET_REFERRALS = "SET_REFERRALS";
export const SET_REFERRAL = "SET_REFERRAL";
export const RESET_REFERRALS = "RESET_REFERRALS";
export const REMOVE_REFERRAL = "REMOVE_REFERRAL";
export const CHECK_FILE_ERROR = "CHECK_FILE_ERROR";
export const SET_USER_LOGGING = "SET_USER_LOGGING";
export const SET_LISTE_IMAGES = "SET_LISTE_IMAGES";
export const SET_LOADING = "SET_LOADING";
export const REMOVE_IMAGE_FROM_LISTE = "REMOVE_IMAGE_FROM_LISTE";

axios.defaults.withCredentials = true;
// toutes les mutations sont appelées ici
const actions = {
  checkError({ commit }, data) {
    commit(CHECK_ERROR, data);
  },
  checkErrorFP({ commit }, data) {
    commit(CHECK_ERROR_FP, data);
  },
  checkFileError({ commit }, data) {
    commit(CHECK_FILE_ERROR, data);
  },
  resetValue({ commit }, data) {
    commit(RESET_VALUE, data);
  },
  setValue({ commit }, data) {
    commit(SET_VALUE, data);
  },
  setValueGlobal({ commit }, data) {
    commit(SET_VALUE_GLOBAL, data);
  },
  setValidateSection({ commit }, data) {
    commit(SET_VALIDATE_SECTION, data);
  },
  setValueDate({ commit }, data) {
    commit(SET_VALUE_DATE, data);
  },
  resetQuestion({ commit }, data) {
    commit(RESET_QUESTION, data);
  },
  validateSection({ commit }, data) {
    commit(VALIDATE_SECTION, data);
  },
  checkCompleted({ commit }, data) {
    commit(CHECK_COMPLETED, data);
  },
  fetchSurvey({ commit }, data) {
    // Si code client, on fait un appel a l'api
    if (data && data.codeClient) {
      return axios
        .get(`${process.env.VUE_APP_API_URL}/api/candidatures/${data.codeClient}`)
        .then(ret => {
          commit(FETCH_SURVEY, ret.data);
        })
        .catch(e => console.log(e));
    }
  },
  getAllPostalCode({ commit }) {
    commit(GET_POSTAL_CODE);
  },
  sendSurvey({ commit }, data) {
    console.log("Sending survey");

    let survey = data.survey;
    let responses = {};
    let arrayResponse = [];
    let type = data.type;
    execSurveyDeep(
      survey,
      (s, sn) => {
        if (s.serverPathValidated && get(survey, `sections.${sn}.isValidated`) === true) {
          responses = setFP(s.serverPathValidated, true, responses);
        }
      },
      (q, p, rp, isArray) => {
        const serverPath = getProp(q, "serverPath", rp);
        if (serverPath && q.questionType && getResponseFromSurvey[q.questionType]) {
          const values = getResponseFromSurvey[q.questionType](
            survey,
            getPath(p, rp, "response"),
            serverPath,
            q.resetServerPath,
            split(p, ".").pop(),
            p
          );
          if (Array.isArray(values)) {
            responses = setFP(serverPath, values, responses);
          } else if (isArray) {
            arrayResponse =
              get(responses, serverPath) !== undefined
                ? [...get(responses, serverPath), values]
                : [values];
            responses = setFP(serverPath, arrayResponse, responses);
          } else if (typeof values === "object" && keys(values).length > 0) {
            forEach(values, (value, path) => {
              responses = setFP(path, value, responses);
            });
          } else {
            // if (get(responses, serverPath) === undefined) {
            responses = setFP(serverPath, values, responses);
            // }
          }
        }
      }
    );
    let currentJeu = survey.sections.globals.currentJeu;
    let repondant = responses.Repondant;
    repondant.IdJeu = currentJeu.IdJeu;
    commit(SET_REPONDANT, repondant);

    let referrals = responses.RepondantReferral;
    commit(SET_REFERRALS, referrals);

    if (type === "bo_nouveau_jeu") {
      let user = survey.sections.globals.Utilisateur;
      return axios
        .post(`${process.env.VUE_APP_API_URL}/api/jeux/`, {
          data: {
            ...responses.NouveauJeu,
            IdUtilisateur: user.IdUtilisateur,
            // Si le JC est créé par la RDS, Conseiller ou un AVM, la diffusion sera de type Local. Si le JC est créé par Communication ou Admin, la diffusion est de type Régional.
            Diffusion: ADMIN_ROLES.includes(user.Role) ? "Régional" : "Local",
          },
        })
        .catch(e => console.log(e));
    }
    if (type === "bo_modifier_jeu") {
      let jeu = responses.Jeu;
      return axios
        .put(`${process.env.VUE_APP_API_URL}/api/jeux/${jeu.IdJeu}`, {
          data: {
            ...jeu,
          },
        })
        .then(res => {
          return res;
        })
        .catch(e => console.log(e));
    }
    if (
      [
        "jeu_concours_form",
        "jeu_instant_gagnant_form",
        "jeu_quizz_form",
        "jeu_grattage_form",
      ].indexOf(type) >= 0
    ) {
      delete repondant.ConnaissanceReglement;
      return axios
        .post(`${process.env.VUE_APP_API_URL}/api/repondants`, {
          data: {
            ...repondant,
          },
        })
        .catch(e => console.log(e)).then((response) => {
          let IdRepondant = response.data.data.repondant.IdRepondant;

          forEach(referrals, (referral) => {
            let data = {
              ...referral,
              IdRepondant: IdRepondant,
              IdJeu: repondant.IdJeu
            };

            return axios
              .post(`${process.env.VUE_APP_API_URL}/api/referral`, { data })
              .catch(e => console.log(e));
          });
        });
    }

    if (type === "quizz_questions") {
      let quizz = responses.Quizz;
      let data = {
        ...quizz,
      };
      if (quizz.IdQuizz) {
        return axios
          .put(`${process.env.VUE_APP_API_URL}/api/quizzs/${quizz.IdQuizz}`, { data })
          .catch(e => console.log(e));
      } else {
        return axios
          .post(`${process.env.VUE_APP_API_URL}/api/quizzs`, { data })
          .catch(e => console.log(e));
      }
    }

    if (type === "banque_images") {
      let image = responses.Image;
      if (image.IdImage) {
        return axios
          .put(`${process.env.VUE_APP_API_URL}/api/banque-images/${image.IdImage}`, { ...image })
          .catch(e => console.log(e));
      } else {
        return axios
          .post(`${process.env.VUE_APP_API_URL}/api/banque-images`, { ...image })
          .catch(e => console.log(e));
      }
    }
  },
  toggleBtNext({ commit }, data) {
    commit(TOGGLE_BT_NEXT, data);
  },
  addPreviousSection({ commit }, data) {
    commit(ADD_PREVIOUS_SECTION, data);
  },
  popPreviousSection({ commit }, data) {
    commit(POP_PREVIOUS_SECTION, data);
  },
  setSurvey({ commit }, data) {
    commit(SET_SURVEY, data);
  },
  addLotJeuState({ commit }, data) {
    commit(ADD_LOT_JEU, data);
  },
  removeLotJeuState({ commit }, data) {
    commit(REMOVE_LOT_JEU, data);
  },
  setJeuxUtilisateur({ commit }, data) {
    commit(SET_JEUX_UTILISATEUR, data);
  },
  setUtilisateurAndJeu({ commit }, data) {
    commit(SET_LOADING_JEUX, true);
    return axios
      .post(`${process.env.VUE_APP_API_URL}/api/utilisateurs/email/`, {
        email: data,
      })
      .then(({ data }) => {
        let user = data.data.utilisateur;
        commit(SET_UTILISATEUR, user);
        return user;
      })
      .then(user => {
        let apiJeuxPath = ADMIN_ROLES.includes(user.Role) ? "/" : `/jeuxutilisateur/${user.IdUtilisateur}`;
        return axios.get(`${process.env.VUE_APP_API_URL}/api/jeux${apiJeuxPath}`);
      })
      .then(({ data }) => {
        let listJeux = data.data.jeux;
        return commit(SET_LISTE_JEUX, listJeux);
      })
      .then(() => commit(SET_LOADING_JEUX, false))
      .catch(e => {
        commit(SET_LOADING_JEUX, false);
        if (e.response.status === 401) {
          console.log("Error", e.response);
          commit(SET_UTILISATEUR, { email: null });
          return router.push({
            name: "BO.Login",
          });
        }
        console.log("error ==> ", e.response.data.message);
        return router.push({
          name: "ErrorAPI",
          params: { id: e.response.status, errorMsg: e.response.data.message },
          // props: { errorMsg: e.response.data.message },
        });
      });
  },
  setJeu({ commit }, id) {
    if (id > 0) {
      return axios.get(`${process.env.VUE_APP_API_URL}/api/jeux/${id}`).then(({ data }) => {
        let lotsTirage = data.data.Jeu.Lots;
        let lotsGagnant = data.data.Jeu.LotsGagnants;
        commit(FETCH_SURVEY, data.data);
        commit(RESET_LOTS_JEU, { section: "bo_modifier_jeu", typeLots: "all" });
        if (lotsTirage.length > 0) {
          commit(SET_LOTS_JEU, { lots: lotsTirage, typeLots: "tirage" });
        }
        if (lotsGagnant.length > 0) {
          commit(SET_LOTS_JEU, { lots: lotsGagnant, typeLots: "gagnant" });
        }
      });
    } else {
      return commit(FETCH_SURVEY, {
        NouveauJeu: {
          HasNom: true,
          NomRequis: true,
          HasPrenom: true,
          PrenomRequis: true,
          HasDateNaissance: true,
          DateNaissanceRequis: true,
          HasTelephone: true,
          TelephoneRequis: true,
          HasEmail: true,
          EmailRequis: true,
          HasCPVille: true,
          CPVilleRequis: true,
          HasClientGroupama: true,
          ClientGroupamaRequis: true,
        },
      });
    }
  },
  resetLotsJeu({ commit }, data) {
    return commit(RESET_LOTS_JEU, data);
  },
  checkUserAlreadyPlay({ commit }, data) {
    return axios
      .post(`${process.env.VUE_APP_API_URL}/api/jeux/checkUserAlreadyPlay`, {
        jeuId: data.jeuId,
        repondant: data.repondant,
      })
      .then(res => res.data.data)
      .catch(e => console.log("err", e));
  },
  setCurrentJeu({ commit }, data) {
    if (data.type && data.type === "apercu") {
      let survey = data.survey;
      let arrayResponse = [];
      let responses = {};
      if (data.id) {
        return axios
          .get(`${process.env.VUE_APP_API_URL}/api/jeux/${data.id}`)
          .then(res => {
            data.jeu = res.data.data.Jeu;
            return commit(SET_CURRENT_JEU, data);
          })
          .catch(e => console.log(e));
      } else {
        return Promise.resolve()
          .then(() =>
            execSurveyDeep(
              survey,
              (s, sn) => {
                if (s.serverPathValidated && get(survey, `sections.${sn}.isValidated`) === true) {
                  responses = setFP(s.serverPathValidated, true, responses);
                }
              },
              (q, p, rp, isArray) => {
                const serverPath = getProp(q, "serverPath", rp);
                if (serverPath && q.questionType && getResponseFromSurvey[q.questionType]) {
                  const values = getResponseFromSurvey[q.questionType](
                    survey,
                    getPath(p, rp, "response"),
                    serverPath,
                    q.resetServerPath,
                    split(p, ".").pop(),
                    p
                  );
                  if (Array.isArray(values)) {
                    responses = setFP(serverPath, values, responses);
                  } else if (isArray) {
                    arrayResponse =
                      get(responses, serverPath) !== undefined
                        ? [...get(responses, serverPath), values]
                        : [values];
                    responses = setFP(serverPath, arrayResponse, responses);
                  } else if (typeof values === "object" && keys(values).length > 0) {
                    forEach(values, (value, path) => {
                      responses = setFP(path, value, responses);
                    });
                  } else {
                    // if (get(responses, serverPath) === undefined) {
                    responses = setFP(serverPath, values, responses);
                    // }
                  }
                }
              }
            )
          )
          .then(() => {
            data.jeu = data.fromSection === "bo_nouveau_jeu" ? responses.NouveauJeu : responses.Jeu;
            console.log("////apercu jeu", data.jeu);
            return commit(SET_CURRENT_JEU, data);
          });
      }
    } else {
      if (data.id) {
        return axios
          .get(`${process.env.VUE_APP_API_URL}/api/jeux/${data.id}`)
          .then(res => {
            data.jeu = res.data.data.Jeu;
            let now = moment();
            let debutJeu = moment(data.jeu.DateDebut);
            let finJeu = moment(data.jeu.DateFin);
            if (debutJeu > now || finJeu < now) {
              router.push({
                name: "Hors Délai",
                meta: { section: "horsdelai" },
              });
            }
            return commit(SET_CURRENT_JEU, data);
          })
          .catch(e => {
            return router.push({
              name: "Jeu non présent",
              meta: { section: "jeuNonPresent" },
            });
          });
      }
    }
    // return Promise.resolve().then(() => commit(SET_CURRENT_JEU, data));
  },
  resetListeJeux({ commit }) {
    return commit(RESET_LISTE_JEUX);
  },
  setAppFooter({ commit }, data) {
    return commit(SET_APP_FOOTER, data);
  },
  setListeQuizzs({ commit }) {
    return axios.get(`${process.env.VUE_APP_API_URL}/api/quizzs`).then(({ data }) => {
      return commit(SET_LISTE_QUIZZS, data.data.Quizzs);
    });
  },
  setQuizz({ commit }, data) {
    if (data.id) {
      return axios
        .get(`${process.env.VUE_APP_API_URL}/api/quizzs/${data.id}`)
        .then(res => {
          data.Quizz = res.data.data.Quizz;
          return commit(FETCH_SURVEY, data);
        })
        .then(() => commit(RESET_REPONSES_QUIZZ))
        .then(() => commit(SET_REPONSES_QUIZZ, data.Quizz.Reponses))
        .catch(e =>
          router.push({
            name: "ErreurQuizz",
          })
        );
    } else {
      return commit(FETCH_SURVEY, { Quizz: {} });
    }
  },
  addReponseQuizz({ commit }) {
    commit(ADD_REPONSE_QUIZZ);
  },
  resetReponsesQuizz({ commit }) {
    commit(RESET_REPONSES_QUIZZ);
  },
  removeReponseQuizz({ commit }, data) {
    commit(REMOVE_REPONSE_QUIZZ, data);
  },
  deleteQuizzDB({ commit }, data) {
    return axios.delete(`${process.env.VUE_APP_API_URL}/api/quizzs/${data.id}`).catch(err => {
      console.log("DELETE Quizz ErrorR : ", err.response);
      return "error";
    });
  },
  deleteJeuDBById({ commit }, data) {
    return axios
      .delete(`${process.env.VUE_APP_API_URL}/api/jeux/${data.id}`)
      .catch(err => {
        console.log("Erreur de la suppression du jeu", err.response.data.message);
        return "error";
      })
      .then(res => {
        if (res !== "error") {
          commit(REMOVE_JEU_FROM_LISTE, { id: data.id });
        }
      });
  },
  duplicateJeu({ commit }, data) {
    if (data.id) {
      return axios
        .get(`${process.env.VUE_APP_API_URL}/api/jeux/duplicate/${data.id}`)
        .catch(err => {
          console.log("erreur lors de la duplication du jeu: ", err.response);
          return "error";
        })
        .then(({ data }) => {
          let jeu = data.data.jeu;
          jeu.numbreRepondants = 0;
          commit(ADD_JEU_TO_LISTE, { jeu });
        });
    }
  },
  setDejaJouer({ commit }, data) {
    return commit(SET_DEJA_JOUER, data);
  },
  setUtilisateur({ commit }, data) {
    let email = data.email;
    commit(SET_USER_LOGGING, false);
    if (!email) {
      return commit(SET_UTILISATEUR, {});
    }
    return axios
      .post(`${process.env.VUE_APP_API_URL}/api/utilisateurs/email/`, {
        email: email,
      })
      .then(({ data }) => {
        let user = data.data.utilisateur;
        commit(SET_UTILISATEUR, user);
        return commit(SET_USER_LOGGING, user.Email.length > 0 || false);
      })
      .catch(e => {
        throw e;
      });
  },
  setListeImages({ commit }) {
    commit(SET_LOADING, true);
    return axios
      .get(`${process.env.VUE_APP_API_URL}/api/banque-images`)
      .then(({ data }) => {
        return commit(SET_LISTE_IMAGES, data.images);
      })
      .catch(e => console.log(e))
      .then(() => commit(SET_LOADING, false));
  },
  setImage({ commit }, data) {
    commit(SET_LOADING, true);
    if (data.id) {
      return axios
        .get(`${process.env.VUE_APP_API_URL}/api/banque-images/${data.id}`)
        .then(res => {
          data.Image = res.data.image;
          commit(FETCH_SURVEY, data);
          return commit(SET_LOADING, false);
        })
        .catch(e => {
          console.log(e);
          return commit(SET_LOADING, false);
        });
    } else {
      commit(FETCH_SURVEY, { Image: {} });
      return commit(SET_LOADING, false);
    }
  },
  deleteImageDB({ commit }, data) {
    return axios
      .delete(`${process.env.VUE_APP_API_URL}/api/banque-images/${data.id}`)
      .then(() => commit(REMOVE_IMAGE_FROM_LISTE, { id: data.id }))
      .catch(err => {
        console.log("DELETE Image ErrorR : ", err.response);
        return "error";
      });
  },
  setReferralState({ commit }, data) {
    commit(SET_REFERRAL, data);
  },
  resetReferralsState({ commit }, data) {
    commit(RESET_REFERRALS, data);
  },
  removeReferralState({ commit }, data) {
    commit(REMOVE_REFERRAL, data);
  }
};

export default actions;
