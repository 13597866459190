<template>
  <main>
    <div class="main-block confirmation-block">
      <div id="lotGagner" v-if="lotGagner" class="confirm-message">
        <div class="congrats">Félicitations !</div>
        <div class="row justify-content-center">
          <div class="lot-container">
            <div class="lot-order">Vous avez gagné</div>
            <div class="lot-visuel">
              <img :src="urlLot" :alt="lotGagner.Nom" />
            </div>
            <div class="lot-description">{{ lotGagner.Nom }}</div>
          </div>
        </div>
        <div class="message-bienvenue consigne" v-if="currentJeu.ConsigneRetraitLot">
          {{ currentJeu.ConsigneRetraitLot }}
        </div>
      </div>
      <div class="confirm-message">
        <p v-sane-html="confirmationText"></p>
        <p v-if="dateTirageJeu && inclusTirage">
          Le tirage au sort aura lieu le {{ dateTirageJeu }}
        </p>
      </div>
      <share-field v-if="isPartage" :isMobile="IsMobile" />
    </div>
    <div class="pages">
      <div></div>
      <div></div>
    </div>
  </main>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { map, filter, flatMap } from "lodash";
import { UAParser } from "ua-parser-js";
import ShareField from "../components/fields/ShareField.vue";

export default {
  components: { ShareField },
  data() {
    return {
      IsMobile: false,
      lotGagner: null,
      confirmationText: "Groupama Loire Bretagne vous remercie de votre participation&nbsp;!",
      inclusTirage: true,
    };
  },
  computed: {
    ...mapGetters(["currentJeu", "dateTirageJeu", "ListeQuizzs", "getRepondant"]),
    ...mapState({
      isPartage(state) {
        return state.survey.sections.globals.currentJeu.HasJeuPartage;
      },
    }),
    urlLot() {
      return `${process.env.VUE_APP_API_URL}/api/upload/${this.lotGagner.Visuel}`;
    },
  },
  created() {
    // check si c'est un mobile
    let uaParser = new UAParser();
    this.IsMobile = uaParser.getDevice().type === "mobile";
    // Récuperer le lot qui a été gagné par le repondant
    if (this.getRepondant.IdLotGagner > 0) {
      this.lotGagner = this.currentJeu.LotsGagnants.find(
        lot => lot.IdLot === this.getRepondant.IdLotGagner
      );
    }
    // cas d'un jeu Quizz check les bonnes réponses
    if (this.currentJeu.Type === "quizz") {
      let reponsesQuizzs = flatMap(this.ListeQuizzs, quizz => quizz.Reponses);
      let correctReponses = map(this.getRepondant.Reponses, reponse => {
        let correctReponseQuizz = filter(reponsesQuizzs, {
          IdQuizz: reponse.IdQuizz,
          IsCorrect: true,
        });
        return correctReponseQuizz[0].Reponse === reponse.Reponse;
      }).filter(Boolean).length;
      console.log(
        "correcte Reponses ",
        correctReponses,
        Object.keys(this.getRepondant.Reponses).length
      );
      this.confirmationText =
        correctReponses === Object.keys(this.getRepondant.Reponses).length
          ? this.confirmationText
          : "Dommage, vous n'avez pas répondu correctement au quiz !";
      this.inclusTirage = correctReponses === Object.keys(this.getRepondant.Reponses).length;
    }
  },
};
</script>
<style lang="scss" scoped></style>
