<template>
  <main>
    <div class="main-block confirmation-block">
      <i class="fal fa-check" />
      <div class="confirm-message">
        <p>Vous avez déjà participé à ce jeu</p>
      </div>
    </div>
    <div class="pages">
      <div></div>
      <div></div>
    </div>
  </main>
</template>

<style lang="scss" scoped></style>
