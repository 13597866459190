<template>
  <main>
    <div class="loading-wrapper" v-if="loadingJeux">
      <div class="loading">
        <div class="spinner-grow text-success"></div>
        Récupération des jeux...
      </div>
    </div>
    <div v-else>
      <div class="page-title">
        <h2
          @click="
            trieStatut = 'all';
            filterJeux();
          "
          style="cursor: pointer"
        >
          Mes Jeux Concours
        </h2>
        <div class="page-filters">
          <div id="filter_jeu_statut" class="btn-group">
            <button
              @click="
                trieStatut === 'all' ? (trieStatut = 'all') : (trieStatut = 'all');
                filterJeux();
              "
              :class="{
                'btn btn-success': trieStatut === 'all',
                'btn btn-light': trieStatut !== 'all',
              }"
            >
              Tous
            </button>
            <button
              @click="
                trieStatut === 'a_venir' ? (trieStatut = 'all') : (trieStatut = 'a_venir');
                filterJeux();
              "
              :class="{
                'btn btn-a_venir  btn-success': trieStatut === 'a_venir',
                'btn btn-a_venir btn-light': trieStatut !== 'a_venir',
              }"
            >
              A venir
            </button>
            <button
              @click="
                trieStatut === 'en_cours' ? (trieStatut = 'all') : (trieStatut = 'en_cours');
                filterJeux();
              "
              :class="{
                'btn btn-en_cours btn-success': trieStatut === 'en_cours',
                'btn btn-en_cours btn-light': trieStatut !== 'en_cours',
              }"
            >
              En Cours
            </button>
            <button
              @click="
                trieStatut === 'termine' ? (trieStatut = 'all') : (trieStatut = 'termine');
                filterJeux();
              "
              :class="{
                'btn btn-termine btn-success': trieStatut === 'termine',
                'btn btn-termine btn-light': trieStatut !== 'termine',
              }"
            >
              Terminé
            </button>
          </div>
          <div id="filter_jeu_type">
            <select
              v-model="typeJeu"
              @change="filterJeux()"
            >
              <option value="all">Tous les types de jeux</option>
              <option v-for="(typeLabel, type) in TypesJeux" :key="type" :value="type" :selected="typeJeu === type">{{ typeLabel }}</option>
            </select>
          </div>
          <div id="filter_jeu_recherche">
            <input
              type="text"
              v-model="searchJeu"
              @input="filterJeux()"
              placeholder="Rechercher un nom"
            />
            <i class="fas fa-search"></i>
          </div>
        </div>
        <div class="main-button">
          <router-link
            :to="{ name: 'BO.CreationJeu' }"
            class="btn btn-primary"
            >Créer un nouveau jeu</router-link
          >
        </div>
      </div>
      <div class="message-bienvenue">
        Bienvenue sur la plateforme de jeux concours Groupama.
        <br />Tirage au sort, jeu de grattage, instant gagnant, quiz… En quelques minutes, créez le
        formulaire qui vous correspond !
        <br />
        Cliquez dès maintenant sur « Créer un nouveau jeu » pour commencer.
        <br />
        <span
          >En cas de question vous pouvez contacter Céline LUCAS, Pierre-Henri GUEGUEN, Audrey OGER,
          Juliette BAGUET ou Adeline PENGUILLY.</span
        >
      </div>
      <div class="content-wrapper">
        <div v-if="CurrentJeux.length > 0" class="row row-cols-1 row-cols-sm-2 row-cols-lg-3">
          <jeu-concours v-for="(jeu, index) in CurrentJeux" :key="index" :jeu="jeu" />
        </div>
        <div v-else>Aucun résultat</div>
      </div>
    </div>
    <footer v-if="nbPages > 1">
      <div class="pagination">
        <button class="page-number" v-if="currentPage > 0" @click="nextJeuxPage(currentPage)">
          <i class="far fa-chevron-left" />
        </button>

        <button
          :class="[i === currentPage + 1 && 'active', 'page-number']"
          v-for="i in nbPages"
          :key="i"
          @click="nextJeuxPage(i)"
        >
          <span>{{ i }}</span>
        </button>
        <button
          class="page-number"
          v-if="currentPage < nbPages - 1"
          @click="nextJeuxPage(currentPage + 2)"
        >
          <i class="far fa-chevron-right" />
        </button>
      </div>
    </footer>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { result } from "lodash";
import JeuConcours from "../../../components/JeuConcours.vue";

export default {
  data() {
    return {
      nbPages: 0,
      nbJeuxPage: 15,
      currentPage: 0,
      searchJeu: null,
      typeJeu: "all",
      trieStatut: "all",
      listCurrentJeux: [],
    };
  },
  components: { JeuConcours },
  computed: {
    ...mapGetters(["ListeJeux", "Utilisateur", "loadingJeux"]),
    CurrentJeux() {
      let n = this.currentPage * this.nbJeuxPage;
      let m = n + this.nbJeuxPage;
      this.nbPages = Math.ceil(this.listCurrentJeux.length / this.nbJeuxPage);
      return this.listCurrentJeux.slice(n, m);
    },
    TypesJeux() {
      let path = "survey.sections.bo_nouveau_jeu.questions.jeu.questions.jeu_infos.questions.type_jeu.options";
      return result(this.$store.state, path);
    }
  },
  methods: {
    ...mapActions(["setUtilisateurAndJeu", "resetListeJeux"]),
    nextJeuxPage(i) {
      this.currentPage = i - 1 < 0 ? 0 : i - 1 > this.nbPages - 2 ? this.nbPages - 1 : i - 1;
    },
    filterJeux(status) {
      this.currentPage = 0;
      let now = moment();

      switch (this.trieStatut) {
        case "a_venir":
          this.listCurrentJeux = this.ListeJeux.filter(jeu => {
            let dateDebut = moment(jeu.DateDebut);
            return now < dateDebut;
          });
          break;
        case "en_cours":
          this.listCurrentJeux = this.ListeJeux.filter(jeu => {
            let dateDebut = moment(jeu.DateDebut);
            let dateFin = moment(jeu.DateFin);
            return now >= dateDebut && now <= dateFin;
          });
          break;
        case "termine":
          this.listCurrentJeux = this.ListeJeux.filter(jeu => {
            let dateFin = moment(jeu.DateFin);
            return now > dateFin;
          });
          break;
        case "all":
          this.listCurrentJeux = this.ListeJeux;
          break;
        default:
          this.listCurrentJeux = this.ListeJeux;
          break;
      }
      if (this.typeJeu) {
        this.listCurrentJeux = this.listCurrentJeux.filter(jeu => {
          return this.typeJeu === "all" ? true : jeu.Type === this.typeJeu;
        });
      }
      if (this.searchJeu) {
        let search = new RegExp(this.searchJeu, "i");
        this.listCurrentJeux = this.listCurrentJeux.filter(jeu => {
          return search.test(jeu.Nom);
        });
      }
    },
  },
  created() {},
  mounted() {
    // let email = __DEV__ ? "hilal+PJC@linaia.com" : "support+pjc@linaia.com";
    return Promise.resolve()
      .then(() => this.resetListeJeux())
      .then(() => this.setUtilisateurAndJeu(this.Utilisateur.Email))
      .then(() => (this.listCurrentJeux = this.ListeJeux));
  },
  watch: {
    ListeJeux(newValue, oldValue) {
      this.listCurrentJeux = newValue;
    },
  },
};
</script>
