<template>
  <div class="col-12">
    <div id="scratchCardWrapper" @mousedown="showDiv = false" @touchstart="showDiv = false">
      <div v-if="!completeGrattage && showDiv" class="grattage-message-start">
        Grattez l'image
        <span>Pour découvrir si vous avez gagné</span>
      </div>
      <wc-scratch brush-size="40" percentage-update="true" @percentage-update="onComplete">
        <div class="scratchCardContainer" :style="{width: cardWidth + 'px', height: cardHeight + 'px'}">
          <div v-if="completeGrattage && !showDiv">
            <div v-if="completeGrattage && isWin" class="grattage-message success">
              Félicitations !
              <span
                >Vous avez gagné et vous pouvez participer au tirage au sort en cliquant sur le
                bouton ci-dessous.</span
              >
            </div>
            <div v-if="completeGrattage && !isWin" class="grattage-message">
              Désolé
              <span
                >Vous n'avez pas gagné mais vous pouvez participer au tirage au sort en cliquant sur
                le bouton ci-dessous.</span
              >
            </div>
          </div>
          <img :src="urlVisuelLot" />
        </div>
      </wc-scratch>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import "wc-scratch";
import { filter } from "lodash";

export default {
  props: ["question", "path", "responsePath"],
  computed: {
    ...mapGetters(["dejaJouer", "currentJeu"]),
    urlVisuelLot() {
      return this.lotGagner.sourceImg;
    },
    urlVisuelJeu() {
      return `${process.env.VUE_APP_API_URL}/api/upload/${this.currentJeu.Visuel}`;
    },
  },
  data() {
    return {
      showDiv: true,
      renderCount: 0,
      cardWidth: 768,
      cardHeight: 570,
      finishPercent: 40,
      completeGrattage: false,
      isWin: false,
      lotGagner: null,
    };
  },
  methods: {
    ...mapActions(["setDejaJouer", "setValue"]),
    onComplete(percentage) {
      if (percentage.detail >= 40 && !this.completeGrattage){
        console.log("Scratch done");
        this.completeGrattage = true;
        this.setDejaJouer(true);
        this.setValue({
          path: this.path,
          responsePath: this.responsePath,
          value: this.isWin ? this.lotGagner.IdLot : -1,
        });

        // Hack to show the "next" button, as it is not in the same dom node.
        let elem = document.querySelector(".jeu_grattage_form + section.content main .footer button.btn.btn-primary");
        elem.style.zIndex = 2;
      }
    },
  },
  created() {
    if (this.dejaJouer) {
      return this.$router.push({
        name: "Deja participer",
        meta: { section: "dejaParticiper" },
      });
    } else {
      let windowWidth = window.innerWidth;
      this.cardWidth = windowWidth > 768 ? 728 : windowWidth - 40;
      this.cardHeight = windowWidth >= 768 ? 542 : 340;
      let lot = {};
      let lotsTirage = filter(this.currentJeu.LotsGagnants, lot => lot.Nombre > lot.NbTirage);
      this.isWin = lotsTirage.length > 0 ? Math.random() <= 0.5 : false;
      if (this.isWin) {
        const lotData = lotsTirage[Math.floor(Math.random() * lotsTirage.length)];
        lot = {
          ...lotData,
          win: true,
          sourceImg: `${process.env.VUE_APP_API_URL}/api/upload/${lotData.Visuel}`,
        };
      } else {
        lot = {
          win: false,
          sourceImg: `${process.env.VUE_APP_API_URL}/api/upload/cerise.jpg`,
        };
      }
      this.lotGagner = lot;
    }
  },
};
</script>
