<template>
  <main>
    <h2>Création d'un nouveau Quiz</h2>
    <form-quizz></form-quizz>
  </main>
</template>

<script>
import FormQuizz from "./FormQuizz.vue";

export default {
  components: { FormQuizz },
  beforeRouteEnter(to, from, next) {
    return next();
  },
};
</script>

<style>
</style>
