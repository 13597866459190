<template>
  <div :class="`${question.answerColClass || 'col'} field`">
    <div class="dropzone">
      <input
        type="file"
        id="inputFile"
        name="inputFile"
        ref="inputFile"
        @change="loadFile"
        :accept="question.accept"
        style="display: none"
      />
      <div class="picture-buttons" ref="dropDiv">
        <div class="button" v-if="!getUrl" @drop="loadFile" @click="$refs.inputFile.click()">
          <span>{{ question.loadBtnLabel }}</span>
        </div>
      </div>

      <div id="preview">
        <div v-if="url" class="btn">
          <img :src="getUrl" alt="Visuel" />
          <a class="remove" @click="removeFile">
            <i class="far fa-times"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { split, replace, get } from "lodash";
import { mapActions } from "vuex";

export default {
  props: ["question", "path", "responsePath"],
  data() {
    return {
      fileName: "",
      url: "",
    };
  },
  computed: {
    getUrl() {
      return this.url;
    },
  },
  methods: {
    ...mapActions(["setValue", "checkFileError"]),
    loadFile(e) {
      const file = e.dataTransfer
        ? e.dataTransfer.files[0]
        : e.srcElement
        ? e.srcElement.files[0]
        : null;
      if (file) {
        let extensions = split(replace(replace(this.question.accept, / /g, ""), /\./g, ""), ",");
        this.checkFileError({
          rules: {
            accept: "Les formats autorisés sont : jpg, jpeg et png",
            weight: "la taille maximume est de 10Mo",
          },
          path: this.path,
          response: this.responsePath,
          value: { file: file, extensions: extensions },
        }).then(res => {
          if (get(this.$store.state.survey, this.path).error) {
            return;
          }

          let formData = new FormData();
          formData.append("file", file);
          this.$axios
            .post(`${process.env.VUE_APP_API_URL}/api/upload`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(res => {
              this.fileName = res.data.data.fileName;
              this.setValue({
                path: this.path,
                response: this.responsePath,
                value: this.fileName,
              });
              this.url = URL.createObjectURL(file);
            });
        });
      }
    },
    removeFile() {
      this.$axios.delete(`${process.env.VUE_APP_API_URL}/api/upload/${this.fileName}`).then(res => {
        this.url = "";
        this.fileName = "";
        this.setValue({
          path: this.path,
          response: this.responsePath,
          value: null,
        });
      });
    },
  },
  created() {
    if (this.question.response) {
      this.fileName = this.question.response;
      this.url = `${process.env.VUE_APP_API_URL}/api/upload/${this.fileName}`;
    }
  },
  mounted() {
    [
      /* Listen to all of the drag events and bind an event listener to each for the fileform. */
      ("drag", "dragstart", "dragend", "dragover", "dragenter", "dragleave", "drop"),
    ].forEach(
      function (evt) {
        this.$refs.dropDiv.addEventListener(
          evt,
          function (e) {
            e.preventDefault();
            e.stopPropagation();
          },
          false
        );
      }.bind(this)
    );
  },
};
</script>

<style></style>
