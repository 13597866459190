<template>
  <main>
    <h2>Nouvelle Image</h2>
    <form-image />
  </main>
</template>

<script>
import FormImage from "./FormImage.vue";
import store from "../../../store";
export default {
  components: { FormImage },
  name: "AddImage",
  beforeRouteEnter(to, from, next) {
    return store.dispatch("setImage", { id: null }).then(() => next());
  },
};
</script>

<style></style>
