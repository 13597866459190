<template>
  <div :class="`${question.questionClass || 'col'} field`">
    <button class="btn btn-primary btn-lg" @click="copy()" target="_blank">
      {{ question.buttonLabel }}
    </button>
  </div>
</template>

<script>
export default {
  props: ["question", "path", "responsePath"],
  methods: {
    copy() {
      let idChamps = this.question.fieldtocopy;
      let champs = document.getElementById(idChamps);
      console.log("======> Champs", champs, champs.value);
      champs.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        alert("Impossible de copier le champs");
      }
    },
  },
};
</script>

<style>
</style>
