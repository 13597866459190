<template>
  <div :class="`${question.answerColClass || 'col'} field`">
    <div class="dropzone">
      <input
        type="file"
        id="inputFile"
        name="inputFile"
        ref="inputFile"
        @change="loadFile"
        :accept="question.accept"
        style="display: none"
      />
      <div class="picture-buttons" ref="dropDiv">
        <div class="button" v-if="!getUrl" @drop="loadFile" @click="$refs.inputFile.click()">
          <i class="fad fa-file-upload"></i>
          <span>{{ question.loadBtnLabel }}</span>
        </div>
        <button
          v-if="!getUrl && question.hasSelectImage"
          class="button"
          @click="
            showSelctImage = true;
            fromDrapDrop = false;
          "
        >
          <i class="fad fa-images"></i>
          <span>Sélectionner une image</span>
        </button>
        <select-image
          v-if="showSelctImage"
          :question="question"
          :path="path"
          :responsePath="responsePath"
          v-model="showSelctImage"
          :typeSelect="question.selectImageType"
        />
      </div>
    </div>
    <div id="preview" v-if="getUrl">
      <div class="picture-wrapper">
        <img :src="getUrl" alt="Visuel" />
        <a class="btn-remove" @click="removeFile">
          <i class="far fa-times"></i>
        </a>
      </div>
      <button class="btn-crop" @click="toCrop = true" v-if="!toCrop && question.hasCropper">
        Recadrer la photo
      </button>
    </div>
    <modal-confirmation-field v-if="toCrop" @close="toCrop = false" @confirm="cropeImage()">
      <template #confirmationBtn>
        <span>Valider</span>
      </template>
      <template #headerTitle>
        <h5>Recadrement du visuel</h5>
      </template>
      <template #confirmationMessage>
        <cropper
          @change="getCanavas"
          :src="getUrl"
          ref="cropper"
          :min-width="question.crop.minWidth || 200"
          :min-height="question.crop.minHeight || 200"
          :stencil-size="
            question.crop.fixedSize
              ? {
                  width: question.crop.width,
                  height: question.crop.height,
                }
              : null
          "
          :stencil-props="{
            aspectRatio: question.crop.ratio || null,
            handlers: question.crop.resizable
              ? { north: true, west: true, south: true, east: true }
              : {},
            movable: question.crop.movable,
            resizable: question.crop.resizable,
          }"
        />
        <div v-if="question.crop.showCoordinates">
          <div class="filed">
            <label class="question-title">Largeur :</label>
            <span>{{ coordinates.width }} pixels</span>
          </div>
          <div class="filed">
            <label class="question-title">Hauteur : </label>
            <span> {{ coordinates.height }} pixels </span>
          </div>
        </div>
      </template>
    </modal-confirmation-field>
  </div>
</template>

<script>
import { split, replace, get } from "lodash";
import { mapActions } from "vuex";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import ModalConfirmationField from "./ModalConfirmationField.vue";
import SelectImage from "./SelectImage.vue";

export default {
  props: ["question", "path", "responsePath"],
  components: { Cropper, ModalConfirmationField, SelectImage },
  data() {
    return {
      fileName: "",
      fromDrapDrop: false,
      toCrop: false,
      showSelctImage: false,
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },
    };
  },
  computed: {
    getUrl() {
      return this.question.response
        ? `${process.env.VUE_APP_API_URL}/api/upload/${this.question.response}`
        : null;
    },
  },
  methods: {
    ...mapActions(["setValue", "checkFileError"]),
    loadFile(e) {
      const file = e.dataTransfer
        ? e.dataTransfer.files[0]
        : e.srcElement
        ? e.srcElement.files[0]
        : e instanceof File
        ? e
        : null;
      if (file) {
        let extensions = split(replace(replace(this.question.accept, / /g, ""), /\./g, ""), ",");
        return this.checkFileError({
          rules: {
            accept: "Les formats autorisés sont : jpg, jpeg et png",
            weight: "la taille maximume est de 10Mo",
          },
          path: this.path,
          response: this.responsePath,
          value: { file: file, extensions: extensions },
        }).then(res => {
          if (get(this.$store.state.survey, this.path).error) {
            return;
          }

          let formData = new FormData();
          formData.append("file", file);
          this.$axios
            .post(`${process.env.VUE_APP_API_URL}/api/upload`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(res => {
              this.fileName = res.data.data.fileName;
              this.setValue({
                path: this.path,
                response: this.responsePath,
                value: this.fileName,
              });
              this.fromDrapDrop = true;
              this.url = URL.createObjectURL(file);
            });
        });
      }
    },
    removeFile() {
      return Promise.resolve(() => {
        if (this.fromDrapDrop) {
          return this.$axios.delete(`${process.env.VUE_APP_API_URL}/api/upload/${this.fileName}`);
        }
      }).then(res => {
        this.url = "";
        this.fileName = "";
        this.fromDrapDrop = false;
        this.showSelctImage = false;
        this.setValue({
          path: this.path,
          response: this.responsePath,
          value: null,
        });
      });
    },
    cropeImage() {
      const { canvas } = this.$refs.cropper.getResult();
      let file = this.dataURLtoFile(canvas.toDataURL(), "test.png");
      this.toCrop = false;
      return this.removeFile().then(() => this.loadFile(file));
    },
    dataURLtoFile(dataUrl, fileName) {
      let arr = dataUrl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, { type: mime });
    },
    getCanavas({ coordinates, canvas }) {
      this.coordinates = coordinates;
    },
  },
  created() {
    if (this.question.response) {
      this.fileName = this.question.response;
      this.url = `${process.env.VUE_APP_API_URL}/api/upload/${this.fileName}`;
    }
    // to prevent read of undifiend properties i f crop was not defined.
    this.question.crop = { ...this.question.crop };
  },
  mounted() {
    [
      /* Listen to all of the drag events and bind an event listener to each for the fileform. */
      ("drag", "dragstart", "dragend", "dragover", "dragenter", "dragleave", "drop"),
    ].forEach(
      function (evt) {
        this.$refs.dropDiv.addEventListener(
          evt,
          function (e) {
            e.preventDefault();
            e.stopPropagation();
          },
          false
        );
      }.bind(this)
    );
  },
};
</script>

<style></style>
