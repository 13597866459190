<template>
    <div class="col-12">
      <label v-if="question.labelInput"
        >{{ question.labelInput }} <span v-if="required">*</span></label
      >
      <div :class="`${question.answerColClass} field`">
        <a class="btn btn-primary" @click="addReferral" target="_blank">{{ question.buttonLabel }}</a>
      </div>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { isRequired } from "../../utils/validate";

export default {
  props: ["question", "path", "responsePath"],
  computed: {
    ...mapState({
      nbReferrals: state => {
        return state.sections.globals.nombreReferrals;
      },
      required() {
        return isRequired(this.question, this.$store.state);
      },
    }),
  },
  methods: {
    ...mapActions({
      addReferral: "setReferralState",
    }),
  },
};
</script>
