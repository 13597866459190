/*
 * ACCOUNTS_ROLES
 */
export const ACCOUNTS_ROLES = {
  ADMIN: "ADMIN",
  CONSEILLER: "CONSEILLER",
  RDS: "RDS",
  COM: "COM",
  AVM: "AVM"
};

/*
 * ADMIN_ROLES
 */
export const ADMIN_ROLES = [
  ACCOUNTS_ROLES.ADMIN,
  ACCOUNTS_ROLES.COM
];