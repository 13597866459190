<template>
  <div :class="`${question.answerColClass || 'col'} field`">
    <div class="checkbox-wrapper">
      <input
        :class="[question.fieldClass]"
        :checked="response"
        :disabled="isDisabled"
        @change="onClick"
        type="checkbox"
        :id="question.serverPath"
      />
      <label :for="question.serverPath"></label>
    </div>
    <label v-if="question.labelInput" :for="question.serverPath"
      >{{ question.labelInput }} <span v-if="required">*</span></label
    >
    <label v-if="question.labelInputHtml" :for="question.serverPath">
      <component v-if="question.labelInputHtml" :is="renderHtml"></component>
      <span v-if="required">*</span>
    </label>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { getOptions, getProp, getPath } from "../../utils/SurveyUtils";
import { isRequired } from "../../utils/validate";

export default {
  props: ["question", "path", "responsePath", "isChecked", "isDisabled"],
  data: () => ({
    response: false,
  }),
  computed: {
    renderHtml() {
      return {
        template: this.question.labelInputHtml,
      };
    },
    required() {
      return isRequired(this.question, this.$store.state);
    },
  },
  watch: {
    ...mapActions(["setValue"]),
    "question.response" (newValue, oldValue){ // On update la valeur si un autre champ le necessite (avec resetOtherServerPath ou setOtherServerPath)
      if (newValue && newValue !== oldValue && newValue !== this.response || newValue == null && this.question.resetOtherServerPath == null) {
        this.updateResponse();
      }
    },
    isChecked(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setValue({
          path: this.path,
          responsePath: this.responsePath,
          value: newValue,
        });
      }
    },
  },
  mounted(){
    this.updateResponse();
  },
  methods: {
    ...mapActions(["resetQuestion", "setValue"]),
    getOptions,
    getPath,
    updateResponse(){
      this.response = getProp(this.question, this.responsePath, "response");
    },
    onClick(event) {

      // Quand on coche cette case, est-ce qu'on doit en reset une autre ?
      if (this.question.resetOtherServerPath) {
        this.resetQuestion({ path: this.question.resetOtherServerPath });
      }

      // Quand on coche cette case, est-ce qu'on doit en cocher une autre ?
      if (this.question.setOtherServerPath) {
        this.setValue({
          path: this.question.setOtherServerPath.path,
          value: this.question.setOtherServerPath.value,
        });
      }

      this.response = event.target.checked;
      this.setValue({
        path: this.path,
        responsePath: this.responsePath,
        value: this.response || false,
      });
    },
  },
};
</script>
