<template>
  <main class="reglement">
    <div v-if="currentJeu.Reglement" v-sane-html="currentJeu.Reglement" class="container ql-editor"></div>
    <div v-else class="container">
      <div id="title">
        <h1>REGLEMENT COMPLET DU JEU GROUPAMA</h1>
        <h2>{{ currentJeu.Nom }}</h2>
      </div>
      <div id="article1">
        <h3>Article 1 : Nature du jeu</h3>
        <p>
          Groupama Loire Bretagne, entreprise régie par le code des assurances dont le siège social
          est situé 23, boulevard Solférino – CS 51209 – 35012 RENNES cedex, organise un jeu gratuit
          et sans obligation d’achat du {{ DateFormat(currentJeu.DateDebut) }} au
          {{ DateFormat(currentJeu.DateFin) }}, intitulé {{ currentJeu.Nom }}, selon les modalités
          indiquées aux articles ci-après.
        </p>
      </div>
      <div id="article2">
        <h3>Article 2 : Participants</h3>
        <p>
          Ce jeu est ouvert à toutes personnes âgées d’au moins 18 ans :
          <ul>
            <li>destinataires d’un email leur proposant de participer au jeu, </li>
            <li>informées de l’organisation du jeu via une publication sur les réseaux sociaux, </li>
            <li>intéressées par une participation au jeu lors du passage sur un salon en flashant un QR code permettant d’accéder directement à celui-ci.</li>
          </ul>
        </p>
        <p>
          <strong>
            <p>
              Ne peuvent pas participer à ce jeu :
              <ul>
                <li>les membres du personnel de la société organisatrice Groupama Loire Bretagne ayant participé à l’organisation du jeu, ainsi que les membres de leurs familles,</li>
                <li>les membres du personnel du prestataire assurant la maintenance et l’hébergement de la plateforme Jeux concours et les membres de leurs familles,</li>
                <li>toutes autres personnes ayant participé à quelque titre que ce soit à l’organisation du jeu, ainsi que les membres de leur famille. </li>
              </ul>
            </p>
          </strong>
        </p>
      </div>
      <div id="article3">
        <h3>Article 3 : Modalités de participation</h3>
        <p>
          Pour participer au jeu, chaque participant doit :
          <ul>
            <li>accéder à la plateforme Jeux concours via l’URL du jeu,</li>
            <li>compléter le formulaire d’inscription en ligne en renseignant les champs demandés,</li>
            <li>prendre connaissance du présent règlement de jeu et cocher la case dédiée à cet effet, </li>
            <li>cliquer sur le bouton permettant de valider son inscription au jeu. </li>
          </ul>
        </p>
        <p>Chaque joueur peut participer plusieurs fois au jeu mais seule une participation (mêmes adresse email et/ou numéro de téléphone) sera prise en compte pour l’attribution des lots gagnés par tirage au sort.</p>
        <p>Toutes informations ou coordonnées incomplètes, erronées ou en violation au règlement entraineront la nullité de la participation et le participant concerné ne pourra donc pas être éligible aux lots mis en jeu.</p>
        <p>D’une façon générale, la Société organisatrice se réserve le droit d’annuler l’attribution d’un lot si le gagnant ne satisfait pas aux conditions de participation au jeu définies par le présent règlement.</p>
      </div>
      <div id="article4">
        <h3>Article 4 : Modalités du jeu </h3>
        <p v-if="currentJeu.Type === 'classic'">
          Toute personne ayant complété le formulaire d’inscription en ligne et validé son inscription au jeu participe automatiquement au tirage au sort. Un message confirme au joueur la prise en compte de sa participation et lui indique la date du tirage au sort.
        </p>
        <p v-if="currentJeu.Type === 'reco'">
          Toute personne ayant complété le formulaire d’inscription en ligne et validé son inscription au jeu participe automatiquement au tirage au sort. Un message confirme au joueur la prise en compte de sa participation et lui indique la date du tirage au sort.
        </p>
        <p v-if="currentJeu.Type === 'grattage'">
           Toute personne ayant complété le formulaire d’inscription en ligne et validé son inscription au jeu voit s’afficher à l’écran une image grattable. Une fois l’image grattée, le joueur sait immédiatement s’il a gagné ou perdu. En cas de gain, l’image affichée est celle du lot gagné, sinon c’est l’image de Cerise de Groupama.
        </p>
        <p v-if="currentJeu.Type === 'quizz'">
           Toute personne ayant complété le formulaire d’inscription en ligne et validé son inscription au jeu doit répondre à différentes questions portant sur des thématiques diverses. Pour chaque question, le joueur doit sélectionner une seule réponse et cliquer sur le bouton « valider ». <br>
           Tout joueur ayant coché les bonnes réponses aux questions du quiz participe au tirage au sort. Un message lui confirme sa participation et la date du tirage au sort.
        </p>
        <p v-if="currentJeu.Type === 'instant_gagnant'">
            Toute personne ayant complété le formulaire d’inscription en ligne et validé son inscription au jeu voit s’afficher à l’écran différentes images sous forme de roue linéaire. Un clic sur le bouton « jouer » active la roue qui s’arrête de manière aléatoire soit sur le visuel du lot gagné si le joueur est gagnant, soit sur le visuel de Cerise de Groupama si le joueur est perdant.
        </p>
      </div>
      <div id="article5">
        <h3>Article 5 : Dotations</h3>
        Le jeu est doté des lots suivants :
        <div>
          <ul>
            <li v-if="currentJeu.Lots && currentJeu.Lots.length > 0">
              <strong>Lots à gagner par tirage au sort :</strong>
              <ul>
                <li v-for="(lot,index) in currentJeu.Lots" :key="index">{{lot.Nombre}} {{lot.Nom}}</li>
              </ul>
            </li>
            <li v-if="currentJeu.LotsGagnants && currentJeu.LotsGagnants.length > 0">
              <strong v-if="currentJeu.Type === 'grattage'">Lots à gagner par grattage :</strong>
              <strong v-if="currentJeu.Type === 'instant_gagnant'">Lots à gagner par instant gagnant :</strong>
              <ul>
                <li v-for="(lot,index) in currentJeu.LotsGagnants" :key="index">{{lot.Nombre}} {{lot.Nom}}</li>
              </ul>
            </li>
          </ul>
        </div>
        <p>
          Les lots sont nominatifs, non cessibles et non transmissibles.
          Ils ne peuvent être repris ni remboursés. Les lots attribués aux gagnants ne peuvent donner lieu à aucune contestation et ne pourront en aucun cas être échangés à la demande des gagnants contre leur valeur en espèces ou contre toute autre dotation pour quelque raison que ce soit.
        </p>
      </div>
      <div id="article6">
        <h3>Article 6 : Remise des lots</h3>
        <!-- <p v-if="currentJeu.Type === 'classic' || currentJeu.Type === 'quizz'"> -->
        <p>
          Un tirage au sort sera effectué le {{DateFormat(currentJeu.DateTirage)}}, parmi tous les participants ayant validé leur participation au jeu, afin d’attribuer les lots mis en jeu.
          Les gagnants seront informés individuellement de leur gain, par tout moyen (mail ou téléphone) à la disposition de la Société Organisatrice.
          Les dotations seront remises sur un lieu à définir proche du domicile des gagnants.
        </p>
        <p v-if="currentJeu.Type === 'grattage' || currentJeu.Type === 'instant_gagnant'">
          La révélation du lot est immédiate. Le lot est remis directement au gagnant par le représentant de la société organisatrice présent sur le stand ou lors de l’événement.
        </p>
      </div>
      <div id="article7">
        <h3>Article 7 : Données à caractère personnel</h3>
        <p>Les données personnelles des participants au jeu sont traitées dans le respect de la règlementation relative à la protection des données personnelles notamment le RGPD (UE 2016/679) et la loi Informatique et Libertés n° 78-17 du 6 Janvier 1978 modifiée.</p>
        <p>Ces données sont nécessaires à la gestion de leur participation au jeu par la Société organisatrice (responsable de traitement dont la base légale est l’intérêt légitime), ses prestataires et partenaires.</p>
        <p>Les participants autorisent, par avance et du fait de leur participation au jeu, la Société organisatrice à publier, le cas échéant, leurs nom, prénom, photographie et le nom de leur commune sur tout support publicitaire interne, y compris sur les différents sites et espaces en ligne et toutes publications internes lors de toutes actions de communication afférentes au présent Jeu, sans que cette publication puisse leur ouvrir droit à quelconque indemnité ou rémunération de quelque nature que ce soit. <br>
        Les données personnelles sont conservées pendant la durée du Jeu et pour permettre de satisfaire aux obligations légales de la Société organisatrice.</p>
        <p>Tout participant au présent Jeu peut exercer ses droits d’accès, de rectification, de suppression, de portabilité, de limitation et d’opposition, aux données le concernant, en écrivant à Groupama Loire Bretagne, Délégué à la Protection des Données, 23 Boulevard Solférino CS 51209 35012 Rennes cedex ou par mail à l’adresse <a href="mailto:contactdrpo@groupama-loire-bretagne.fr">contactdrpo@groupama-loire-bretagne.fr</a>.</p>
        <p>Les participants peuvent, pour des motifs légitimes, s'opposer à ce que leurs données personnelles communiquées dans le cadre de ce Jeu fassent l'objet d'un traitement, dans ce cas, ils ne pourront continuer à participer au Jeu.</p>
        <p>Concernant leurs Données personnelles, les participants peuvent consulter la Politique de protection des données, retrouver les informations relatives aux traitements mis en œuvre et aux modalités d’exercice de leurs droits sur le site internet <a href="https://www.groupama.fr" target="_blank">www.groupama.fr</a>, rubrique « Données personnelles ».</p>
        <p>La Société organisatrice s’engage à l’égard des personnes concernées à prendre toutes précautions utiles afin de préserver la sécurité des informations et notamment d’empêcher qu’elles soient communiquées à des tiers non autorisés. <br>
        Les joueurs ont été avisés de ces droits lors de leur participation.</p>
      </div>
      <div id="article8">
        <h3>Article 8 : Responsabilité</h3>
        <p>Le participant reconnaît et accepte que la seule obligation de la Société organisatrice au titre du Jeu est de soumettre au tirage au sort les participants, sous réserve que sa participation soit conforme aux termes et conditions du présent Règlement, et de remettre les lots aux gagnants, selon les critères et modalités définis dans le présent Règlement.
        <br>La Société organisatrice ne saurait encourir une quelconque responsabilité, si en cas de force majeure ou d’événements indépendants de leur volonté ou dans les cas prévus par le présent Règlement, elle était amenée à annuler, écourter, prolonger, reporter le présent Jeu ou à en modifier les conditions.
        <br>La Société organisatrice ne saurait non plus être tenue pour responsable en cas de non-délivrance du courriel ou du SMS annonçant le gain par suite d'une erreur dans l'adresse e-mail ou le numéro de téléphone renseignés lors de l’inscription, en cas de défaillance du fournisseur d'accès, en cas de défaillance du réseau Internet ou pour tout autre cas.
        <br>Par ailleurs, il est expressément rappelé qu’Internet n’étant pas un réseau sécurisé, la Société organisatrice ne saurait être tenue pour responsable de la contamination par un éventuel virus ou de l’intrusion d’un tiers dans le système du terminal des joueurs et déclinent toute responsabilité quant aux conséquences de la connexion des participants sur la plateforme permettant la participation au jeu.
        <br>La Société organisatrice ne saurait non plus être tenue pour responsable en cas de dysfonctionnement du réseau Internet qui empêcherait le bon déroulement du jeu et l’information des joueurs.
        <br>La Société organisatrice ne saurait enfin être tenue pour responsable d’un quelconque dommage causé aux joueurs, à leurs équipements informatiques et aux données qui y sont stockées, ainsi que de toutes conséquences pouvant en découler, notamment sur leur activité personnelle ou professionnelle.
        </p>
      </div>
      <div id="article9">
        <h3>Article 9 : Convention de preuve</h3>
          <p>La société organisatrice a mis en place les moyens techniques nécessaires pouvant démontrer la participation ou non-participation d’un participant. Il est donc convenu que, sauf erreur manifeste, les données contenues dans les systèmes d’information de la société organisatrice ont force probante quant aux éléments de connexion et aux informations d’un traitement informatique relatif au jeu.</p>
          <p>Ainsi, sauf en cas d’erreur manifeste, la société organisatrice pourra se prévaloir, notamment aux fins de preuve de tout acte, fait, omission, des programmes, données, fichiers, enregistrements, opérations et autres éléments de nature ou sous format ou support informatique ou électronique, établis, reçus ou conservés directement ou indirectement par la société organisatrice, notamment dans ses systèmes d’information.</p>
          <p>Les participants s’engagent à ne pas contester la recevabilité, la validité ou la force probante des éléments de nature ou sous format ou support informatique ou électronique précités, sur le fondement de quelque disposition légale que ce soit et qui spécifierait que certains documents doivent être écrits ou signés par les parties pour constituer une preuve. Ainsi, les éléments considérés constituent des preuves et, s’ils sont produits comme moyens de preuve par la société organisatrice dans toute procédure contentieuse ou autre, ils seront recevables, valables et opposables entre les Parties de la même manière, dans les mêmes conditions et avec la même force probante que tout document qui serait établi, reçu ou conservé par écrit.</p>
      </div>
      <div id="article10">
        <h3>Article 10 : Litiges</h3>
        <p>Pour être prises en compte, les éventuelles contestations relatives au Jeu doivent être formulées par écrit à l’adresse de la Société organisatrice au plus tard trente (30) jours après la date limite de participation au Jeu telle qu’indiquée à l’article 1 au présent règlement. Passé ce délai, les réclamations seront irrecevables.</p>
        <p>En cas de désaccord persistant sur l'application ou l'interprétation du présent règlement, et à défaut d'accord amiable, tout litige sera soumis au tribunal compétent.
        <br>Il ne sera répondu à aucune demande ou réclamation formulée par téléphone concernant l´application ou l´interprétation du présent Règlement.
        </p>
      </div>
      <div id="article11">
        <h3>Article 11 : Modifications éventuelles</h3>
        <p>Groupama Loire Bretagne se réserve le droit d’écourter, de modifier, de proroger ou d’annuler ce jeu, si des circonstances extérieures à sa volonté l’exigent. Sa responsabilité ne saurait être engagée de ce fait.</p>
      </div>
      <div id="article12">
        <h3>Article 12 : Acceptation et délivrance du règlement</h3>
          <p>La participation à ce jeu implique l’acceptation entière et sans réserves du présent règlement.
          <br>Ce Règlement sera consultable gratuitement sur la plateforme Jeux concours pendant toute la période de validité de celui-ci.  Il peut être modifié à tout moment par la Société organisatrice. Chaque modification fera l’objet d’une actualisation du règlement.
          </p>
          <p>Le présent règlement est déposé chez Maître Le Bourhis Huissier de justice – 2, avenue Charles Tillon 35105 RENNES.
          <br>Il sera adressé à titre gratuit à toute personne qui en fera la demande par courrier à Groupama Loire Bretagne Communication clients – 23, bd Solférino CS 51209 35012 RENNES Cedex. Timbre remboursé sur demande au tarif lent en vigueur à raison d’un timbre par enveloppe de moins de 20g, un remboursement par foyer même nom, même adresse.</p>
          <p>Le résultat du tirage au sort pourra être obtenu dans le délai d’un mois à compter de la date fixée à l’article 6, sur simple demande écrite (accompagnée d’une enveloppe timbrée et pré-adressée) à Groupama Loire Bretagne -23 boulevard Solférino - CS 51209 - 35012 Rennes.</p>
      </div>
      <div id="signature">Fait à Rennes, le {{DateFormat()}}</div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import store from "../store";

export default {
  computed: {
    ...mapGetters(["currentJeu"]),
  },
  methods: {
    ...mapActions(["setCurrentJeu"]),
    DateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  mounted() {
    return Promise.resolve()
      .then(() =>
        store.dispatch("setCurrentJeu", {
          survey: store.state.survey,
          id: this.$route.params.param,
        })
      )
      .then(() => store.dispatch("setAppFooter", true));
  },
};
</script>

<style>
</style>
