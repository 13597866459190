<template>
  <div :class="`${question.questionClass || 'col'} field`">
    <button
      class="btn btn-primary btn-lg"
      @click="addLot({ sectionName, typeLot })"
      target="_blank"
    >
      {{ question.buttonLabel }}
    </button>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: ["question", "path", "responsePath"],
  computed: {
    sectionName() {
      return this.$route.meta.section;
    },
    typeLot() {
      return this.question.lotType;
    },
  },
  methods: {
    ...mapActions({
      addLot: "addLotJeuState",
    }),
  },
};
</script>
