<template>
  <main>
    <div v-if="$route.params.id > 0">
      <h2>Modification du Quiz {{ $route.params.id }}</h2>
      <form-quizz></form-quizz>
    </div>
    <div v-else>Aucun id quiz n'a été donné</div>
  </main>
</template>

<script>
import FormQuizz from "./FormQuizz.vue";
export default {
  components: { FormQuizz },
};
</script>

<style>
</style>
