import { find, includes, split, upperCase, replace, filter, pickBy, keys } from "lodash";
import isEmail from "validator/lib/isEmail";
import moment from "moment";
import { getProp } from "../utils/SurveyUtils";

// Return boolean, true if the rule is completed
// tous les comparateurs que l'on peut utiliser dans les visibilityRules
export const validate = {
  minQuestions: (value, parameters, state) => {
    let hasError = filter(parameters.champs, p => {
      return getProp(state.survey, `${p}.response`);
    });
    if (parameters.minResponse) return hasError.length >= parameters.minResponse;
    if (parameters.minResponseEquals) return hasError.length === parameters.minResponseEquals;
  },
  requiredIf: (value, parameters, state) => {
    let otherVal = getProp(state?.survey, `${parameters.champs}`);
    return otherVal ? validate.required(value) : true;
  },
  required: value =>
    value !== undefined &&
    value !== null &&
    ((replace(value, / /g, "") !== "" && !Number.isNaN(value)) ||
      replace(value, / /g, "").length > 0),
  requiredDate: value => {
    return (
      value !== "Invalid date" &&
      value !== undefined &&
      value !== null &&
      typeof value !== "object" &&
      ((replace(value, / /g, "") !== "" && !Number.isNaN(value)) ||
        replace(value, / /g, "").length > 0)
    );
  },
  empty: value => !validate.required(value),
  email: value => validate.empty(value) || isEmail(value),
  alpha: value => /^[A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ -]+$/i.test(value),
  alphaName: value => /^[A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ - -'/]+$/i.test(value),
  date: value => moment().format("YYYYMMDD-HHmmss"),
  numeric: value => /^[0-9 ]+$/i.test(value),
  special_chars_alpha: value => /^[A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ -/]+$/i.test(value),
  regexp: (value, parameters) => new RegExp(parameters.model).test(value) || validate.empty(value),
  maxLength: (value, parameters) => value.length <= parameters.length,
  minLength: (value, parameters) => value.length >= parameters.length,
  length: (value, parameters) => {
    if (value.length === 0) {
      return true;
    }
    return Array.isArray(parameters.length)
      ? find(parameters.length, params => {
          return value.length === params;
        })
      : value.length === parameters.length;
  },
  equals: (value, parameters) => value === parameters,
  equalsModel: (value, parameters) => value === parameters.model,
  notEquals: (value, parameters) => value !== parameters,
  lengthValueInferior: (value, parameters) => {
    if (value === undefined) {
      return true;
    } else {
      return value.length < parameters;
    }
  },
  contains: (value, parameters) => {
    if (Array.isArray(parameters)) {
      for (let i = 0; i < parameters.length; i++) {
        if (includes(value, parameters[i])) {
          return true;
        }
      }
      return false;
    }
    return includes(value, parameters);
  },
  containsIfExists: (value, parameters) => {
    if (value === undefined) {
      return true;
    } else {
      return validate.contains(value, parameters);
    }
  },
  notContains: (value, parameters) => {
    if (Array.isArray(parameters)) {
      for (let i = 0; i < parameters.length; i++) {
        if (includes(value, parameters[i])) {
          return false;
        }
      }
      return true;
    }
    return !includes(value, parameters);
  },
  greater: (value, parameters) => !validate.empty(value) && StringToInt(value) > parameters,
  greaterEquals: (value, parameters) => !validate.empty(value) && StringToInt(value) >= parameters,
  dateGreaterThen: (value, parameters, state) => {
    if (!value) return true;
    let otherDate = getProp(state?.survey, `${parameters.champs}.response`);
    if (parameters.diff) {
      return moment(otherDate).diff(moment(value), "years") >= parameters.diff;
    }
    return moment(value) > moment(otherDate);
  },
  inferiorEquals: (value, parameters) => !validate.empty(value) && StringToInt(value) <= parameters,

  greaterOrEmpty: (value, parameters) => {
    return validate.empty(value) || StringToInt(value) > parameters;
  },
  greaterEqualsOrEmpty: (value, parameters) =>
    validate.empty(value) || StringToInt(value) >= parameters,
  lower: (value, parameters) => {
    return !validate.empty(value) && StringToInt(value) < parameters;
  },
  lowerEquals: (value, parameters) => !validate.empty(value) && StringToInt(value) <= parameters,
  lowerOrEmpty: (value, parameters) => validate.empty(value) || StringToInt(value) < parameters,
  lowerEqualsOrEmpty: (value, parameters) =>
    validate.empty(value) || StringToInt(value) <= parameters,
  accept: value => {
    return !value
      ? true
      : find(
          value.extensions,
          ext => upperCase(split(value.file.name, ".")[1]) === upperCase(ext)
        ) !== undefined;
  },
  weight: value => {
    // Taille Max 10 Mb
    return value && value.file ? value.file.size < 1048576 * 10 : true;
  },
  maxAnswers: (value, parameters) => {
    const parametersLength = parameters.length;

    if (parameters.answer) {
      // count the number of response equal to parameters.answer

      const selected = filter(value, item => {
        const important = pickBy(item.questions, (value, key) => {
          return value.response && value.response[0] === parameters.answer;
        });

        return keys(important).length > 0;
      });

      return selected.length <= parametersLength;
    } else {
      // just count the number of responses
      return value && value.length <= parametersLength;
    }
  },
};

// cast un string en int si possible sinon renvoi le string
export function StringToInt(value) {
  return isNaN(value) ? (isNaN(replace(value, / /g, "")) ? 0 : replace(value, / /g, "")) : value;
}

export function getError(value, rules, state) {
  const ruleError = find(rules, (params, rule) => {
    if (!validate[rule]) {
      console.error("Unknow validation function : ", rule, params);
      return false; // Unkown function are not valid
    }
    return !validate[rule](value, params, state);
  });
  return ruleError ? ruleError.message || ruleError : null;
}

export function isRequired(question, state) {
  let rulesRequired = pickBy(
    question.rules,
    (r, key) => key === "requiredIf" || key === "required"
  );
  let c = getError(null, rulesRequired, state);
  return c != null;
}
