import { createApp } from "vue";
import DOMPurify from "dompurify";
import "es6-promise/auto";
import axios from "axios";
import "./assets/styles/app.scss";
import VueSelect from "vue-select";

import router from "./router";
import MainLayout from "./components/layouts/MainLayout";
import store from "./store";

// Import global componenents
import QuestionLayout from "./components/QuestionLayout";
import TooltipElement from "./components/fields/TooltipElement";

// import shareIt from "vue-share-it";

/* eslint-disable no-new */
const app = createApp({
  template: "<main-layout/>",
  components: {
    MainLayout,
  },

  watch: {
    "$store.state.isSurveyCompleted": function (isSurveyCompleted) {
      if (isSurveyCompleted === true) {
        router.push({ name: "Complet" });
      }
    },
  },
});

const axiosInstance = axios.create({
  withCredentials: true,
});
app.config.globalProperties.$axios = { ...axiosInstance };

app.use(router);
app.use(store);
// app.use(shareIt);

app.mount("#root");

app.component("question-layout", QuestionLayout);
app.component("tooltip-element", TooltipElement);
app.component("v-select", VueSelect);

app.directive("sane-html", (el, binding) => {
  el.innerHTML = DOMPurify.sanitize(binding.value);
});

if (__DEV__) {
  // Remove productionTip
  app.config.productionTip = false;
}

export default app;
