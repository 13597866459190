<template>
  <main>
    <div class="confirm-message">
      <p>Erreur interne du sever API</p>
      <p>{{ id }}</p>
      <p>{{ errorMsg }}</p>
      <router-link :to="{ name: 'BO.Login' }" class="btn btn-primary" v-if="[401,403].includes(parseInt(id))">Je me connecte</router-link>
    </div>
  </main>
</template>

<script>
export default {
  props: ["errorMsg", "id"],
};
</script>

<style></style>
