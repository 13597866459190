<template>
  <div :class="`${question.answerColClass || 'col'} field`">
    <VueDatePicker
      v-model="date"
      model-type="dd/MM/yyyy"
      format="dd/MM/yyyy"
      :enable-time-picker=false
      locale="fr-FR"
      auto-apply
      :text-input=true
      :placeholder="question.placeholder"
      :class="question.fieldClass"
      @update:model-value="e => setValueDate({ path, responsePath, value: e })"
      @date-update="e => checkError({ path, responsePath, value: e })"
    />
    <label v-if="question.labelInput">
      {{ question.labelInput }}<span v-if="required">*</span>
      <!-- Info bulle -->
      <!-- <tooltip-element :question="question" /> -->
    </label>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import { getProp } from "../../utils/SurveyUtils";
import { isRequired } from "../../utils/validate";

export default {
  props: ["question", "path", "responsePath"],
  data: function () {
    return {
      date: null,
    };
  },
  computed: {
    required() {
      return isRequired(this.question, this.$store.state);
    },
  },
  methods: {
    ...mapActions(["setValueDate", "checkError"]),
  },
  mounted() {
    let stringDate = getProp(this.question, this.responsePath, "response");
    if (stringDate !== null && stringDate !== undefined){
      this.date = moment(stringDate).format("DD/MM/YYYY");
    }
  },
  components: {
    VueDatePicker,
  },
};
</script>
