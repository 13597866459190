<template>
  <main>
    <h3>Une erreur est produit lors de la récuperation du quiz</h3>
    <h5>Quiz a été supprimé ou il n'existe pas dans la base des données</h5>
    <div>
      <!-- <button @click="$router.go(-2)" class="btn btn-primary">Précedent</button> -->
    </div>
  </main>
</template>

<script>
export default {};
</script>

<style>
</style>
