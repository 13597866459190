<template>
  <div :class="`${question.answerColClass || 'col'} field`">
    <router-link
      :class="question.type"
      :to="{
        name: 'Questionnaire',
        params: {
          section: question.sectionLink,
          declarationId: question.sectionParam,
        },
        query: {...$route.query, button: 'linkField'},
      }"
    >
      <span>{{ question.btnText }}</span>
      <i></i>
    </router-link>
  </div>
</template>
<script>
import { getProp } from "../../utils/SurveyUtils";

export default {
  props: ["question", "path", "responsePath"],
  computed: {
    type(state) {
      return getProp(this.question, "type");
    },
  },
};
</script>
