<template>
  <div class="col-0 field">
    <a :class="[question.class, !divDisplay && 'dropdown-close']" @click="deplierDiv"
      ><i class="far fa-angle-up"></i>{{ question.buttonLabel }}</a
    >
  </div>
</template>

<script>
export default {
  props: ["question", "path", "responsePath"],
  data() {
    return {
      divDisplay: true,
    };
  },
  methods: {
    deplierDiv() {
      let div = document.getElementsByClassName(this.question.dropDivClass);
      div[0].style.display = this.divDisplay ? "none" : "block";
      this.divDisplay = !this.divDisplay;
    },
  },
};
</script>

<style>
</style>
