import axios from "axios";
import { has, map, get, set, dropRight, concat, forEach, omitBy, take } from "lodash";
import { set as setFP } from "lodash/fp";
import moment from "moment";
import { uuid } from "vue-uuid";
import "moment/locale/fr";

import {
  SET_SURVEY,
  CHECK_ERROR_FP,
  SET_VALUE_GLOBAL,
  TOGGLE_BT_NEXT,
  SET_VALIDATE_SECTION,
  GET_POSTAL_CODE,
  CHECK_ERROR,
  SET_VALUE,
  SET_VALUE_DATE,
  RESET_QUESTION,
  VALIDATE_SECTION,
  FETCH_SURVEY,
  SET_ID,
  RESET_VALUE,
  ADD_PREVIOUS_SECTION,
  POP_PREVIOUS_SECTION,
  CHECK_COMPLETED,
  ADD_LOT_JEU,
  REMOVE_LOT_JEU,
  SET_JEUX_UTILISATEUR,
  RESET_LOTS_JEU,
  SET_CURRENT_JEU,
  RESET_LISTE_JEUX,
  SET_APP_FOOTER,
  SET_UTILISATEUR,
  SET_LISTE_JEUX,
  ADD_REPONSE_QUIZZ,
  RESET_REPONSES_QUIZZ,
  REMOVE_REPONSE_QUIZZ,
  SET_LISTE_QUIZZS,
  SET_REPONSES_QUIZZ,
  SET_LOTS_JEU,
  REMOVE_JEU_FROM_LISTE,
  ADD_JEU_TO_LISTE,
  SET_LOADING_JEUX,
  SET_DEJA_JOUER,
  SET_REPONDANT,
  SET_REFERRALS,
  SET_REFERRAL,
  RESET_REFERRALS,
  REMOVE_REFERRAL,
  CHECK_FILE_ERROR,
  SET_USER_LOGGING,
  SET_LOADING,
  SET_LISTE_IMAGES,
  REMOVE_IMAGE_FROM_LISTE,
} from "./actions";
import { getError } from "../utils/validate";
import {
  getPath,
  getProp,
  execQuestionDeep,
  execSectionDeep,
  execSurveyDeep,
  isRulesVisible,
  isRulesDisabled,
} from "../utils/SurveyUtils";
import { getResponseFromServer } from "../utils/ServerParser";

// tous les changements du store doivent être fait ici
axios.defaults.withCredentials = true;
const mutations = {
  [SET_REPONDANT]: (state, data) => {
    let survey = state.survey;
    survey.sections.globals.Repondant = data;
    state.survey = survey;
  },
  [SET_REFERRALS]: (state, data) => {
    let survey = state.survey;
    survey.sections.globals.Referrals = data;
    state.survey = survey;
  },
  [SET_REFERRAL]: (state) => {
    let survey = state.survey;
    let nbReferrals = survey.sections.globals.nombreReferrals;
    let jeu = survey.sections.globals.currentJeu;
    if (nbReferrals < 5 && jeu.Type === "reco") {
      let referraluuid = uuid.v1();
      let referral = {
        "label": "Votre Filleul",
        "questionClass": "col-12 form-referral",
        "questions": {
          "nom": {
            "serverPath": "RepondantReferral." + referraluuid + ".Nom",
            "questionType": "input",
            "labelInput": "Nom",
            "questionClass": "col-12 col-md-4",
            "rules": {
              "required": "Merci de renseigner son nom.",
              "alphaName": "Merci de vérifier votre saisie."
            }
          },
          "prenom": {
            "serverPath": "RepondantReferral." + referraluuid + ".Prenom",
            "labelInput": "Prénom",
            "questionType": "input",
            "questionClass": "col-12 col-md-4",
            "rules": {
              "required": "Merci de renseigner son prénom.",
              "alphaName": "Merci de vérifier votre saisie."
            }
          },
          "ville": {
            "serverPath": "RepondantReferral." + referraluuid + ".CPVille",
            "labelInput": "CP - Ville",
            "optionsURL": "api/codespostal",
            "questionType": "select",
            "questionClass": "col-12 col-md-4",
            "rules": {
              "required": "Merci de renseigner son code postal-ville."
            }
          },
          "email": {
            "serverPath": "RepondantReferral." + referraluuid + ".Email",
            "labelInput": "Email",
            "questionType": "input",
            "questionClass": "col-12 col-md-4",
            "rules": {
              "email": "Merci de vérifier votre saisie."
            }
          },
          "telephone": {
            "serverPath": "RepondantReferral." + referraluuid + ".Telephone",
            "labelInput": "Téléphone",
            "questionClass": "col-12 col-md-4",
            "questionType": "cleave",
            "cleaveOptions": {
              "blocks": [
                2,
                2,
                2,
                2,
                2
              ]
            },
            "rules": {
              "required": "Merci de renseigner son numéro de téléphone.",
              "regexp": {
                "model": "^[0-9]{10}$",
                "message": "Merci de vérifier votre saisie."
              }
            }
          },
          "profession": {
            "serverPath": "RepondantReferral." + referraluuid + ".Profession",
            "labelInput": "Profession",
            "questionType": "input",
            "questionClass": "col-12 col-md-4",
            "rules": {
              "alphaName": "Merci de vérifier votre saisie."
            }
          },
          "removeReferral": {
            "id": referraluuid,
            "questionType": "removeReferral",
            "buttonLabel": "Annuler l'ajout",
            "visibilityRules": {
              "sections.globals.nombreReferrals": {
                "greater": 1
              }
            }
          }
        }
      };

      survey = setFP(`sections.jeu_concours_form.questions.questionnaire.questions.referrals.questions.${referraluuid}`, referral, survey);
      survey = setFP("sections.globals.nombreReferrals", nbReferrals + 1, survey);
      if (nbReferrals >= 1) {
        let i = 1;
        forEach(survey.sections.jeu_concours_form.questions.questionnaire.questions.referrals.questions, (question, key) => {
          survey = setFP(`sections.jeu_concours_form.questions.questionnaire.questions.referrals.questions.${key}.label`, "Votre filleul n° " + i, survey);
          i++;
        });
      };
      state.survey = survey;
    }
  },
  [RESET_REFERRALS]: (state) => {
    let survey = state.survey;
    survey = setFP("sections.jeu_concours_form.questions.questionnaire.questions.referrals.questions", {}, survey);
    survey = setFP("sections.globals.nombreReferrals", 0, survey);
    survey = setFP("sections.jeu_concours_form.isValidated", false, survey);
    state.survey = survey;
  },
  [REMOVE_REFERRAL]: (state, data) => {
    let survey = state.survey;
    let s = omitBy(survey.sections.jeu_concours_form.questions.questionnaire.questions.referrals.questions, (o, key) => key === data.id);
    survey = setFP("sections.jeu_concours_form.questions.questionnaire.questions.referrals.questions", s, survey);
    survey = setFP("sections.globals.nombreReferrals", survey.sections.globals.nombreReferrals - 1, survey);
    let i = 1;
    let nbReferrals = survey.sections.globals.nombreReferrals;
    forEach(survey.sections.jeu_concours_form.questions.questionnaire.questions.referrals.questions, (question, key) => {
      if (nbReferrals > 1) {
        survey = setFP(`sections.jeu_concours_form.questions.questionnaire.questions.referrals.questions.${key}.label`, "Votre filleul n° " + i, survey);
      } else {
        survey = setFP(`sections.jeu_concours_form.questions.questionnaire.questions.referrals.questions.${key}.label`, "Votre filleul", survey);
      }
      i++;
    });
    state.survey = survey;
  },
  [SET_LOADING_JEUX]: (state, status) => {
    let survey = state.survey;
    survey.sections.globals.loadingJeux = status;
    state.survey = survey;
  },
  [SET_APP_FOOTER]: (state, data) => {
    state.showAppFooter = data;
  },
  [CHECK_ERROR]: (state, { path, responsePath, value }) => {
    const question = get(state.survey, path, {});
    // Use set (not setFP) to not re-create the question with the new error props (don't display the new error on user input)
    set(state.survey, getPath(path, responsePath, "error"), getError(value, question.rules, state));
  },
  [CHECK_ERROR_FP]: (state, { path, responsePath, value }) => {
    const question = get(state.survey, path, {});
    let survey = state.survey;
    survey = setFP(
      getPath(path, responsePath, "error"),
      getError(value, question.rules),
      state.survey
    );
    // if (getError(value, question.rules)) {
    //   survey = setFP("sections.risques.isValidated", false, survey);
    // }
    state.survey = survey;
  },
  [ADD_PREVIOUS_SECTION]: (state, value) => {
    let survey = state.survey;
    let previousSections = get(survey, "sections.globals.previousSection");
    survey = setFP(
      "sections.globals.previousSection",
      previousSections !== null && Array.isArray(previousSections)
        ? concat(previousSections, value)
        : [value],
      survey
    );
    state.survey = survey;
  },
  [POP_PREVIOUS_SECTION]: state => {
    let survey = state.survey;
    let previousSections = get(survey, "sections.globals.previousSection");
    survey = setFP(
      "sections.globals.previousSection",
      previousSections !== null && Array.isArray(previousSections)
        ? dropRight(previousSections)
        : [],
      survey
    );
    state.survey = survey;
  },
  [SET_VALUE_DATE]: (state, { path, responsePath, value }) => {
    let survey = state.survey;
    const question = get(state.survey, path, {});
    let valueDate = value ? moment(value, "DD/MM/YYYY").format("YYYY-MM-DD") : null;
    survey = setFP(getPath(path, responsePath, "response"), valueDate, survey);
    survey = setFP(getPath(path, responsePath, "responseFull"), value, survey);
    survey = setFP(
      getPath(path, responsePath, "error"),
      getError(valueDate, question.rules, state),
      survey
    );
    state.survey = survey;
  },
  [RESET_VALUE]: (state, { path, value }) => {
    let survey = state.survey;
    survey = setFP(getPath(path), value, survey);
    state.survey = survey;
  },
  [SET_VALUE]: (state, { path, responsePath, value }) => {
    let survey = state.survey;
    const question = get(state.survey, path, {});
    survey = setFP(getPath(path, responsePath, "response"), value, survey);
    survey = setFP(
      getPath(path, responsePath, "error"),
      getError(value, question.rules, state),
      survey
    );
    const sectionPath = path.split(".");
    // on clear isBtNextClicked
    survey = setFP(
      getPath(`${sectionPath[0]}.${sectionPath[1]}`, "isBtNextClicked"),
      false,
      survey
    );
    state.survey = survey;
  },
  [SET_VALUE_GLOBAL]: (state, { path, responsePath, value }) => {
    let survey = state.survey;
    survey = setFP(getPath(path, responsePath), value, survey);
    state.survey = survey;
  },
  [TOGGLE_BT_NEXT]: (state, { sectionPath }) => {
    let survey = state.survey;
    survey = setFP(
      getPath(`sections.${sectionPath}`, "isBtNextClicked"),
      !get(state.survey, getPath(`sections.${sectionPath}`, "isBtNextClicked")),
      survey
    );
    state.survey = survey;
  },
  [SET_VALIDATE_SECTION]: (state, { path, responsePath, value }) => {
    let survey = state.survey;
    survey = set(survey, getPath(path, "isValidated"), value);
    state.survey = survey;
  },
  [RESET_QUESTION]: (state, { path, responsePath }) => {
    let survey = state.survey;
    const question = get(state.survey, path, {});
    execQuestionDeep(question, path, responsePath, (q, p, rp) => {
      survey = setFP(getPath(p, rp, "response"), null, survey);
      // survey = setFP(getPath(p, rp, "error"), getError(null, q.rules, state), survey);
    });
    state.survey = survey;
  },
  [VALIDATE_SECTION]: (state, sectionName) => {
    let survey = state.survey;
    let sectionHasError = false;
    execSectionDeep(get(state, `survey.sections.${sectionName}`), sectionName, (q, p, rp) => {
      const isVisible = isRulesVisible(q.visibilityRules, state);
      if (isVisible) {
        if (has(q.rules, "minQuestions")) {
          set(state.survey, getPath(p, rp, "error"), getError(null, q.rules, state));
        }
        const error = getError(getProp(q, rp, "response"), q.rules, state);
        sectionHasError = sectionHasError || error;
        survey = setFP(getPath(p, rp, "error"), error, survey);

        const isDisabled = isRulesDisabled(q.disabledRules, state);
        if (isDisabled) {
          // le champ est obligatoirement désactivé, par contre il faut le déclarer comme tel dans le survey
          survey = setFP(getPath(p, rp, "response"), false, survey);
          state.survey = survey;
        }

        return true;
      }
      return false;
    });
    survey = setFP(`sections.${sectionName}.isValidated`, !sectionHasError, survey);
    state.survey = survey;
  },
  [CHECK_COMPLETED]: state => {
    let allSectionsValidated = true;
    let survey = state.survey;
    forEach(survey.sections, (s, sn) => {
      if (sn !== "globals" && !s.isValidated) {
        allSectionsValidated = false;
      }
    });
    state.survey = survey;
    // désactiver en dev pour éviter la redirection vers page dejarepondu
    if (!__DEV__) {
      state.isSurveyCompleted = allSectionsValidated;
    }
  },
  [FETCH_SURVEY]: (state, data) => {
    let allSectionsValidated = true;
    let survey = state.survey;
    if (data) {
      let allSectionsValidated = true;
      let survey = state.survey;
      execSurveyDeep(
        survey,
        (s, sn) => {
          if (sn === "globals" || get(data, s.serverPathValidated) === true) {
            survey = setFP(`sections.${sn}.isValidated`, true, survey);
          } else {
            allSectionsValidated = false;
          }
        },
        (q, p, rp) => {
          const serverPath = getProp(q, "serverPath", rp);
          if (serverPath) {
            survey = setFP(
              getPath(p, rp, "response"),
              getResponseFromServer[q.questionType](data, serverPath, q.resetServerPath),
              survey
            );
            survey = setFP(getPath(p, rp, "error"), null, survey);
          }
        }
      );
      state.survey = survey;
      if (allSectionsValidated) {
        state.isSurveyCompleted = true;
      }
    } else {
      state.survey = survey;
      // désactiver en dev pour éviter la redirection vers page dejarepondu
      if (!__DEV__) {
        state.isSurveyCompleted = allSectionsValidated;
      }
    }
  },
  [SET_LOTS_JEU]: (state, data) => {
    let lots = data.lots;
    let typeLots = data.typeLots;
    let refSurveyLot = typeLots === "tirage" ? "lots_jeu" : "lots_a_gagnes";
    let survey = state.survey;
    survey = setFP(
      `sections.bo_modifier_jeu.questions.jeu.questions.jeu_infos.questions.${refSurveyLot}.questions.lots.questions`,
      {},
      survey
    );

    map(lots, lot => {
      let idLot = uuid.v1();
      let refLot = typeLots === "tirage" ? "Jeu.Lots." + idLot : "Jeu.LotsGagnants." + idLot;

      let lotSurvey = {
        label: "Lot",
        questionClass: "col-12 lot",
        questions: {
          btn_plier: {
            questionClass: "col-0",
            questionType: "dropdowndiv",
            dropDivClass: idLot + "-infos",
          },
          lot_infos: {
            questionClass: `col-12 ${idLot}-infos`,
            questions: {
              id_lot: {
                serverPath: refLot + ".IdLot",
                questionType: "input",
                visibilityRules: false,
                hidden: true,
                response: lot.IdLot,
              },
              ordre_lot: {
                serverPath: refLot + ".Ordre",
                label: "Ordre",
                questionType: "input",
                questionClass: "col-12 col-md-2",
                response: lot.Ordre,
                rules: {
                  required: "Merci de renseigner l'ordre du lot",
                  regexp: {
                    model: "^([1-9][0-9]{0,3}|10000)$",
                    message: "Merci de vérifier votre saisie.",
                  },
                },
              },
              nom_lot: {
                serverPath: refLot + ".Nom",
                label: "Nom",
                questionType: "input",
                questionClass: "col-12 col-md-8",
                placeholder: "Nom du lot",
                response: lot.Nom,
                rules: {
                  required: "Merci de renseigner le nom du lot",
                },
              },
              nombre_lot: {
                serverPath: refLot + ".Nombre",
                label: "Nombre",
                questionType: "input",
                questionClass: "col-12 col-md-2",
                response: lot.Nombre,
                rules: {
                  required: "Merci de renseigner le nombre de lot",
                  regexp: {
                    model: "^([1-9][0-9]{0,3}|10000)$",
                    message: "Merci de vérifier votre saisie.",
                  },
                },
              },
              visuel_lot: {
                serverPath: refLot + ".Visuel",
                questionType: "cropimage",
                accept: "jpg,png,jpeg",
                label: "Visuel",
                loadBtnLabel: "Déposer une image",
                uploadBtnLabel: "Sélectionner un visuel",
                questionClass: "col-12 col-md-12",
                response: lot.Visuel,
                hasSelectImage: true,
                selectImageType: "Lot",
                hasCropper: true,
                crop: {
                  fixedSize: true,
                  width: 250,
                  height: 250,
                  ration: 1,
                  movable: true,
                  resizable: false,
                },
                rules: {
                  required: "Merci de sélectionner un visuel",
                },
              },
              remove_lot: {
                id: idLot,
                questionClass: "col-12",
                questionType: "removeLot",
                lotType: typeLots,
                buttonLabel: "Supprimer",
              },
            },
          },
        },
      };

      survey = setFP(
        `sections.bo_modifier_jeu.questions.jeu.questions.jeu_infos.questions.${refSurveyLot}.questions.lots.questions.${idLot}`,
        lotSurvey,
        survey
      );
    });
    let i = 1;
    forEach(
      survey.sections.bo_modifier_jeu.questions.jeu.questions.jeu_infos.questions[refSurveyLot]
        .questions.lots.questions,
      (question, key) => {
        survey = setFP(
          `sections.bo_modifier_jeu.questions.jeu.questions.jeu_infos.questions.${refSurveyLot}.questions.lots.questions.${key}.label`,
          "Lot " + i,
          survey
        );
        i++;
      }
    );
    state.survey = survey;
  },
  [GET_POSTAL_CODE]: (state, codeClient) => {
    let survey = state.survey;
    state.surveyWithClientCode = true;
    // let currentSession = sessionStorage.getItem('enquete-client');
    // appler axios get, pour aller chercher les postal code
    axios
      .get(`${process.env.VUE_APP_API_URL}/api/codespostal/`)
      .then(({ data }) => {
        survey.sections.globals.cpVilles = data;
        state.survey = survey;
        // puis les stocker dans le state
      })
      .catch(e => console.log(e));
  },
  [SET_SURVEY]: (state, survey) => {
    state.survey = survey;
  },
  [SET_ID]: (state, data) => {
    state.survey = setFP("sections.globals.questions.exportId.response", data.id, state.survey);
  },
  [ADD_LOT_JEU]: (state, data) => {
    let section = data.sectionName;
    let typeLot = data.typeLot;
    let refSection = data.sectionName === "bo_nouveau_jeu" ? "NouveauJeu" : "Jeu";
    let survey = state.survey;
    let idLot = uuid.v1();
    let refLot = refSection + (typeLot === "tirage" ? ".Lots." : ".LotsGagnants.") + idLot;
    let lot = {
      label: "Lot",
      questionClass: "col-12 lot",
      questions: {
        btn_plier: {
          questionClass: "col-0",
          questionType: "dropdowndiv",
          dropDivClass: idLot + "-infos",
        },
        lot_infos: {
          questionClass: `col-12 ${idLot}-infos`,
          questions: {
            ordre_lot: {
              // serverPath: refSection + ".Lots." + idLot + ".Ordre",
              serverPath: refLot + ".Ordre",
              label: "Ordre",
              questionType: "input",
              questionClass: "col-12 col-md-2",
              rules: {
                required: "Merci de renseigner l'ordre du lot",
                regexp: {
                  model: "^([1-9])+$",
                  message: "Merci de vérifier votre saisie.",
                },
              },
            },
            nom_lot: {
              // serverPath: refSection + ".Lots." + idLot + ".Nom",
              serverPath: refLot + ".Nom",
              label: "Nom",
              questionType: "input",
              questionClass: "col-12 col-md-8",
              placeholder: "Nom du lot",
              rules: {
                required: "Merci de renseigner le nom du lot",
              },
            },
            nombre_lot: {
              // serverPath: refSection + ".Lots." + idLot + ".Nombre",
              serverPath: refLot + ".Nombre",
              label: "Nombre",
              questionType: "input",
              questionClass: "col-12 col-md-2",
              rules: {
                required: "Merci de renseigner le nombre de lot",
                regexp: {
                  model: "^([1-9])+$",
                  message: "Merci de vérifier votre saisie.",
                },
              },
            },
            visuel_lot: {
              serverPath: refLot + ".Visuel",
              questionType: "cropimage",
              accept: "jpg,png,jpeg",
              label: "Visuel",
              uploadBtnLabel: "Sélectionner un visuel",
              loadBtnLabel: "Déposer une image",
              questionClass: "col-12 col-md-12",
              hasSelectImage: true,
              selectImageType: "Lot",
              hasCropper: true,
              crop: {
                fixedSize: true,
                width: 250,
                height: 250,
                ration: 1,
                movable: true,
                resizable: false,
              },
              rules: {
                required: "Merci de sélectionner un visuel",
              },
            },
            remove_lot: {
              id: idLot,
              questionClass: "col-12",
              questionType: "removeLot",
              lotType: typeLot,
              buttonLabel: "Supprimer",
            },
          },
        },
      },
    };
    let refSurveyLot = typeLot === "tirage" ? "lots_jeu" : "lots_a_gagnes";
    survey = setFP(
      `sections.${section}.questions.jeu.questions.jeu_infos.questions.${refSurveyLot}.questions.lots.questions.${idLot}`,
      lot,
      survey
    );
    let i = 1;
    forEach(
      survey.sections[section].questions.jeu.questions.jeu_infos.questions[refSurveyLot].questions
        .lots.questions,
      (question, key) => {
        survey = setFP(
          `sections.${section}.questions.jeu.questions.jeu_infos.questions.${refSurveyLot}.questions.lots.questions.${key}.label`,
          "Lot " + i,
          survey
        );
        i++;
      }
    );
    state.survey = survey;
  },
  [REMOVE_LOT_JEU]: (state, data) => {
    let survey = state.survey;
    let section = data.sectionName;
    let typeLot = data.typeLot;
    let idLot = data.id;
    let refSurveyLot = typeLot === "tirage" ? "lots_jeu" : "lots_a_gagnes";
    let idLotDB =
      survey.sections[section].questions.jeu.questions.jeu_infos.questions[refSurveyLot].questions
        .lots.questions[idLot].questions.lot_infos.questions.id_lot?.response;
    let visuelLotDB =
      survey.sections[section].questions.jeu.questions.jeu_infos.questions[refSurveyLot].questions
        .lots.questions[idLot].questions.lot_infos.questions.visuel_lot?.response;
    if (visuelLotDB) {
      axios.delete(`${process.env.VUE_APP_API_URL}/api/upload/${visuelLotDB}`);
    }
    if (idLotDB) {
      axios.delete(`${process.env.VUE_APP_API_URL}/api/lots/${idLotDB}`);
    }
    let lots = omitBy(
      survey.sections[section].questions.jeu.questions.jeu_infos.questions[refSurveyLot].questions
        .lots.questions,
      (q, key) => key === idLot
    );

    survey = setFP(
      `sections.${section}.questions.jeu.questions.jeu_infos.questions.${refSurveyLot}.questions.lots.questions`,
      lots,
      survey
    );
    let i = 1;
    forEach(
      survey.sections[section].questions.jeu.questions.jeu_infos.questions[refSurveyLot].questions
        .lots.questions,
      (question, key) => {
        survey = setFP(
          `sections.${section}.questions.jeu.questions.jeu_infos.questions.${refSurveyLot}.questions.lots.questions.${key}.label`,
          "Lot " + i,
          survey
        );
        i++;
      }
    );
    state.survey = survey;
  },
  [SET_JEUX_UTILISATEUR]: (state, idUtilisateur) => {
    let survey = state.survey;
    axios
      .get(`${process.env.VUE_APP_API_URL}/api/jeux/jeuxutilisateur/${idUtilisateur}`)
      .then(({ data }) => {
        survey.sections.globals.ListeJeux = data.data.jeuxUtilisateur;
        state.survey = survey;
      })
      .catch(e => console.log(e));
  },
  [SET_LISTE_QUIZZS]: (state, data) => {
    let survey = state.survey;
    survey.sections.globals.ListeQuizzs = data;
    state.survey = survey;
  },
  [SET_UTILISATEUR]: (state, user) => {
    let survey = state.survey;
    survey.sections.globals.Utilisateur = user;
    state.survey = survey;
  },
  [SET_LISTE_JEUX]: (state, listeJeux) => {
    let survey = state.survey;
    survey.sections.globals.ListeJeux = listeJeux;
    state.survey = survey;
  },
  // [SET_UTILISATEUR_AND_JEUX]: (state, email) => {
  //   console.log("User Mail ==>", email);
  //   let survey = state.survey;
  //   return axios
  //     .post(`${process.env.VUE_APP_API_URL}/api/utilisateurs/email/`, {
  //       email: email
  //     })
  //     .then(({ data }) => {
  //       let user = data.data.utilisateur;
  //       survey.sections.globals.Utilisateur = user;
  //       return user;
  //     })
  //     .then(user => {
  //       return axios
  //         .get(`${process.env.VUE_APP_API_URL}/api/jeux/jeuxutilisateur/${user.IdUtilisateur}`);
  //     })
  //     .then(({ data }) => {
  //       console.log(data);
  //       survey.sections.globals.ListeJeux = data.data.jeuxUtilisateur;
  //       state.survey = survey;
  //     })
  //     .catch(e => console.log(e));
  // },
  [RESET_LOTS_JEU]: (state, data) => {
    let survey = state.survey;
    let section = data.section;
    let typeLots = data.typeLots;
    let refSurveyLot = typeLots === "tirage" ? "lots_jeu" : "lots_a_gagnes";
    if (typeLots === "all") {
      survey = setFP(
        `sections.${section}.questions.jeu.questions.jeu_infos.questions.lots_jeu.questions.lots.questions`,
        {},
        survey
      );
      survey = setFP(
        `sections.${section}.questions.jeu.questions.jeu_infos.questions.lots_a_gagnes.questions.lots.questions`,
        {},
        survey
      );
    } else {
      survey = setFP(
        `sections.${section}.questions.jeu.questions.jeu_infos.questions.${refSurveyLot}.questions.lots.questions`,
        {},
        survey
      );
    }
    state.survey = survey;
  },
  [SET_CURRENT_JEU]: (state, data) => {
    let survey = state.survey;
    let jeu = data.jeu;

    survey.sections.globals.currentJeu = jeu;
    // Set jeu_accueil
    let periodeJeu = `du ${moment(jeu.DateDebut).format("D")} ${moment(jeu.DateDebut).format(
      "MMMM",
      "fr"
    )} au ${moment(jeu.DateFin).format("D")} ${moment(jeu.DateFin).format("MMMM", "fr")} ${moment(
      jeu.DateFin
    ).format("YYYY")}`;
    let lots = jeu.Lots ? Object.values(jeu.Lots) : [];
    let jeuAccueil = {
      titre: {
        questionType: "text",
        questionClass: "jeux-entete jeux-titre",
        // ticket #5925
        // bloc: "Jouez et Gagnez avec Groupama Loire Bretagne",
      },
      visuel: {
        questionType: "image",
        response: jeu.Visuel,
        alt: "Visuel du Jeu",
      },
      nom: {
        questionType: "text",
        questionClass: "jeux-description jeux-nom",
        bloc: jeu.Nom,
      },
      periode: {
        questionType: "text",
        questionClass: "jeux-description jeux-period",
        bloc: periodeJeu,
      },
      description: {
        questionType: "text",
        questionClass: "jeux-description ql-editor",
        bloc: jeu.Description,
      },
      lots: {
        questionClass: `${lots.length > 2 && "nb-lots-3"}`,
        questions: {},
      },
    };
    if (lots.length > 0) {
      survey = setFP("sections.jeu_accueil.questions", {}, survey);
      let nbLots = jeu.Type === "reco" ? 6 : 3;
      map(take(lots, nbLots), (lot, index) => {
        let lotJeu = {
          questionClass: "lot-block",
          questions: {
            order: {
              questionType: "text",
              bloc: `${
                lots.length > 1
                  ? `${lot.Ordre}<sup>${lot.Ordre < 2 ? "er" : "e"}</sup>`
                  : "À gagner"
              }`,
              questionClass: "col-12",
              answerColClass: "col-0",
            },
            visuel: {
              questionType: "image",
              questionClass: "col-12",
              response: lot.Visuel,
              alt: "Visuel de lot",
            },
            description: {
              questionType: "text",
              bloc: `${lot.Nombre} ${lot.Nom}`,
            },
          },
        };
        jeuAccueil.lots.questions["lot" + index] = lotJeu;
      });
      if (lots.length > nbLots) {
        let lotJeuPlus = {
          questionType: "text",
          questionClass: "autres-lots",
          bloc: "Et bien d'autres lots...",
        };
        jeuAccueil["lotsPlus"] = lotJeuPlus;
      }
    }

    survey = setFP("sections.jeu_accueil.questions", jeuAccueil, survey);
    // tickt #5925
    // survey = setFP(
    //   "sections.jeu_concours_form.title",
    //   "Jouez et Gagnez avec Groupama Loire Bretagne",
    //   survey
    // );
    survey = setFP(
      // "sections.jeu_concours_form.questions.questionnaire.questions.lots",
      "sections.jeu_concours_form.questions.lots",
      jeuAccueil.lots,
      survey
    );
    if (jeu.Type === "quizz") {
      let questionsJeu = jeu.Quizzs.map(id =>
        survey.sections.globals.ListeQuizzs.find(q => q.IdQuizz === id)
      );

      const questions = {};

      forEach(questionsJeu, q => {
        const options = {};

        forEach(q.Reponses, (r, index) => {
          options[r.Reponse] = r.Reponse;
        });

        set(questions, `question_${q.IdQuizz}`, {
          questionClass: "col-12",
          questions: {
            idQuizz: {
              questionType: "input",
              visibilityRules: false,
              hidden: true,
              serverPath: `Repondant.Reponses.question_${q.IdQuizz}.IdQuizz`,
              response: q.IdQuizz,
            },
            reponse: {
              questionType: "radio",
              serverPath: `Repondant.Reponses.question_${q.IdQuizz}.Reponse`,
              label: q.Question,
              options: options,
              rules: {
                required: "Merci de répondre à la question",
              },
            },
          },
        });
      });

      survey = setFP(
        "sections.jeu_quizz_form.questions.questionnaire.questions",
        questions,
        survey
      );
      // ticket #5925
      // survey = setFP(
      //   "sections.jeu_quizz_form.title",
      //   "Jouez et Gagnez avec Groupama Loire Bretagne",
      //   survey
      // );
    }

    if (jeu.Type === "grattage") {
      let questions = {
        grattage: {
          questionType: "grattage",
          questionClass: "jeux-description jeux-nom",
          serverPath: "Repondant.IdLotGagner",
        },
      };

      survey = setFP(
        "sections.jeu_grattage_form.questions.questionnaire.questions",
        questions,
        survey
      );
      // ticket #5925
      // survey = setFP(
      //   "sections.jeu_grattage_form.title",
      //   "Jouez et Gagnez avec Groupama Loire Bretagne",
      //   survey
      // );
    }
    if (jeu.Type === "instant_gagnant") {
      // set question de la section jeu_instant_gagnant_form
      let lotsGagnants = jeu.LotsGagnants ? Object.values(jeu.LotsGagnants) : [];
      let items = [];
      lotsGagnants.forEach(lot => {
        items.push({
          nom: lot.Nom,
          src: `${process.env.VUE_APP_API_URL}/api/upload/${lot.Visuel}`,
          isGagnant: true,
          quantity: lot.Nombre,
          nbTirage: lot.NbTirage ? lot.NbTirage : 0,
          idLot: lot.IdLot,
        });
        items.push({
          nom: "cerise",
          src: `${process.env.VUE_APP_API_URL}/api/upload/cerise-instant_gagnant.jpg`,
          isGagnant: false,
          quantity: 1,
          nbTirage: 0,
          idLot: -1,
        });
      });

      for (let i = items.length; i <= 5; i++) {
        items.push({
          nom: "cerise",
          src: `${process.env.VUE_APP_API_URL}/api/upload/cerise-instant_gagnant.jpg`,
          isGagnant: false,
          quantity: 1,
          nbTirage: 0,
          idLot: -1,
        });
      }

      survey = setFP(
        "sections.jeu_instant_gagnant_form.questions.roue_chance.items",
        items,
        survey
      );
    }
    state.survey = survey;
  },
  [RESET_LISTE_JEUX]: state => {
    let survey = state.survey;
    survey.sections.globals.ListeJeux = [];
    state.survey = survey;
  },
  [ADD_REPONSE_QUIZZ]: (state, section) => {
    let survey = state.survey;
    let idReponse = uuid.v1();
    let reponse = {
      questionClass: "col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 quizz-reponse",
      questions: {
        quizz_reponse: {
          questionType: "input",
          questionClass: "col-12",
          placeholder: "Réponse",
          serverPath: "Quizz.Reponses." + idReponse + ".Reponse",
          rules: {
            required: "Merci de donner une valeur pour la réponse",
          },
        },
        quizz_reponse_correct: {
          serverPath: "Quizz.Reponses." + idReponse + ".IsCorrect",
          questionType: "checkbox-classic",
          labelInput: "Réponse correcte",
          response: false,
          questionClass: "field noBottom",
        },
        remove_reponse: {
          id: idReponse,
          questionClass: "col-0 btn-erase-wrapper",
          questionType: "removeReponse",
          visibilityRules: {
            "sections.quizz_questions.questions.quizz_reponses.questions.reponses.nbReponses": {
              greater: 2,
            },
          },
        },
      },
    };
    let pathReponse = `sections.quizz_questions.questions.quizz_reponses.questions.reponses.questions.${idReponse}`;
    let pathValidationReponse = `${pathReponse}.questions.quizz_reponse_correct`;
    survey.sections.quizz_questions.questions.quizz_reponses.questions.reponses.rules.minQuestions.champs.push(
      pathValidationReponse
    );
    survey = setFP(pathReponse, reponse, survey);
    let nbReponses = survey.sections.quizz_questions.questions.quizz_reponses.questions.reponses
      .nbReponses
      ? survey.sections.quizz_questions.questions.quizz_reponses.questions.reponses.nbReponses
      : 0;
    survey = setFP(
      "sections.quizz_questions.questions.quizz_reponses.questions.reponses.nbReponses",
      nbReponses + 1,
      survey
    );
    state.survey = survey;
  },
  [RESET_REPONSES_QUIZZ]: state => {
    let survey = state.survey;
    survey = setFP(
      "sections.quizz_questions.questions.quizz_reponses.questions.reponses.questions",
      {},
      survey
    );
    survey = setFP(
      "sections.quizz_questions.questions.quizz_reponses.questions.reponses.nbReponses",
      0,
      survey
    );
    survey.sections.quizz_questions.questions.quizz_reponses.questions.reponses.rules.minQuestions.champs =
      [];
    state.survey = survey;
  },
  [REMOVE_REPONSE_QUIZZ]: (state, data) => {
    let survey = state.survey;
    let idReponseDB =
      survey.sections.quizz_questions.questions.quizz_reponses.questions.reponses.questions[data.id]
        .questions.id_reponse?.response;
    if (idReponseDB) {
      axios.delete(`${process.env.VUE_APP_API_URL}/api/reponses/${idReponseDB}`);
    }
    let reponses = omitBy(
      survey.sections.quizz_questions.questions.quizz_reponses.questions.reponses.questions,
      (q, key) => key === data.id
    );
    survey = setFP(
      "sections.quizz_questions.questions.quizz_reponses.questions.reponses.questions",
      reponses,
      survey
    );
    survey = setFP(
      "sections.quizz_questions.questions.quizz_reponses.questions.reponses.nbReponses",
      Object.keys(reponses).length,
      survey
    );
    survey.sections.quizz_questions.questions.quizz_reponses.questions.reponses.rules.minQuestions.champs =
      survey.sections.quizz_questions.questions.quizz_reponses.questions.reponses.rules.minQuestions.champs.filter(
        function (champ) {
          return champ.indexOf(data.id) < 0;
        }
      );
    state.survey = survey;
  },
  [SET_REPONSES_QUIZZ]: (state, reponses) => {
    let survey = state.survey;
    survey.sections.quizz_questions.questions.quizz_reponses.questions.reponses.rules.minQuestions.champs =
      [];
    let nbReponses = 0;
    map(reponses, reponse => {
      let idReponse = uuid.v1();
      let reponseQuizz = {
        questionClass: "col-xs-12 col-sm-12 col-md-4 col-lg-5 quizz-reponse",
        questions: {
          id_reponse: {
            serverPath: "Quizz.Reponses." + idReponse + ".IdReponse",
            questionType: "input",
            questionClass: "col-12",
            visibilityRules: false,
            hidden: true,
            response: reponse.IdReponse,
          },
          id_reponse_quizz: {
            serverPath: "Quizz.Reponses." + idReponse + ".IdQuizz",
            questionType: "input",
            questionClass: "col-12",
            visibilityRules: false,
            hidden: true,
            response: reponse.IdQuizz,
          },
          quizz_reponse: {
            questionType: "input",
            questionClass: "col-12",
            placeholder: "Réponse",
            serverPath: "Quizz.Reponses." + idReponse + ".Reponse",
            response: reponse.Reponse,
            rules: {
              required: "Merci de donner une valeur pour la réponse",
            },
          },
          quizz_reponse_correct: {
            serverPath: "Quizz.Reponses." + idReponse + ".IsCorrect",
            questionType: "checkbox-classic",
            labelInput: "Réponse correcte",
            response: reponse.IsCorrect,
            questionClass: "field noBottom",
          },
          remove_reponse: {
            id: idReponse,
            questionClass: "col-0 btn-erase-wrapper",
            questionType: "removeReponse",
            visibilityRules: {
              "sections.quizz_questions.questions.quizz_reponses.questions.reponses.nbReponses": {
                greater: 2,
              },
            },
          },
        },
      };
      let pathReponse = `sections.quizz_questions.questions.quizz_reponses.questions.reponses.questions.${idReponse}`;
      let pathValidationReponse = `${pathReponse}.questions.quizz_reponse_correct`;
      survey.sections.quizz_questions.questions.quizz_reponses.questions.reponses.rules.minQuestions.champs.push(
        pathValidationReponse
      );

      survey = setFP(pathReponse, reponseQuizz, survey);
      survey = setFP(
        "sections.quizz_questions.questions.quizz_reponses.questions.reponses.nbReponses",
        nbReponses + 1,
        survey
      );
      nbReponses += 1;
    });
    state.survey = survey;
  },
  [REMOVE_JEU_FROM_LISTE]: (state, data) => {
    if (data.id) {
      let survey = state.survey;
      survey.sections.globals.ListeJeux = survey.sections.globals.ListeJeux.filter(jeu => {
        return jeu.IdJeu !== data.id;
      });
      state.survey = survey;
    }
  },
  [ADD_JEU_TO_LISTE]: (state, data) => {
    let survey = state.survey;
    survey.sections.globals.ListeJeux.push(data.jeu);
    state.survey = survey;
  },
  [SET_DEJA_JOUER]: (state, data) => {
    let survey = state.survey;
    survey.sections.globals.dejaJouer = data;
    state.survey = survey;
  },
  [CHECK_FILE_ERROR]: (state, data) => {
    let survey = state.survey;
    survey = setFP(
      getPath(data.path, data.responsePath, "error"),
      getError(data.value, data.rules),
      state.survey
    );
    state.survey = survey;
  },
  [SET_USER_LOGGING]: (state, isLogged) => {
    let survey = state.survey;
    survey.sections.globals.isUserLogged = isLogged;
  },
  [SET_LOADING]: (state, statut) => {
    state.loading = statut;
  },
  [SET_LISTE_IMAGES]: (state, images) => {
    let survey = state.survey;
    survey.sections.globals.ListeImages = images;
    state.survey = survey;
  },
  [REMOVE_IMAGE_FROM_LISTE]: (state, data) => {
    if (data.id) {
      let survey = state.survey;
      survey.sections.globals.ListeImages = survey.sections.globals.ListeImages.filter(image => {
        return image.IdImage !== data.id;
      });
      state.survey = survey;
    }
  },
};

export default mutations;
