<template>
  <footer class="bottom-nav">
    <router-link
      v-if="$route.name != 'Hors Délai'"
      class="link"
      :to="{
        name: 'Règlement',
        params: {param: $route.params?.param},
        query: $route.query,
      }"
      target="_blank"
    >
      Règlement du jeu
    </router-link>
    <a class="link" href="/mentions-legales.pdf" target="_blank"> Mentions légales </a>
    <a class="link" href="/donnes-personnelles.pdf" target="_blank"> Données personnelles </a>
    <a class="optanon-toggle-display link">Gérer mes cookies</a>
  </footer>
</template>
<script>
export default {};
</script>
