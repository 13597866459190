<template>
  <main>
    <div class="loading-wrapper" v-if="loading">
      <div class="loading">
        <div class="spinner-grow text-success"></div>
        Récupération des images...
      </div>
    </div>
    <div v-else>
      <div class="page-title">
        <h3>Listes des images</h3>
        <router-link
          :to="{ name: 'BO.AddImage' }"
          class="btn btn-primary"
          >Ajouter une image</router-link
        >
      </div>
      <div class="content-wrapper">
        <table class="table table-referentiel">
          <thead>
            <tr>
              <th>
                <div class="th-title">
                  <a
                    @click="
                      sorting.Titre = !sorting.Titre;
                      sortListe('Titre', sorting.Titre);
                    "
                  >
                    <span>Titre</span>
                    <i :class="`far  ${sorting.Titre ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                  </a>
                </div>
                <input type="text" v-model="searching.Titre" @input="filterListe()" />
              </th>
              <th>
                <div class="th-title">
                  <a
                    @click="
                      sorting.Description = !sorting.Description;
                      sortListe('Description', sorting.Description);
                    "
                  >
                    <span>Description</span>
                    <i :class="`far  ${sorting.Description ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                  </a>
                </div>
                <input type="text" v-model="searching.Description" @input="filterListe()" />
              </th>
              <th class="image-type">
                <div class="th-title">
                  <a
                    @click="
                      sorting.Type = !sorting.Type;
                      sortListe('Type', sorting.Type);
                    "
                  >
                    <span>Type</span>
                    <i :class="`far  ${sorting.Type ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                  </a>
                </div>
                <input type="text" v-model="searching.Type" @input="filterListe()" />
              </th>
              <th>Image</th>
              <th>
                <button class="btn btn-primary" @click="resetFilter">
                  <i class="far fa-sync" /><span>Réinitialiser</span>
                </button>
              </th>
            </tr>
          </thead>
          <tbody v-if="pageListe && pageListe.length > 0">
            <details-image
              v-for="(image, index) in pageListe"
              :key="`image-${index}`"
              :image="image"
            />
          </tbody>
          <div v-else>Aucun résultat</div>
        </table>

        <div v-if="nbPages > 1">
          <div class="pagination">
            <button class="page-number" v-if="currentPage > 0" @click="nextPage(currentPage)">
              <i class="far fa-chevron-left" />
            </button>
            <button
              :class="[i === currentPage + 1 && 'active', 'page-number']"
              v-for="i in nbPages"
              :key="i"
              @click="nextPage(i)"
            >
              <span>{{ i }}</span>
            </button>
            <button
              class="page-number"
              v-if="currentPage < nbPages - 1"
              @click="nextPage(currentPage + 2)"
            >
              <i class="far fa-chevron-right" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { map } from "lodash";
import { mapActions, mapGetters } from "vuex";
import DetailsImage from "./DetailsImage.vue";

export default {
  name: "ListeImages",
  components: { DetailsImage },
  data() {
    return {
      nbPages: 0,
      nbPerPage: 30,
      currentPage: 0,
      showModalDelete: false,
      currentListe: [],
      sorting: {
        Titre: false,
        Description: false,
        Type: false,
      },
      searching: {
        Titre: null,
        Description: null,
        Type: null,
      },
    };
  },
  computed: {
    ...mapGetters(["loading", "listeImages"]),
    pageListe() {
      if (this.currentPage && this.currentPage.length > 0) {
        let n = this.currentPage * this.nbPerPage;
        let m = n + this.nbPerPage;
        this.nbPages = Math.ceil(this.currentListe.length / this.nbPerPage);
        return this.currentListe.slice(n, m);
      }
      return this.currentListe;
    },
  },
  methods: {
    ...mapActions(["setListeImages"]),
    nextPage(i) {
      this.currentPage = i - 1 < 0 ? 0 : i - 1 > this.nbPages - 2 ? this.nbPages - 1 : i - 1;
    },
    filterListe() {
      this.currentPage = 0;
      this.currentListe = [...this.listeImages];
      map(this.searching, (search, key) => {
        if (search) {
          let regex = new RegExp(search, "i");
          this.currentListe = this.currentListe.filter(user => {
            return regex.test(user[key]);
          });
        }
      });
    },
    resetFilter() {
      this.currentListe = [...this.listeImages];
      map(this.searching, (value, key) => {
        this.searching[key] = null;
        this.sorting[key] = false;
      });
    },
    sortListe(key, sortKey) {
      this.currentPage = 0;
      this.currentListe = this.currentListe.sort((a, b) => this.callbackSort(a, b, key, sortKey));
    },
    callbackSort(a, b, key, sortKey) {
      if (a[key] < b[key]) {
        return sortKey ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return sortKey ? 1 : -1;
      }
      return 0;
    },
  },
  created() {
    return Promise.resolve()
      .then(() => this.setListeImages())
      .then(() => (this.currentListe = this.listeImages));
  },
  watch: {
    listeImages(newValue, oldValue) {
      this.currentListe = [...newValue];
    },
  },
};
</script>

<style></style>
