import IndexImages from "./IndexImages.vue";
import ListImages from "./ListeImages.vue";
import AddImage from "./AddImage.vue";
import EditImage from "./EditImage.vue";

export const routes = [
  {
    name: "BO.Images",
    path: "images",
    component: IndexImages,
    redirect: "/bo/images/liste",
    children: [
      {
        name: "BO.ListeImages",
        path: "liste",
        component: ListImages,
        meta: { section: "banque_images" },
      },
      {
        name: "BO.AddImage",
        path: "add",
        component: AddImage,
        meta: { section: "banque_images" },
      },
      {
        name: "BO.EditImage",
        path: "edit/:id",
        component: EditImage,
        meta: { section: "banque_images" },
      },
    ],
  },
];
