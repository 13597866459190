<template>
  <div class="col">
    <div class="jeu-block">
      <div class="jeu-block-top">
        <div class="jeu-infos">
          <div v-if="jeu.Visuel" class="jeu-visuel">
            <img :src="urlVisuel" alt="Visuel Jeu" />
          </div>
          <div class="jeu-details">
            <div class="jeu-title-block">
              <span class="jeu-title">{{ jeu.Nom }}</span>
              <span :class="['jeu-status', statutJeu]">{{ getStatutJeu }}</span>
            </div>
            <div class="jeu-info-value">
              Du {{ DateFormat(jeu.DateDebut) }} au {{ DateFormat(jeu.DateFin) }}
            </div>
            <div :class="['jeu-type', jeu.Type]">
              {{typeJeu}}
            </div>
          </div>
        </div>
        <div class="jeu-block-top-right">
          <div class="jeu-repondants">
            <div>{{ jeu.numbreRepondants }}</div>
            <span v-if="jeu.numbreRepondants > 1">répondants</span>
            <span v-else>répondant</span>
          </div>
          <div class="jeu-actions">
            <button class="btn tooltip-btn" @click="apercuJeu()">
              <i class="fal fa-search"></i>
              <span class="tooltip-text">Aperçu</span>
            </button>
            <button
              class="btn tooltip-btn"
              @click="isModalDelete = true"
              :disabled="statutJeu === 'en_cours'"
            >
              <i class="fal fa-trash-alt"></i>
              <span class="tooltip-text">Supprimer</span>
            </button>
            <button class="btn tooltip-btn" @click="modifierJeuLink()">
              <i class="fal fa-pencil"></i>
              <span class="tooltip-text">Editer</span>
            </button>
          </div>
        </div>
      </div>
      <div class="jeu-block-bottom">
        <button class="btn tooltip-btn" @click="ImprimerJeu(true)">
          <i class="fal fa-qrcode"></i>
          <span class="tooltip-text">Imprimer une affiche</span>
        </button>
        <button class="btn tooltip-btn" @click="ImprimerJeu(false)">
          <i class="fal fa-print"></i>
          <span class="tooltip-text">Imprimer</span>
        </button>
        <button class="btn tooltip-btn" @click="dupliquerJeu()">
          <i class="fal fa-copy"></i>
          <span class="tooltip-text">Dupliquer</span>
        </button>
        <button class="btn tooltip-btn" @click="copierUrl()">
          <i class="fal fa-link"></i>
          <span class="tooltip-text">Copier l'url</span>
        </button>
        <!-- <button class="btn tooltip-btn" @click="downloadQRCode()">
          <i class="fal fa-qrcode"></i>
          <span class="tooltip-text">Copier le QRCode</span>
        </button> -->
        <button class="btn tooltip-btn" @click="exportJeu()">
          <i class="fal fa-analytics"></i>
          <span class="tooltip-text">Export du jeu</span>
        </button>
        <span class="text-diffusion">{{ jeu.Diffusion }}</span>
      </div>
    </div>
    <modal-confirmation
      v-show="isModalDelete"
      @close="isModalDelete = false"
      @confirm="supprimerJeu()"
    >
      <template #confirmationBtn>
        <span>Je confirme</span>
      </template>
      <template #headerTitle>
        <h5>Confirmation de la suppression</h5>
      </template>
      <template #confirmationMessage>
        <p>Merci de confirmer votre demande de suppression</p>
      </template>
    </modal-confirmation>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import { get } from "lodash";
import ModalConfirmation from "./fields/ModalConfirmationField.vue";
export default {
  components: { ModalConfirmation },
  props: ["jeu"],
  data() {
    return {
      isModalDelete: false,
    };
  },
  computed: {
    dateFormat() {
      return "DD/MM/YYYY";
    },
    statutJeu() {
      let dateDebut = moment(this.jeu.DateDebut);
      let dateFin = moment(this.jeu.DateFin);
      let now = moment();
      return now < dateDebut
        ? "a_venir"
        : now >= dateDebut && now <= dateFin
        ? "en_cours"
        : "termine";
    },
    typeJeu() {
      let path = `survey.sections.bo_nouveau_jeu.questions.jeu.questions.jeu_infos.questions.type_jeu.options['${this.jeu.Type}']`;
      return get(this.$store.state, path);
    },
    urlVisuel() {
      return `${process.env.VUE_APP_API_URL}/api/upload/${this.jeu.Visuel}`;
    },
    getStatutJeu() {
      let status = { a_venir: "À venir", en_cours: "En cours", termine: "Terminé" };
      return status[this.statutJeu];
    },
  },
  methods: {
    ...mapActions(["setCurrentJeu", "deleteJeuDBById", "duplicateJeu"]),
    DateFormat(date) {
      return moment(date).format(this.dateFormat);
    },
    modifierJeuLink() {
      this.$router.push({
        name: "BO.ModificationJeu",
        params: {
          param: this.jeu.IdJeu,
        },
        query: this.$route.query,
        meta: {
          section: "bo_modifier_jeu",
        }
      });
    },
    exportJeu() {
      window.location = `${process.env.VUE_APP_API_URL}/api/jeux/exportrepondants/${this.jeu.IdJeu}`;
    },
    copierUrl() {
      let url = document.createElement("textarea");
      document.body.appendChild(url);
      url.value = this.jeu.Url;
      url.select();
      document.execCommand("copy");
      document.body.removeChild(url);
    },
    // downloadQRCode() {
    //   // window.location = `${process.env.VUE_APP_API_URL}/api/upload/download/${this.jeu.QRCode}`;
    //   window.open(
    //     `${process.env.VUE_APP_API_URL}/api/upload/download/${this.jeu.QRCode}`,
    //     "_blank"
    //   );
    // },
    apercuJeu() {
      let windowApercu = window.open();
      return Promise.resolve()
        .then(res => {
          let routeApercu = this.$router.resolve({
            name: "Questionnaire",
            params: {
              param: this.jeu.IdJeu,
              section: "jeu_accueil",
            },
            query: { ...this.$route.query, type: "apercu" },
          });
          windowApercu.location.href = routeApercu.href;
          // return window.open(routeApercu.href, "_blank");
        });
    },
    supprimerJeu() {
      this.deleteJeuDBById({ id: this.jeu.IdJeu }).then(() => (this.isModalDelete = false));
    },
    dupliquerJeu() {
      this.duplicateJeu({ id: this.jeu.IdJeu });
    },
    ImprimerJeu(isAffiche) {
      window.open(
        `${process.env.VUE_APP_API_URL}/api/jeux/pdf/${this.jeu.IdJeu}?affiche=${isAffiche}`,
        "_blank"
      );
    },
  },
};
</script>
