<template>
  <main>
    <div class="main-block confirmation-block">
      <div class="confirm-message">
        <h1>Jeu non existant</h1>
        <p>Désolé ce jeu n'existe pas ou a été supprimé</p>
      </div>
    </div>
    <div class="pages">
      <div></div>
      <div></div>
    </div>
  </main>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped></style>
