<template>
  <div class="container text-center">
    <h2>Données personnelles</h2>
    <p>
      La présente politique témoigne des engagements mis en œuvre dans le cadre des activités
      quotidiennes pour une utilisation responsable des données personnelles.
    </p>
    <p></p>
    <h3>Politique générale de protection des données personnelles</h3>
    <h3></h3>
  </div>
</template>

<style lang="scss" scoped></style>
