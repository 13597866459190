<template>
  <a class="btn" @click="removeReferral({ id: question.id })" target="_blank">{{
    question.buttonLabel
  }}</a>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  props: ["question", "path", "responsePath"],
  computed: {
    ...mapState({
      nbReferrals: state => {
        return state.sections.globals.nombreReferrals;
      },
    }),
  },
  methods: {
    ...mapActions({
      removeReferral: "removeReferralState",
    }),
  },
};
</script>
