<template>
  <div class="col-0 field">
    <a
      class="btn btn-primary"
      @click="removeLot({ id: question.id, sectionName, typeLot })"
      target="_blank"
      >{{ question.buttonLabel }}</a
    >
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: ["question", "path", "responsePath"],
  computed: {
    sectionName() {
      return this.$route.meta.section;
    },
    typeLot() {
      return this.question.lotType;
    },
  },
  methods: {
    ...mapActions({
      removeLot: "removeLotJeuState",
    }),
  },
};
</script>
