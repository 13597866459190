<template>
  <div id="layout">
    <!-- <v-header /> -->
    <section class="wrapper">
      <app-header v-if="showAppHeader" />
      <section class="content">
        <router-view v-slot="{ Component }">
          <Transition name="fade" mode="out-in">
            <keep-alive v-if="$route.meta && $route.meta.keepAlive !== false">
              <component :is="Component" />
            </keep-alive>
            <component :is="Component" v-else />
          </Transition>
        </router-view>
        <app-footer v-if="showAppFooter" />
        <img
          src="@/assets/images/logo-icon.svg"
          alt="Logo Groupama vectoriel"
          class="logo-vectoriel"
        />
      </section>
      <!-- <app-footer v-if="showAppFooter" /> -->
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";

import VHeader from "./Header";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";

export default {
  components: {
    VHeader,
    AppHeader,
    AppFooter
  },
  metaInfo: {
    htmlAttrs: {
      lang: "fr",
      amp: true
    }
  },
  computed: mapState({
    showAppHeader: state => state.showAppHeader,
    showAppFooter: state => state.showAppFooter,
    section(state) {
      return state.survey.sections[this.$route.meta.section];
    }
  })
};
</script>
