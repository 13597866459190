<template>
  <div :class="`${question.answerColClass || 'col'} field multi-select-quizz`">
    <v-select
      v-for="(i, index) in numberOfSelect"
      :key="`question${i}`"
      :name="`question${i}`"
      :options="options"
      placeholder="Choisir une question"
      :modelValue="selected[index]"
      @option:selected="value => onInput(value, index)"
    >
      <template #no-options>Pas de résultats</template>
    </v-select>
    <!-- <label v-if="question.labelInput">{{ question.labelInput }}</label> -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import VSelect from "vue-select";
import { getProp } from "../../utils/SurveyUtils";

export default {
  components: {
    VSelect,
  },
  props: ["question", "path", "responsePath"],
  data() {
    return {
      numberOfSelect: this.question.numberOfSelect,
      options: [],
      selected: [],
    };
  },
  computed: {
    ...mapGetters(["ListeQuizzs"]),
  },
  methods: {
    ...mapActions(["setValue", "setListeQuizzs"]),
    onInput(value, index) {
      this.selected[index] = value;
      this.options = this.ListeQuizzs.filter(quizz => {
        let q = quizz.Question;
        return this.selected.indexOf(q) < 0;
      }).map(quizz => quizz.Question);

      // set IdQuizz comme response
      let valueToSet = this.ListeQuizzs.filter(quizz => {
        let q = quizz.Question;
        return this.selected.indexOf(q) >= 0;
      }).map(quizz => quizz.IdQuizz);
      this.$store.dispatch("setValue", {
        path: this.path,
        responsePath: this.responsePath,
        value: valueToSet,
      });
    },
  },
  mounted() {
    return Promise.resolve()
      .then(() => this.setListeQuizzs())
      .then(() => (this.options = this.ListeQuizzs.map(quizz => quizz.Question)))
      .then(() => {
        let response = getProp(this.question, this.responsePath, "response");
        this.selected = response
          ? this.ListeQuizzs.filter(quizz => {
              let id = quizz.IdQuizz;
              return response.indexOf(id) >= 0;
            }).map(quizz => quizz.Question)
          : [];
      });
  },
};
</script>
<style scoped>
.multi-select-quizz {
  display: grid;
  grid-gap: 10px 10px;
  /* grid-template-columns: 50% 50%; */
  /* grid-column-start: 2; */
}
</style>
