<template>
  <main>
    <nav class="nav-bar" v-if="$route.name != 'BO.Login'">
      <router-link :to="{ name: 'BO.Jeux' }">Les jeux concours</router-link>
      <router-link :to="{ name: 'BO.Quizz' }" v-if="hasAction">Les quiz</router-link>
      <router-link :to="{ name: 'BO.Images' }" v-if="hasAction">Banque d'image</router-link>
      <a href="/guide-utilisateur.pdf" target="_blank" rel="noopener noreferrer"
        >Guide utilisateur</a
      >
    </nav>
    <router-view></router-view>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import { ADMIN_ROLES } from "../../constants/Properties";
export default {
  name: "BO",
  computed: {
    ...mapGetters(["userRole"]),
    hasAction() {
      return ADMIN_ROLES.includes(this.userRole);
    },
  },
};
</script>

<style></style>
