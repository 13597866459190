<template>
  <div
    v-for="(formule, index) in question.formuleData"
    :key="index"
    :class="`${question.answerColClass || 'col'}`"
  >
    <div
      :class="[
        'pricing-table',
        { highlight: formule.highlight },
        { 'not-selected': formule.notSelected },
      ]"
    >
      <div class="pricing-table-tag">
        <i class="fas fa-star" />
        <div>Formule conseillée</div>
      </div>
      <div class="pricing-table-header">
        {{ formule.title }}
        <sup>(1)</sup>
      </div>
      <div class="pricing-table-content">
        <div class="princing-table-avantage" v-if="formule.plus">
          <div>
            L'intégralité de la
            <br />
            formule {{ question.formuleData[index - 1].title }}
          </div>
          <i class="fal fa-plus-circle"></i>
        </div>
        <ul>
          <li v-for="(item, indexx) in formule.items" :key="indexx">
            <i class="fal fa-check"></i>
            <span>{{ item }}</span>
          </li>
        </ul>
        <button class="btn btn-secondary" @click="select(formule)">
          <span v-if="formule.notSelected || formule.notSelected === undefined"
            >sélectionner</span
          >
          <span v-else>SÉLECTIONNÉE</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

import { getOptions, getProp } from "../../utils/SurveyUtils";

export default {
  props: ["question", "path", "responsePath"],
  computed: {
    response() {
      return getProp(this.question, this.responsePath, "response");
    },
  },
  methods: {
    getOptions,
    ...mapActions(["setValue", "resetValue"]),
    log(message) {
      console.log(message);
    },
    select(formule) {
      this.setValue({
        path: this.path,
        responsePath: this.responsePath,
        value: formule.title,
      });

      this.question.formuleData.forEach((form, index) => {
        let path = this.path + `.formuleData[${index}].notSelected`;
        if (form.title !== formule.title) {
          this.resetValue({ path, value: true });
        } else {
          this.resetValue({ path, value: false });
        }
      });
    },
  },
};
</script>
