<template>
  <div :class="`${question.answerColClass} col`">
    <div class="montant-title" v-sane-html="computedText"></div>
    <div class="montant">
      <div class="montant-mois">{{ tarifMonth ? tarifMonth : calculMonth() }}</div>
      <div class="montant-details">
        <div class="montant-details-mois">
          €/mois
          <sup>(1)</sup>
        </div>
        <div>soit {{ tarifYear ? tarifYear : calculYear() }} €/an</div>
      </div>
      <div v-if="question.labelSuffix">{{ question.labelSuffix }}</div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { replace } from "lodash";
import moment from "moment";

export default {
  props: ["question", "path", "responsePath"],
  data: function () {
    return {
      tarifMonth: null,
      tarifYear: null,
    };
  },
  computed: {
    computedText() {
      const formule = this.$store.state.survey.sections.formule.questions.formules.response;
      return replace(this.question.text, "{{param}}", formule);
    },
  },
  methods: {
    ...mapActions(["setValueGlobal"]),
    calculateAge(birthday) {
      if (birthday) {
        let ageDifMs =
          Date.now() -
          moment(birthday).toDate().now();
        let ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
      } else {
        return null;
      }
    },
    calculMonth() {
      const formule = this.$store.state.survey.sections.formule.questions.formules.response;
      const ddn = this.$store.state.survey.sections.coordonnees.questions.infosCoordonees.questions
        .ddn.response;
      let tarif = replace(
        formule === "Tranquillité" && this.calculateAge(ddn) <= 54 ? 8.66 : 5.33,
        ".",
        ","
      );

      // set value globally
      this.setValueGlobal({
        path: "sections.globals.questions.calcul.response.calcul_month",
        value: replace(tarif, ".", ","),
      });
      this.tarifMonth = tarif;
      return tarif;
    },
    calculYear() {
      const formule = this.$store.state.survey.sections.formule.questions.formules.response;
      const ddn = this.$store.state.survey.sections.coordonnees.questions.infosCoordonees.questions
        .ddn.response;
      let tarif = replace(
        formule === "Tranquillité" && this.calculateAge(ddn) <= 54 ? 103.94 : 63.94,
        ".",
        ","
      );

      // set value globally
      this.setValueGlobal({
        path: "sections.globals.questions.calcul.response.calcul_year",
        value: tarif,
      });
      this.tarifYear = tarif;
      return tarif;
    },
  },
};
</script>
