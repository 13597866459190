<template>
  <div class="container text-center">
    <p>
      Les informations recueillies ont pour objectif de mieux vous connaître et recueillir vos
      appréciations sur différentes thématiques afin de nous permettre d’adapter nos modèles
      relationnels avec nos clients et d’ajuster nos messages de communication à vos attentes et
      préoccupations. Les réponses aux questions sont toutes facultatives. Ces informations sont
      confidentielles et strictement destinées à nos services internes chargés du traitement de
      l’enquête et notre prestataire informatique et ne seront pas transmises à des tiers.
      <br />Elles seront conservées uniquement le temps de leur analyse, puis anonymisées ou
      supprimées. <br />Vous pouvez exercer vos droits sur ces données (droits d’accès, de
      rectification, de suppression, d’opposition, de limitation) en écrivant au Délégué à la
      Protection des Données de Groupama Loire Bretagne, par courrier adressé 23, Boulevard
      Solférino CS51209 35012 Rennes cedex ou par mail à
      <a href="mailto:contactdrpo@groupama-loire-bretagne.fr"
        >contactdrpo@groupama-loire-bretagne.fr</a
      >.
    </p>
  </div>
</template>

<style lang="scss" scoped></style>
