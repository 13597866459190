<template>
  <main>
    <div v-if="$route.params.id > 0">
      <h2>Modification de l'image {{ $route.params.id }}</h2>
      <form-image />
    </div>
    <div v-else>Aucun id image n'a été donné</div>
  </main>
</template>

<script>
import FormImage from "./FormImage.vue";
import store from "../../../store";

export default {
  components: { FormImage },
  name: "EditImage",
  beforeRouteEnter(to, from, next) {
    return store.dispatch("setImage", { id: to.params.id }).then(() => next());
  },
};
</script>

<style></style>
