<template>
  <input
    :class="[question.questionClass]"
    :checked="response"
    :disabled="isDisabled"
    @click="onClick()"
    type="checkbox"
    :id="question.serverPath"
  />
  <label :for="question.serverPath"></label>
</template>
<script>
import { mapActions } from "vuex";
import { getOptions, getProp, getPath } from "../../utils/SurveyUtils";

export default {
  props: ["question", "path", "responsePath", "isChecked", "isDisabled"],
  computed: {
    response() {
      return getProp(this.question, this.responsePath, "response");
    }
  },
  watch: {
    ...mapActions(["setValue"]),
    isChecked(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setValue({
          path: this.path,
          responsePath: this.responsePath,
          value: newValue,
        });
      }
    },
  },
  methods: {
    ...mapActions(["setValue"]),
    getOptions,
    getPath,

    onClick() {
      if (this.question.setOtherServerPath) {
        this.setValue({
          path: this.question.setOtherServerPath.path,
          value: this.question.setOtherServerPath.value
        });
      }
      return this.setValue({
        path: this.path,
        responsePath: this.responsePath,
        value: !this.response || false
      });
    }
  }
};
</script>
