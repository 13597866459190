<template>
  <div class="networks">
    <div
      v-for="network in networks"
      :key="network.network"
      class="network" 
      :class="network.network"
    >
      <a :href="network.url" :title="network.name">
        <i :class="network.icon" :style="`color:${ network.color };`"></i>
      </a>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "share-field",
  props: ["isMobile"],
  computed: {
    ...mapGetters(["currentJeu"]),
    networks() {
      return this.isMobile
        ? [
            { network: "facebook", name: "Facebook", icon: "fab fah fa-lg fa-facebook-f", color: "#1877f2", url: "https://www.facebook.com/sharer/sharer.php?href=" + encodeURIComponent(this.currentJeu.Url) },
            { network: "twitter", name: "Twitter", icon: "fab fah fa-lg fa-twitter", color: "#1da1f2", url: "https://twitter.com/intent/tweet?url=" + encodeURIComponent(this.currentJeu.Url) },
            { network: "whatsapp", name: "Whatsapp", icon: "fab fah fa-lg fa-whatsapp", color: "#25d366", url: "https://api.whatsapp.com/send?text=" + encodeURIComponent(this.currentJeu.Url) },
            { network: "email", name: "Email", icon: "far fah fa-lg fa-envelope", color: "#333333", url: "mailto:?body=" + encodeURIComponent(this.currentJeu.Url) },
          ]
        : [
            { network: "facebook", name: "Facebook", icon: "fab fah fa-lg fa-facebook-f", color: "#1877f2", url: "https://www.facebook.com/sharer/sharer.php?href=" + encodeURIComponent(this.currentJeu.Url) },
            { network: "twitter", name: "Twitter", icon: "fab fah fa-lg fa-twitter", color: "#1da1f2", url: "https://twitter.com/intent/tweet?url=" + encodeURIComponent(this.currentJeu.Url) },
            { network: "email", name: "Email", icon: "far fah fa-lg fa-envelope", color: "#333333", url: "mailto:?body=" + encodeURIComponent(this.currentJeu.Url) },
          ];
    },
  },
};
</script>
