<template>
  <a class="btn btn-primary btn-erase" @click="removeReponse({ id: question.id })" target="_blank">
    <i class="far fa-times" />
  </a>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: ["question", "path", "responsePath"],
  methods: {
    ...mapActions({
      removeReponse: "removeReponseQuizz",
    }),
  },
};
</script>
