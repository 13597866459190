<template>
  <tr>
    <td>
      <span>{{ DateCreation }}</span>
    </td>
    <td>
      <span>{{ quizz.Titre }}</span>
    </td>
    <td>
      <span>{{ Question }}</span>
    </td>
    <td>
      <div class="statut">
        <div :class="['statut-icon', quizz.Statut.toLowerCase()]"></div>
        <span>{{ quizz.Statut }}</span>
      </div>
    </td>
    <td>
      <router-link
        class="tooltip-btn"
        :to="{
          name: 'ModificationQuizz',
          params: { id: quizz.IdQuizz, },
        }"
      >
        <i class="fal fa-pencil"></i>
        <span class="tooltip-text">Editer</span>
      </router-link>
      <button
        :disabled="quizz.Statut === 'Online'"
        class="btn tooltip-btn"
        @click="isModalDelete = true"
      >
        <i class="fal fa-trash-alt"></i>
        <span class="tooltip-text">Supprimer</span>
      </button>
    </td>
    <modal-confirmation
      v-show="isModalDelete"
      @close="isModalDelete = false"
      @confirm="supprimerQuizz(quizz.IdQuizz)"
    >
      <template #confirmationBtn>
        <span>Je confirme</span>
      </template>
      <template #headerTitle>
        <h5>Confirmation de la suppression</h5>
      </template>
      <template #confirmationMessage>
        <p>Merci de confirmer votre demande de suppression</p>
      </template>
    </modal-confirmation>
  </tr>
</template>
<script>
import moment from "moment";
import { mapActions } from "vuex";
import ModalConfirmation from "../../../components/fields/ModalConfirmationField.vue";
export default {
  data() {
    return {
      isModalDelete: false,
    };
  },
  components: { ModalConfirmation },
  props: ["quizz"],
  computed: {
    // Statut() {
    //   let s = await this.getStatut(this.quizz.IdQuizz);
    //   return s;
    // },
    stringTrim() {
      return 30;
    },
    DateCreation() {
      return moment(this.quizz.DateCreation).format("DD/MM/YYYY");
    },
    Question() {
      let q = this.quizz.Question;
      return q.length > this.stringTrim ? q.substring(0, this.stringTrim) + "..." : q;
    },
  },
  methods: {
    ...mapActions(["deleteQuizzDB", "setListeQuizzs"]),
    supprimerQuizz() {
      return Promise.resolve()
        .then(() => this.deleteQuizzDB({ id: this.quizz.IdQuizz }))
        .then(res => {
          if (res === "error") {
            this.isModalDelete = false;
            alert("Impossible de supprimer la quizz");
          } else {
            this.$router.go();
          }
        });
    },
  },
};
</script>
