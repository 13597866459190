<template>
  <div class="col-12">
    <div class="roue-wrapper">
      <div class="roue">
        <ul id="listItems" class="listItems">
          <li v-for="(item, index) in listItems" :key="index" class="item">
            <img v-if="item.src" :src="item.src" :alt="item.nom" />
            <span v-else>{{ item.nom }}</span>
          </li>
        </ul>
      </div>
      <button v-if="!dejaJouer && lotGagner == null" class="btn-primary" @click="spin()">
        {{ question.roueBtn }}
      </button>
      <div class="roue-message-wrapper">
        <div class="roue-message lot-gagne" v-if="isGagne === true">
          <div class="roue-message-title">Félicitations !</div>
          <span
            >Vous avez gagné et vous pouvez participer au tirage au sort en cliquant sur le bouton
            ci-dessous.</span
          >
        </div>
        <div class="roue-message lot-perdu" v-if="isGagne === false">
          <div class="roue-message-title">Désolé</div>
          <p>
            Vous n'avez pas gagné mais vous pouvez participer au tirage au sort en cliquant sur le
            bouton ci-dessous.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// proprietés d'Item
// nom: "cerise",
// src: `${process.env.VUE_APP_API_URL}/api/upload/cerise.png`,
// isGagnant: false,
// quantity: 1,
// nbTirage: 0,
// idLot: -1

import { mapActions, mapGetters } from "vuex";
export default {
  props: ["question", "path", "responsePath"],
  data() {
    return {
      listItems: this.question.items,
      lotGagner: null,
    };
  },
  computed: {
    ...mapGetters(["dejaJouer"]),
    isGagne() {
      return this.lotGagner == null ? null : this.lotGagner.isGagnant;
    },
  },
  methods: {
    ...mapActions(["setDejaJouer", "setValue"]),
    spin() {
      if (this.dejaJouer) {
        alert("vous avez déjà tenté votre chance");
      } else {
        return this.setDejaJouer(true).then(() => {
          let ul = document.getElementById("listItems");
          let liListe = ul.querySelectorAll("li");
          let lengthItems = this.listItems.length;
          let heigthImage = 90;
          let lotsTirage = this.listItems.filter(
            item => item.quantity > item.nbTirage && item.nom !== "cerise"
          );
          let indexPerdu = this.listItems.map(item => item.nom).indexOf("cerise");

          if (liListe.length !== 2 * this.listItems.length) {
            ul.innerHTML = ul.innerHTML + ul.innerHTML;
          }
          let i = 0;
          let rd;
          ul.classList.remove("scroll-animation");
          setTimeout(() => {
            let isGagne = Math.random() <= 0.5;
            if (isGagne && lotsTirage.length > 0) {
              let randomLot = lotsTirage[Math.floor(Math.random() * lotsTirage.length)];
              rd = this.listItems.map(item => item.nom).indexOf(randomLot.nom);
            } else {
              rd = indexPerdu;
            }
            let stopAt =
              rd > 1
                ? -(rd - 2) * heigthImage
                : -(lengthItems - 3) * heigthImage - (rd + 1) * heigthImage;
            ul.style.setProperty("--halfScroll", -lengthItems * heigthImage + "px");
            ul.style.setProperty("--stopAt", stopAt + "px");
            ul.classList.add("scroll-animation");
          }, 0);
          ul.onanimationend = () => {
            i++;
            if (i === 2) {
              setTimeout(() => {
                this.lotGagner = this.listItems[rd];
                this.setValue({
                  path: this.path,
                  responsePath: this.responsePath,
                  value: this.lotGagner.idLot,
                });
              }, 150);
            }
          };
        });
      }
    },
    RandomIntInterval(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
  },
};
</script>
