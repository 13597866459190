import { filter, isEmpty } from "lodash";
import moment from "moment";
const getters = {
  NomUtilisateur: state => {
    let user = state.survey.sections.globals.Utilisateur;
    return !isEmpty(user) ? `${user.Nom}  ${user.Prenom}` : null;
  },
  ListeJeux: state => {
    return state.survey.sections.globals.ListeJeux;
  },
  ListeQuizzs: state => {
    return state.survey.sections.globals.ListeQuizzs;
  },
  getRepondant: state => {
    return state.survey.sections.globals.Repondant;
  },
  Utilisateur: state => {
    return state.survey.sections.globals.Utilisateur;
  },
  currentJeu: state => {
    return state.survey.sections.globals.currentJeu;
  },
  dateTirageJeu: state => {
    let date = state.survey.sections.globals.currentJeu.DateTirage
      ? moment(state.survey.sections.globals.currentJeu.DateTirage).format("DD/MM/YYYY")
      : "";
    return date;
  },
  typeJeuSection: state => sectionName => {
    if (["bo_modifier_jeu", "bo_nouveau_jeu"].indexOf(sectionName) >= 0) {
      return state.survey.sections[sectionName].questions.jeu.questions.jeu_infos.questions.type_jeu
        .response;
    }
    return null;
  },
  ReponseHasOneCorrecte: state => {
    let reponses =
      state.survey.sections.quizz_questions.questions.quizz_reponses.questions.reponses.questions;
    let reponsesCorrecte = filter(reponses, reponse => {
      return reponse.questions.quizz_reponse_correct.response;
    });
    return reponsesCorrecte.length > 0;
  },
  loadingJeux: state => {
    return state.survey.sections.globals.loadingJeux;
  },
  dejaJouer: state => {
    return state.survey.sections.globals.dejaJouer;
  },
  getEmailEtTelephone: state => {
    let email =
      state.survey.sections.jeu_concours_form.questions.questionnaire.questions.email.response;
    let telephone =
      state.survey.sections.jeu_concours_form.questions.questionnaire.questions.telephone.response;
    return `${email}|${telephone}`;
  },
  loading: state => {
    return state.loading;
  },
  listeImages: state => {
    return state.survey.sections.globals.ListeImages;
  },
  userRole: state => {
    return state.survey.sections.globals.Utilisateur.Role;
  }
};
export default getters;
