<template>
  <tr>
    <td>
      <span>{{ image.Titre }}</span>
    </td>
    <td>
      <span>{{ Description }}</span>
    </td>
    <td>
      <span>{{ image.Type }}</span>
    </td>
    <td>
      <img :src="imgSrc" :alt="image.Type" width="75px" />
    </td>
    <td>
      <router-link
        class="tooltip-btn"
        :to="{
          name: 'BO.EditImage',
          params: { id: image.IdImage },
        }"
      >
        <i class="fal fa-pencil"></i>
        <span class="tooltip-text">Editer</span>
      </router-link>
      <button class="btn tooltip-btn" @click="isModalDelete = true">
        <i class="fal fa-trash-alt"></i>
        <span class="tooltip-text">Supprimer</span>
      </button>
    </td>
    <modal-confirmation
      v-show="isModalDelete"
      @close="isModalDelete = false"
      @confirm="supprimerImage(image.IdImage)"
    >
      <template #confirmationBtn>
        <span>Je confirme</span>
      </template>
      <template #headerTitle>
        <h5>Confirmation de la suppression</h5>
      </template>
      <template #confirmationMessage>
        <p>Merci de confirmer votre demande de suppression</p>
      </template>
    </modal-confirmation>
  </tr>
</template>
<script>
import { mapActions } from "vuex";
import ModalConfirmation from "../../../components/fields/ModalConfirmationField.vue";
export default {
  name: "DetailsImage",
  data() {
    return {
      isModalDelete: false,
    };
  },
  components: { ModalConfirmation },
  props: {
    image: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    stringTrim() {
      return 30;
    },
    Description() {
      let q = this.image.Description;
      return q?.length > this.stringTrim ? q.substring(0, this.stringTrim) + "..." : q;
    },
    imgSrc() {
      return `${process.env.VUE_APP_API_URL}/api/upload/${this.image.FileName}`;
    },
  },
  methods: {
    ...mapActions(["deleteImageDB"]),
    supprimerImage() {
      return Promise.resolve()
        .then(() => this.deleteImageDB({ id: this.image.IdImage }))
        .then(res => {
          if (res === "error") {
            alert("Impossible de supprimer l'image");
          }
        })
        .then(() => {
          this.isModalDelete = false;
        });
    },
  },
};
</script>
